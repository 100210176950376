import React from 'react';
import { useTranslation } from 'react-i18next';
import {Button, Checkbox, Dimmer, Grid, Icon, Message} from 'semantic-ui-react';
import CustomTable from "../common/CustomTable";
import UserDetailModal from "./UserDetailModal";
import ConfirmModal from "../common/ConfirmModal";
import { DELETE, GET } from "../../utils/HttpClient";
import { URL_AUTH } from "../../constances/urls";
import { Toast } from "../../utils/Toast";

export default function UsersView(props) {
	const { t, i18n } = useTranslation();
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [showInActive, setShowInActive] = React.useState(false);
	// Table
	const [data, setData] = React.useState([]);
	const [page, setPage] = React.useState(1);
	const [pageSize, setPageSize] = React.useState(10);
	const [totalPages, setTotalPage] = React.useState(1);
	// Modal
	const [openDetailModal, setOpenDetailModal] = React.useState(false);
	const [editUser, setEditUser] = React.useState(null);
	const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
	const [deleteUser, setDeleteUser] = React.useState(null);

	const columns = [
		{
			Header: t("profiles.name"),
			accessor: "display_name",
		},
		{
			Header: t("profiles.email"),
			accessor: "email",
		},
		{
			Header: " ",
			Cell: ({row}) => {
				const myData = row.original;
				const isActive = myData.is_active;
				return (
					<div>
						{isActive && (
							<div>
								<Button basic 
									floated={"right"}
									color={"red"}
									onClick={() => handleDeleteUser(myData)}>
									<Icon name={"delete"}/>
									{t("delete")}
								</Button>

								<Button basic 
									floated={"right"}
									color={"blue"}
									onClick={() => handleEditUser(myData)}>
									<Icon name={"edit"}/>
									{t("edit")}
								</Button>
							</div>
						)}
						{!isActive && (
							<Button basic 
								floated={"right"}
								color={"blue"}
								onClick={() => handleEditUser(myData)}>
								<Icon name={"sync alternate"}/>
								{t("reactivate")}
							</Button>
						)}
					</div>
				)
			}
		}
	];

	const handleEditUser = async (data) => {
		setEditUser(data.id);
		setOpenDetailModal(true);
	};

	const handleDeleteUser = async (data) => {
		setDeleteUser(data);
		setOpenConfirmModal(true);
	};

	const fetchUsersList = async () => {
    setLoading(true);
    try {
			let params = {
				page: page,
				page_size: pageSize,
				is_active: !showInActive,
			}
			const response = await GET(URL_AUTH.USERS, params);
			const target = response.data;
			setData(target.results);
			setTotalPage(Math.ceil(target.count/pageSize));
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setLoading(false);
    }
	};

	const deleteTarget = async (id) => {
		if (!id)
			return;
		
		setLoading(true);
		try {
			const response = await DELETE(`${URL_AUTH.USERS}${id}/`);
			Toast.success(t("admin.the_user_was_deleted"))
		} catch (error) {
			setErrorMessage(error.errorMessages);
		} finally {
			setLoading(false);
			fetchUsersList();
		}
	};

	React.useEffect(() => {
		fetchUsersList();
	}, [page, showInActive]);

	React.useEffect(() => {
		if (!openDetailModal) {
			if (editUser)
				setEditUser(null);
			fetchUsersList();
		}
	}, [openDetailModal]);
	
  return (
		<div>
			{ errorMessage && (
        <Message negative>
          <Message.Header>{t("error")}</Message.Header>
          <p>{errorMessage}</p>
        </Message>
      )}

			<Grid columns={"2"}>
				<Grid.Column floated={"left"} width={"12"} verticalAlign={"middle"} style={{textAlign: "left"}}>
					<Checkbox 
						label={t("deleted_data")}
						checked={showInActive}
						onChange={(_, {checked}) => setShowInActive(checked)}/>
				</Grid.Column>
				<Grid.Column floated={"right"} width={"4"}>
					<Button 
						style={{marginBottom: 16}}
						color={"green"}
						floated={"right"}
						onClick={() => setOpenDetailModal(true)}>
							<Icon name={"add"}/>
						{t("admin.add_user")}
					</Button>
				</Grid.Column>
			</Grid>

			<CustomTable 
				columns={columns}
				data={data}
				page={page}
				totalPages={totalPages}
				onPageChange={(activePage) => setPage(activePage)}/>

				<UserDetailModal 
					userId={editUser}
					open={openDetailModal}
					onClose={() => setOpenDetailModal(false)}/>

				<ConfirmModal 
					title={t("admin.delete_user")}
					detail={t("admin.msg_confirm_delete_user", {name: (deleteUser ? deleteUser.username : "")})}
					open={openConfirmModal}
					onClose={() => setOpenConfirmModal(false)}
					onCancel={() => setOpenConfirmModal(false)}
					onConfirm={async () => {
						await deleteTarget(deleteUser ? deleteUser.id : null);
						setOpenConfirmModal(false);
					}}/>
		</div>
	)
}

UsersView.defaultProps = {}

UsersView.propTypes = {}