import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Segment, Header } from 'semantic-ui-react';
import CustomerSetting from '../components/customer/CustomerSetting';
import ProductSetting from '../components/product/ProductSetting';
import VehicleSetting from '../components/vehicle/VehicleSetting';

export default function SettingsView () {

  const { t, i18n } = useTranslation();
  const [activeItem, setActiveItem] = useState('customer');

  return (
    <div>
      <Header dividing style={{textAlign: "left", padding: "8px"}}>
				{t('menu.settings')}
			</Header>
      <Menu attached='top' tabular>
        <Menu.Item
          name={t('customer')}
          active={activeItem === 'customer'}
          onClick={() => setActiveItem('customer')}
        />
        <Menu.Item
          name={t('product')}
          active={activeItem === 'product'}
          onClick={() => setActiveItem('product')}
        />
        <Menu.Item
          name={t('vehicle')}
          active={activeItem === 'vehicle'}
          onClick={() => setActiveItem('vehicle')}
        />
      </Menu>
      <Segment attached='bottom' basic>
        { activeItem === 'customer' && <CustomerSetting/> }

        { activeItem === 'product' && <ProductSetting/> }

        { activeItem === 'vehicle' && <VehicleSetting/> }
      </Segment>
    </div>
  );
}