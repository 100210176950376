/**
 * HTTP Client wrapper on axios library
 *
 * Usage Example:
 * import { POST } from 'utils/HttpClient'
 * POST('/api-token-auth/', params).then(
 *   (payload) => {
 *     // Authen Success with payload parameter
 *   },
 *   (payload) => {
 *     // Authen Failed with payload parameter
 *   }
 * )
 *
 * PAYLOAD Parameters
 * action.payload.{parameters}
 * The HTTP response on both success and fail will return payload which contains
 *  - status : HTTP response code
 *    (In case of unknown error [cannot connect server, etc..] it'll return 520 (ERROR_HTTP_STATUS_CODE_UNKNOWN) error as default)
 *  - data : raw response data (In case of unknown error, it'll return error object. In case django debug error it'll return html page)
 *  - errorMessages : The formatted error message for display on screen. In success response this variable will be empty string
 *  - requestUrl : The requested url of this payload (useful when using with multiple concurrent HTTP request)
 */

import axios from "axios";
import Cookies from 'js-cookie'
import { AUTH_TOKEN } from "../constances/string";

// ============== Internal default constant variables ================
const HTTP_TIMEOUT = 30000;  // ms
const ERROR_HTTP_STATUS_CODE_UNKNOWN = 520; // use 520 as default error ref [https://en.wikipedia.org/wiki/List_of_HTTP_status_codes]
const ERROR_MSG_INITIALIZE_INTERCEPTOR =
  "Failed to intitialize interceptor for axios";
// const ERROR_MSG_URL_TYPE_UNKNOWN =
//   "Specify endpoint URL in string or array. (`/apis/url/` or [`/apis/url1/`, `/apis/url2/`])";
const ERROR_MSG_UNKNOWN_HTTP_TYPE = "Unknown HTTP request type.";
const MAX_ERROR_LINE_COUNT = 3; // Show error top 3 line on string response (e.g. django error page on debug mode)

/**
 * Construct axios instance from given paramters
 * @param {number} timeout Number of timeout in millisecond, leave null to use default value (12000)
 */
export const getAxiosInstance = (timeout) => {
  const axios_params = {
    timeout: timeout
  };

  if (process.env.REACT_APP_BASE_URL != null) {
    // Edit base URL for axios
    axios_params.baseURL = process.env.REACT_APP_BASE_URL;
  }

  const token = Cookies.get(AUTH_TOKEN.token_key);
  if (token != null) {
    axios_params["headers"] = {
      Authorization: `Bearer ${token}`
    };
  }

  let axiosInstance = axios.create(axios_params);

  // Intercept request for override pagination class to backend
  axiosInstance.interceptors.request.use(
    config => {
      // intercept a request to handle a csrf token
      config.xsrfCookieName = "csrftoken";
      config.xsrfHeaderName = "X-CSRFToken";
      // config.withCredentials = true;
      return config;
    },
    () => {
      // (error) => {  // for debug purpose
      throw new Error(ERROR_MSG_INITIALIZE_INTERCEPTOR);
    }
  );
  return axiosInstance;
};

/**
 * HTTP request to an URL endpoint
 * @param {string} method Method of HTTP REQUEST can be 'GET', 'POST', 'PUT', 'PATCH', 'DELETE'
 * @param {object} client Instance of Axios http client
 * @param {string} url Endpoint URL to send request
 * @param {object} params HTTP parameters for using with 'POST', 'PUT', 'PATCH'
 */
const doHttpRequest = (method, timeout, url, params, isFormData, isBlob) => {
  const client = getAxiosInstance(
    timeout,
  );

  let formData = null;
  let config = null;

  if (isFormData) {
    formData = new FormData()
    for (let p in params) {
      formData.append(p, params[p])
    }
    config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  }
  if (isBlob) {
    config = {
      responseType: 'blob'
    }
  }

  if (method === "GET") {
    if (Object.keys(params).length === 0) {
      if (isFormData || isBlob) {
        return client.get(url, config)
      }
      else {
        return client.get(url);
      }
    } else {
      if (isFormData || isBlob) {
        return client.get(url, { params: params, ...config });
      }
      else {
        return client.get(url, { params: params });
      }
    }
  } else if (method === "POST") {
    if (isFormData) {
      return client.post(url, formData, config);
    } else {
      if (isBlob) {
        return client.post(url, params, config);
      }
      else {
        return client.post(url, params);
      }
    }
  } else if (method === "PUT") {
    if (isFormData) {
      return client.put(url, formData, config);
    } else {
      if (isBlob) {
        return client.put(url, params, config);
      }
      else {
        return client.put(url, params);
      }

    }
  } else if (method === "PATCH") {
    if (isFormData) {
      return client.patch(url, formData, config);
    } else {
      if (isBlob) {
        return client.patch(url, params, config);
      }
      else {
        return client.patch(url, params);
      }
    }
  } else if (method === "DELETE") {
    if (isFormData) {
      return client.delete(url, formData, config);
    } else {
      if (isBlob) {
        return client.delete(url, params, config);
      }
      else {
        return client.delete(url, params);
      }
    }
  } else {
    throw new Error(
      ERROR_MSG_UNKNOWN_HTTP_TYPE +
      `[${method}]` +
      ". Only support GET, POST, PUT, PATCH, DELETE"
    );
  }
};

/**
 * Format error message into readable format to display on screen
 * @param {object} data Error response data object
 */
const formatErrorMessage = data => {
  let msg = "";
  if (typeof data === "string") {
    // Parse string get only MAX_ERROR_LINE_COUNT as a formatted text
    const lines = data.split(/\r\n|\r|\n/);
    for (var i = 0; i < lines.length; i++) {
      if (i < MAX_ERROR_LINE_COUNT) {
        msg = msg + lines[i] + "\n";
      }
    }
    return msg;
  }

  // In case of object resposne (array or json object)
  for (let key in data) {
    if (msg) {
      msg = msg + "\n";
    }
    if (key === "detail") {
      msg = msg + `${data[key]}`;
    } else if (key === "non_field_errors") {
      msg = msg + `${data[key]}`;
    } else {
      msg = msg + `${data[key]} (${key})`;
    }
  }
  return msg;
};

/**
 * Construct payload success object
 */
const getSuccessPayload = response => {
  return {
    status: response.status,
    data: response.data,
    errorMessages: "",
    requestUrl: response.request.responseURL
  };
};

/**
 * Construct payload fail object
 */
export const getFailPayload = error => {
  if (error.response != null && error.response.status === 404) {
    return {
      status: 404,
      data: error.response.data,
      errorMessages: `URL ${error.response.request.responseURL} not found`,
      requestUrl: error.response.request.responseURL
    }
  }
  else {
    return {
      status:
        typeof error.response === "undefined"
          ? ERROR_HTTP_STATUS_CODE_UNKNOWN
          : error.response.status,
      data: typeof error.response === "undefined" ? error : error.response.data,
      errorMessages:
        typeof error.response === "undefined"
          ? error.message
          : formatErrorMessage(error.response.data),
      requestUrl:
        typeof error.response === "undefined"
          ? ""
          : error.response.request.responseURL
    };
  }
};

/**
 * Handle response from doHttpRequest on single HTTP request and dispatch event based on response
 */
const handleSingleHttpRequest = (
  method,
  timeout,
  url,
  params,
  isFormData,
  isBlob
) => {
  return new Promise((resolve, reject) => {
    doHttpRequest(method, timeout, url, params, isFormData, isBlob).then(
      response => {
        resolve(getSuccessPayload(response));
      },
      error => {
        reject(getFailPayload(error));
      }
    );
  });
};

/**
 * Utility function to check promise failed for helping to catch all error in one payload.
 * Normally the axios.all will immediately call catch when error occur
 */
const checkFailed = then => responses => {
  const someFailed = responses.some(response => response.error);
  if (someFailed) {
    throw responses;
  }
  return then(responses);
};

/**
 * Handle response from doHttpRequest on multiple HTTP request and dispatch event based on response
 */
export const handleMultipleHttpRequest = (
  type,
  timeout,
  url,
  params,
  isFormData,
  isBlob,
) => {
  return new Promise((resolve, reject) => {
    const promises = url.map((singleUrl, index) => {
      return doHttpRequest(
        type,
        timeout,
        singleUrl,
        params.length > index ? params[index] : {},
        isFormData,
        isBlob
      )
    });
    const promisesResolved = promises.map(promise => {
      return promise.catch(error => ({ error }))
    }
    );
    axios.all(promisesResolved)
      .then(
        checkFailed(responses => {
          resolve(
            responses.map(response => getSuccessPayload(response))
          );
        })
      )
      .catch(responses => {
        reject(
          responses.map(response =>
            typeof response.error === "undefined"
              ? getSuccessPayload(response)
              : getFailPayload(response.error)
          )
        );
      });
  });
};

export const GET = (
  url,
  params = {},
  isFormData = false,
  isBlob = false,
) => {
  if (Array.isArray(url)) {
    return handleMultipleHttpRequest(
      "GET",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
  else {
    return handleSingleHttpRequest(
      "GET",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
};

export const POST = (
  url,
  params = {},
  isFormData = false,
  isBlob = false
) => {
  if (Array.isArray(url)) {
    return handleMultipleHttpRequest(
      "POST",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
  else {
    return handleSingleHttpRequest(
      "POST",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
};

export const PUT = (
  url,
  params = {},
  isFormData = false,
  isBlob = false
) => {
  if (Array.isArray(url)) {
    return handleMultipleHttpRequest(
      "PUT",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
  else {
    return handleSingleHttpRequest(
      "PUT",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
};

export const PATCH = (
  url,
  params = {},
  isFormData = false,
  isBlob = false
) => {
  if (Array.isArray(url)) {
    return handleMultipleHttpRequest(
      "PATCH",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
  else {
    return handleSingleHttpRequest(
      "PATCH",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
};

export const DELETE = (
  url,
  params = {},
  isFormData = false,
  isBlob = false
) => {
  if (Array.isArray(url)) {
    return handleMultipleHttpRequest(
      "DELETE",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
  else {
    return handleSingleHttpRequest(
      "DELETE",
      HTTP_TIMEOUT,
      url,
      params,
      isFormData,
      isBlob
    );
  }
};
