import React from "react";
import Cookies from 'js-cookie'
import { Route, Redirect } from "react-router-dom";
import { AUTH_TOKEN } from '../constances/string';
import { PATH } from '../routes/Routes';

export default function PrivateRoute({component: Component, ...rest}) {
  const renderComponent = (props) => {
    if (Cookies.get(AUTH_TOKEN.token_key) != null) {
      return <Component {...props} />
    }
    else {
      return (
        <Redirect
          to={{ pathname: PATH.LOGIN, state: { from: props.location } }}
        />
      )
    }
  };

  return <Route {...rest} render={renderComponent} />;
}
