import React, { createRef, useEffect, useState } from 'react';
import propTypes from "prop-types";
import { Header, Icon, Segment } from 'semantic-ui-react';
import { Doughnut } from 'react-chartjs-2';
import { pluginService } from 'chart.js';
import { ellipsisString, formatComma, shortCurrencyFormat } from '../../utils/Util';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { LAPTOP_WIDTH, MOBILE_WIDTH, SMALL_MOBILE_WIDTH } from '../../Config';
import { COLOR_SET } from '../../constances/string';


export default function PieWidget(props) {

  const {
    title,
    dataSet,
    timeScale,
    icon,
    unit,
  } = props;

  const { t, i18n } = useTranslation();
  const {width, height} = useWindowDimensions();

  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);
  const [centerText, setCenterText] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [centerAmount, setCenterAmount] = useState(0);
  const chartRef = createRef();
 
  const getCenterAmountText = () => {
    if (data.length === 0) {
      return '';
    }
    return `${shortCurrencyFormat(centerAmount)} ${unit}`
  }

  const getCenterTitleText = () => {
    if (data.length === 0) {
      return '';
    } else if (centerText) {
      return centerText;
    } 
    return t('dashboard.charts.total'); 
  }

  const renderCenterText = () => {
    pluginService.register({
      beforeDraw: (chart) => {
        if (chart.config.options.elements.center) {
          const ctx = chart.chart.ctx;

          const centerConfig = chart.config.options.elements.center;
          const fontStyle = centerConfig.fontStyle || 'Lato';
          const titleTxt = centerConfig.titleText;
          const titleColor = '#c0c1c2';
          const amountTxt = centerConfig.amountText;
          const amountColor = '#343a40';

          let maxFontSize = centerConfig.maxFontSize || 75;
          let sidePadding = centerConfig.sidePadding || 20;
          let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

          ctx.font = `30px ${fontStyle}`;
          let elementHeight = (chart.innerRadius * 2);
          let elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

          let stringWidth = Math.max(ctx.measureText(titleTxt).width, ctx.measureText(amountTxt).width);
          let amountWidth = ctx.measureText(amountTxt).width

          let widthRatio = elementWidth / stringWidth;
          let amountWidthRatio = elementWidth / amountWidth;

          let newFontSize = Math.floor(30 * widthRatio);
          let amountFontSize = Math.floor(30 * amountWidthRatio);

          let minFontSize = centerConfig.minFontSize || 36;
          let titlefontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize, minFontSize);
          let amountfontSizeToUse = Math.min(amountFontSize, elementHeight, maxFontSize, minFontSize);
          let textRatio = amountfontSizeToUse / titlefontSizeToUse
          let lineHeight = centerConfig.lineHeight || 36;

          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

          ctx.font = `${amountfontSizeToUse}px ${fontStyle}`;
          ctx.fillStyle = amountColor;
          ctx.fillText(amountTxt, centerX, centerY);

          ctx.font = `${titlefontSizeToUse/(2/textRatio)}px ${fontStyle}`;
          ctx.fillStyle = titleColor;
          ctx.fillText(titleTxt, centerX, centerY + (lineHeight+(2/textRatio)));
        }
      }
    });
  }

  const plotChart = () => {
    const results = dataSet[dataSet.length-1].results;

    const sortResults = Object.fromEntries(
      Object.entries(results).sort(([,a],[,b]) => b-a)
    );

    const renderDataset = Object.keys(sortResults).map(key => sortResults[key]).slice(0, 5);
    const renderLabel = Object.keys(sortResults).slice(0, 5);
    if (Object.keys(sortResults).length > 5) {
      const otherData = Object.keys(sortResults)
        .map(key => sortResults[key])
        .slice(5)
        .reduce((prev, val) => parseFloat(prev) + parseFloat(val));
      renderDataset.push(otherData);
      renderLabel.push(t('other'));
    }

    setData(renderDataset);
    setLabel(renderLabel);
    if(Object.keys(sortResults).length > 0) {
      const total = Object.keys(sortResults).map(key => sortResults[key]).reduce((prev, val) => parseFloat(prev) + parseFloat(val))
      setTotalAmount(total);
      setCenterAmount(total);
    }
  }

  const renderSubtitle = () => {
    if (!dataSet.length) {
      return null;
    }

    const date = dataSet[dataSet.length-1].date;
    const [day, month, year] = date.split('/');
    const pointDate = new Date(year, month-1, day)

    if (timeScale === 'day') {
      return (
        <div>
          <div>{t(`dashboard.sub_title.${timeScale}`)}</div>
          {date}
        </div>
      )
    } else if (timeScale === 'week') {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return (
        <div>
          <div>{t(`dashboard.sub_title.${timeScale}`)}</div>
          {`${pointDate.toLocaleDateString('en-GB')} - ${today.toLocaleDateString('en-GB')}`}
        </div>
      )
    } else if (timeScale === 'month') {
      return (
        <div>
          <div>{t(`dashboard.sub_title.${timeScale}`)}</div>
          {`${t(`months.${pointDate.getMonth()}`)} ${pointDate.getFullYear()}`}
        </div>
      )
    } else if (timeScale === 'year') {
      return (
        <div>
          <div>{t(`dashboard.sub_title.${timeScale}`)}</div>
          {`${pointDate.getFullYear()}`}
        </div>
      )
    }
  }


  const getChartDataSet = () => {
    return {
      labels: label,
      datasets: [{
        data: data,
        backgroundColor: [
          COLOR_SET['teal'].code,
          COLOR_SET['blue'].code,
          COLOR_SET['olive'].code,
          COLOR_SET['green'].code,
          COLOR_SET['yellow'].code,
          COLOR_SET['orange'].code,
          COLOR_SET['grey'].code,
          COLOR_SET['black'].code,
        ],
        hoverBackgroundColor: [
          COLOR_SET['teal'].code,
          COLOR_SET['blue'].code,
          COLOR_SET['olive'].code,
          COLOR_SET['green'].code,
          COLOR_SET['yellow'].code,
          COLOR_SET['orange'].code,
          COLOR_SET['grey'].code,
          COLOR_SET['black'].code,
        ],
      }]
    };
  }

  const renderChart = () => {
    if (!data.length) {
      return (
        <Segment placeholder basic>
          <Header icon disabled>
            <Icon name='file outline'/>
            {t('no_data')}
          </Header>
        </Segment>
      )
    }
    return (
      <div>
        <Doughnut
          ref={chartRef}
          data={getChartDataSet()}
          options={getChartOption()}
          height={350}
          generateLegend
        />
      </div>
    )
  }

  const getChartOption = () => {
    return {
      onHover: (e, element) => {
        if(element.length > 0) {
          setCenterText(element[0]._view.label)
          setCenterAmount(data[element[0]._index])
        } else {
          setCenterText('');
          setCenterAmount(totalAmount);
        }
      },
      maintainAspectRatio: false,
      legend: {
        display: width > SMALL_MOBILE_WIDTH,
        position: width > MOBILE_WIDTH ? 'left' : 'top',
        labels: {
          fontSize: width > LAPTOP_WIDTH ? 18 : 12,
          usePointStyle: true,
          boxWidth: width > LAPTOP_WIDTH ? 12 : 8,
          generateLabels: (chart) => {
            if (chart.data.labels.length > 0) {
              return chart.data.labels.map((label, index) => {
                const datasets = chart.data.datasets[0]
                const meta = chart.getDatasetMeta(0)

                const data = datasets.data[index];
                const text = `${ellipsisString(label)} (${formatComma(Number(data/totalAmount*100).toFixed(9))}%)`;
                const fillStyle = datasets.backgroundColor[index];
                const strokeStyle = datasets.backgroundColor[index];
                const hidden = meta.data[index].hidden;

                return {
                  text,
                  fillStyle,
                  strokeStyle,
                  hidden,
                  index,
                };
              })
            } else {
              return [];
            }
          },
        },
      },
      tooltips: {
        bodyFontSize: 16,
        callbacks: {
          label: (tooltipItem) => `${label[tooltipItem.index]}: ${shortCurrencyFormat(data[tooltipItem.index], true)} ${unit}`, 
        }
      },
      elements: {
        center: {
          amountText: getCenterAmountText(), 
          titleText: getCenterTitleText(),
        }
      },
      cutoutPercentage: 65,
    };
  }

  useEffect(() => {
    renderCenterText();
  }, []);

  useEffect(() => {
    if (dataSet.length > 0) {
      plotChart();
    } else {
      setData([]);
      setLabel([]);  
    }
  }, [dataSet])

  return (
    <Segment raised>
      <Header textAlign='left' as='h2'>
        {icon}
        <Header.Content>
          {title}
          <Header.Subheader>
            {renderSubtitle()}
          </Header.Subheader>
        </Header.Content>        
      </Header>
 
      {renderChart()}
    </Segment>
  );
}

PieWidget.defaultProps = {
  title: '',
  dataSet: [],
  timeScale: 'day',
  icon: {},
  unit: '',
}

PieWidget.propTypes = {
  title: propTypes.string,
  dataSet: propTypes.array,
  timeScale: propTypes.string,
  icon: propTypes.object,
  unit: propTypes.string,
}