import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Message, Dimmer, Loader } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GET, POST, PATCH } from '../../utils/HttpClient';
import { URL_CUSTOMER, URL_CORE } from "../../constances/urls";
import { useTranslation } from 'react-i18next';
import { Toast } from "../../utils/Toast";

const typeOptions = (t) => [
  { key: 'truck', value: 'truck', text: t('vehicle_type.truck') },
  { key: 'pickup', value: 'pickup', text: t('vehicle_type.pickup') },
  { key: 'other', value: 'other', text: t('vehicle_type.other') },
];

export default function CustomerDetailModal ({ open, onClose, vehicle, refetch}) {

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessage] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [detail, setDetail] = useState(defaultModel);
  const validationSchema = Yup.object().shape({
    type: Yup.string().required(t('required')),
    license_plate_number: Yup.string().required(t('required')),
  })
  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: detail,
		validationSchema: validationSchema,
		onSubmit: (values) => handleSubmitVehicle(values),
  })

  const fetchCustomerOptions = async () => {
    setIsLoading(true);
    let params = {
			page: 1,
			page_size: 100,
			is_active: true,
		}
    try {
      const response = await GET(URL_CUSTOMER.CUSTOMER, params);
      const options = [
        {value: null, text: '-'},
        ...response.data.results.map(element => {
          return {value: element.id, text: element.name}
        })
      ]
      setCustomerOptions(options);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchProvinceOptions = async () => {
    setIsLoading(true);
    try {
      const response = await GET(URL_CORE.PROVINCE);
      const options = [
        {value: null, text: '-'},
        ...response.data.map(element => {
          return {value: element.id, text: element.name_th}
        })
      ];
      setProvinceOptions(options);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (vehicle) {
      setDetail(vehicle);
    } else {
      setDetail(defaultModel);
    }
  }, [open])

  useEffect(() => {
    fetchCustomerOptions();
    fetchProvinceOptions();
  }, []);

  const handleSubmitVehicle = async (data) => {
    setIsLoading(true)

    try {
      const params = data;
      if (vehicle) {
        await PATCH(`${URL_CUSTOMER.VEHICLE}${vehicle.id}/`, params)
      } else {
        await POST(URL_CUSTOMER.VEHICLE, params)
      }
      formik.resetForm();
      handleCloseSuccessModal();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleReactivateVehicle = async () => {
    if (!vehicle) {
      return;
    }

    setIsLoading(true);
    try {
      const vehicleId = vehicle.id;
      const response = await POST(`${URL_CUSTOMER.VEHICLE}${vehicleId}/reactivate/`);
      handleCloseSuccessModal();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleCloseSuccessModal = () => {
    Toast.success(t("messages.success"));
    refetch();
    onClose();
  }

  return (
    <div>
      <Dimmer inverted active={isLoading}>
        <Loader inverted>{t("loading")}</Loader>
      </Dimmer>
      
      <Modal
        open={open}
      >
        <Modal.Header>{vehicle? t('edit') : t('add')}{t('vehicle')}</Modal.Header>
        <Modal.Content>
          { errorMessages && 
            <Message negative>
              <Message.Header>{t('messages.error')}</Message.Header>
              <p>{errorMessages}</p>
            </Message>
          }
          <Form>
            <Form.Field required>
              <label>{t('vehicles.vehicle_type')}</label>
              <Form.Dropdown
                fluid
                search
                selection
                options={typeOptions(t)}
                name='type'
                disabled={!detail.is_active}
                value={formik.values.type}
                error={formik.errors.type}
                onChange={(event, data) =>
                  formik.setFieldValue('type', data.value)
                }
              />
            </Form.Field>
            <Form.Field>
              <label>{t('vehicles.customer')}</label>
              <Form.Dropdown
                fluid
                search
                selection
                options={customerOptions}
                name='customer'
                disabled={!detail.is_active}
                value={formik.values.customer}
                error={formik.errors.customer}
                onChange={(event, data) =>
                  formik.setFieldValue('customer', data.value)
                }              
              />
            </Form.Field>
            <Form.Group widths='equal'>
              <Form.Field required>
                <label>{t('vehicles.license_plate')}</label>
                <Form.Input
                  fluid
                  name='license_plate_number'
                  placeholder='เลขป้ายทะเบียน'
                  readOnly={!detail.is_active}
                  value={formik.values.license_plate_number}
                  error={formik.errors.license_plate_number}
                  onChange={formik.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('vehicles.province')}</label>
                <Form.Dropdown
                  fluid
                  search
                  selection
                  options={provinceOptions}
                  name='license_plate_province'
                  disabled={!detail.is_active}
                  value={formik.values.license_plate_province}
                  error={formik.errors.license_plate_province}
                  onChange={(event, data) =>
                    formik.setFieldValue('license_plate_province', data.value)
                  }                         
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button 
            onClick={() => {
              formik.resetForm();
              onClose();
            }} 
            icon='cancel' 
            content={t('cancel')} 
          />
          { vehicle ? 
            vehicle.is_active ? 
            <Button loading={isLoading} type='button' onClick={formik.handleSubmit} icon='edit' content={t('edit')} color="blue" /> : 
            <Button loading={isLoading} type='button' onClick={handleReactivateVehicle} icon='sync alternate' content={t('reactivate')} color="blue" /> :
            <Button loading={isLoading} type='button' onClick={formik.handleSubmit} icon='add' content={t('add')} color="green" />
          }
        </Modal.Actions>
      </Modal>
    </div>
  );
}

const defaultModel = {
  type: '',
  customer: '',
  license_plate_number: '',
  license_plate_province: null,
  is_active: true,
};