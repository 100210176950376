import React, { useEffect } from 'react';
import { URL_COMPANY } from '../constances/urls';
import { GET } from '../utils/HttpClient';


const defaultCompanyShift = {
  id: null,
  amount: '',
  note: '',
  type: '',
  user_name: '',
  created: null
}

const defaultShiftStatus = {
  is_enabled: false,
  is_on_shift: false,
  is_user_shift: false,
  is_ready: false,
  is_available: false,
  current_in_shift: defaultCompanyShift,
}

export default function useShiftStatus( config={manual: false} ) {
  const [data, setData] = React.useState(defaultShiftStatus) 
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(false);

  const syncStatus = async () => {
    setIsLoading(true);

    try {
      const response = await GET(URL_COMPANY.CHECK_SHIFT_STATUS)
      setData({
        ...response.data,
        is_ready: response.data && (
          (response.data.is_on_shift && response.data.is_user_shift) || 
          !response.data.is_enabled
        ),
        is_available: response.data && (!response.data.is_on_shift && !response.is_user_shift),
      })
    } catch (e) {
      setErrorMessages(e.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!config.manual) {
      syncStatus();
    }
  }, [])

  return { data, isLoading, errorMessages, syncStatus };
}