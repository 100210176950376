import React, { useState, useEffect } from 'react';
import { Header, Grid, Icon, Segment, Button, Dimmer, Loader, Message, Dropdown } from 'semantic-ui-react';
import { GET } from '../utils/HttpClient';
import { URL_DASHBOARD } from '../constances/urls';
import useWindowDimensions from '../hooks/useWindowDimensions';
import WidgetWithChart from '../components/dashboard/WidgetWithChart';
import { useTranslation } from 'react-i18next';
import LineWidget from '../components/dashboard/LineWidget';
import PieWidget from '../components/dashboard/PieWidget';
import LineGraphSegment from "../components/dashboard/LineGraphSegment";
import { MOBILE_WIDTH, SMALL_MOBILE_WIDTH } from "../Config";

export default function DashboardView() {  
  const { t, i18n } = useTranslation();
  const { width, height } = useWindowDimensions();
  const isMobile = width <= MOBILE_WIDTH;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  const [bestSellingProduct, setBestSellingProduct] = useState([]);
  const [incomePerCustomer, setIncomePerCustomer] = useState([]);

  const [timeScale, setTimeScale] = useState('day');

  const menus = [
    {
      title: 'sales',
      color: 'blue',
      icon: 'money bill alternate outline',
      url: URL_DASHBOARD.SALES,
      format: 'currency'
    },
    {
      title: 'debt',
      color: 'yellow',
      icon: 'address book outline',
      url: URL_DASHBOARD.DEBT,
      format: 'currency'
    },
    {
      title: 'net_weight',
      color: 'teal',
      icon: 'dolly flatbed',
      url: URL_DASHBOARD.NET_WEIGHT,
      format: 'weight'
    },
    {
      title: 'passed_vehicle',
      color: 'green',
      icon: 'truck',
      url: URL_DASHBOARD.PASSED_VEHICLE,
      unit: 'vehicle'
    },
  ]

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const bestSellingProductResponse = await GET(URL_DASHBOARD.BEST_SELLING_PRODUCT, {scale: timeScale});
      setBestSellingProduct(bestSellingProductResponse.data)
      const IncomePerCustomerResponse = await GET(URL_DASHBOARD.INCOME_PER_CUSTOMER, {scale: timeScale});
      setIncomePerCustomer(IncomePerCustomerResponse.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeTimeScale = (scale) => {
    setTimeScale(scale);
  }

  useEffect(() => {
    fetchData();
  }, [timeScale]);

  return (
    <div>
      { errorMessages && 
        <Message negative>
          {errorMessages}
        </Message>
      }
      <Header dividing style={{textAlign: "left", padding: "8px"}}>
				{t('menu.dashboard')}
			</Header>
      <Dimmer inverted active={isLoading}>
        <Loader inverted>{t("loading")}...</Loader>
      </Dimmer>
      <Grid>
        <Grid.Row columns={width > MOBILE_WIDTH ? 4 : 1}>
          {menus.map(menu => (
            <Grid.Column key={`widget-${menu.title}`}>
              <WidgetWithChart
                title={menu.title}
                url={menu.url}
                icon={menu.icon}
                color={menu.color}
                currencyFormat={menu.format === 'currency'}
                weightFormat={menu.format === 'weight'}
                unit={menu.unit}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment basic>
              { width > SMALL_MOBILE_WIDTH && 
                <Button.Group basic>
                  <Button 
                    onClick={() => handleChangeTimeScale('day')} 
                    active={timeScale === 'day'}
                  >{t('dashboard.scale.day')}</Button>
                  <Button
                    onClick={() => handleChangeTimeScale('week')} 
                    active={timeScale === 'week'}
                  >{t('dashboard.scale.week')}</Button>
                  <Button 
                    onClick={() => handleChangeTimeScale('month')} 
                    active={timeScale === 'month'}
                  >{t('dashboard.scale.month')}</Button>
                  <Button
                    onClick={() => handleChangeTimeScale('year')} 
                    active={timeScale === 'year'}
                  >{t('dashboard.scale.year')}</Button>
                </Button.Group>              
              }
              { width <= SMALL_MOBILE_WIDTH && 
                <Dropdown
                  button
                  basic
                  options={[
                    {value: 'day', text: t('dashboard.scale.day')},
                    {value: 'week', text: t('dashboard.scale.week')},
                    {value: 'month', text: t('dashboard.scale.month')},
                    {value: 'year', text: t('dashboard.scale.year')},
                  ]}
                  defaultValue='day'
                  onChange={(e, data) => {
                    handleChangeTimeScale(data.value)
                  }}
                />
              }
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={width > MOBILE_WIDTH ? 2 : 1}>
          <Grid.Column>
            <PieWidget
              title={t('dashboard.best_selling_product')}
              icon={<Icon name='star' color='yellow'/>}
              timeScale={timeScale}
              dataSet={bestSellingProduct}
              name='product'
              unit={t('units.baht')}
            />
          </Grid.Column>
          <Grid.Column style={isMobile ? {marginTop: '1em'} : {}}>
            <PieWidget
              title={t('dashboard.income_per_customer')}
              icon={<Icon name='user' color='blue'/>}
              timeScale={timeScale}
              dataSet={incomePerCustomer}
              name='customer'
              unit={t('units.baht')}
            />
          </Grid.Column>
        </Grid.Row>
        
        {!isMobile &&
          <Grid.Row columns={1}>
            <Grid.Column>
              <LineWidget
                menus={menus}
                timeScale={timeScale}
              />
            </Grid.Column>
          </Grid.Row>
        }
        {isMobile &&
          <Grid.Row columns={1}>
            {
              menus.map((menu, index) => (
                <Grid.Column style={index > 0 ? {marginTop: '1em'} : {}}>
                  <LineGraphSegment 
                    title={t(`dashboard.${menu.title}`)}
                    titleIcon={menu.icon}
                    timeScale={timeScale}
                    color={menu.color}
                    url={menu.url}
                    currencyFormat={menu.format === 'currenncy'}
                    weightFormat={menu.format === 'weight'}
                    unit={menu.unit}
                  />
                </Grid.Column>
              ))                
            }            
          </Grid.Row>
        } 
      </Grid>
    </div>
  )
}