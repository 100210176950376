import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, Message, Modal } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { POST } from '../../utils/HttpClient';
import ListTable from '../common/ListTable';
import { formatComma } from '../../utils/Util';
import { URL_WEIGHT } from '../../constances/urls';
import { Toast } from '../../utils/Toast';


export default function UndoPaymentModal (props) {

  const {
    open,
    form,
    onClose,
    onConfirm,
  } = props;

  const { t, i18n } = useTranslation();

  const columns = useMemo(() => [
    {
      Header: t('logs.date'),
      accessor: 'created'
    },
    {
      Header: t('logs.user'),
      accessor: 'created_by'
    },
    {
      Header: t('cashiers.payment_type'),
      accessor: 'type',
      Cell: ({row}) => (
        <div>
          <Icon 
            color='green' 
            name={row.original.type === 'wallet' ? 
              'credit card outline' : 
              'money bill alternate outline'
            }
          />
          {t(`cashiers.${row.original.type}`)}
        </div>
      )
    },
    {
      Header: t('cashiers.amount'),
      accessor: 'amount',
      Cell: ({row}) => `${formatComma(row.original.amount)} ${t('units.baht')}`
    }

  ], []);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [data, setData] = useState({
    count: 0,
    pages: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  });

  const confirmUndoPayment = async () => {
    setIsLoading(true);

    try {
      const response = await POST(`${URL_WEIGHT.FORM}${form.id}/undo_payment/`);
      Toast.success(`${t('undo')} ${t('messages.success')}`)
      onConfirm();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      setData({
        ...data,
        count: form.payment_transactions.length,
        results: form.payment_transactions,
      })
    }
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Modal.Header>{t('cashiers.undo_payment')}</Modal.Header>
        <Modal.Content>
          <Message warning>
            <Message.Header>{t('messages.undo_payment_confirmation')}</Message.Header>
            {t('warnings.payment_transaction_will_be_deleted')}
          </Message>
          <ListTable
            columns={columns}
            data={data}
            loading={isLoading}
            error={errorMessages}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            content={t('cancel')}
            onClick={onClose}
          />
          <Button
            positive
            content={t('confirm')}
            onClick={confirmUndoPayment}
          />
        </Modal.Actions>
      </Modal>
    </div>
  )
}


UndoPaymentModal.defaultProps = {
  open: false,
  form: {
    payment_transactions: []
  },
  onClose: () => {},
  onConfirm: () => {},
}

UndoPaymentModal.propTypes = {
  open: propTypes.bool,
  form: propTypes.shape({
    payment_transactions: propTypes.array,
  }),
  onClose: propTypes.func,
  onConfirm: propTypes.func,
}