import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import {Header, Grid, Menu, Icon, Container, Pagination, Table} from 'semantic-ui-react';
import { useTable } from "react-table";

export default function CustomTable(props) {
	const { t, i18n } = useTranslation();
	const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: (props.columns) ? props.columns : [],
    data: (props.data) ? props.data : [],
    },
	);
  return (
		<div>
			{/* T A B L E */}
			<Table {...getTableProps()}>
        <Table.Header>
          {headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Table.Row {...row.getRowProps()} onClick={() => props.onSelected(row.original)}>
                {row.cells.map(cell => {
                  return <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
        { (props.data.length === 0) && 
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='100'> 
                {/* <Message icon='file outline' header='ว่างเปล่า' content="ไม่พบข้อมูล"/> */}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        }
      </Table>
      <Pagination
        activePage={props.page}
        onPageChange={(e, {activePage}) => props.onPageChange(activePage)}
        totalPages={props.totalPages}
      />
		</div>
	)
}

CustomTable.defaultProps = {
	columns: [],
	data: [],
	page: 1,
	totalPages: 1,
	onPageChange: (page) => {},
	onSelected: (data) => {}
}

CustomTable.propTypes = {
	columns: propTypes.array,
	data: propTypes.array,
	page: propTypes.number,
	totalPages: propTypes.number,
	onPageChange: propTypes.func,
	onSelected: propTypes.func
}