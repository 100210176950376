import React from 'react';
import propTypes from "prop-types";
import { Modal, Button, Form, Message, Dimmer, Loader } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GET, PATCH, POST } from '../../utils/HttpClient';
import { URL_AUTH } from "../../constances/urls";
import { useTranslation } from 'react-i18next';
import { Toast } from "../../utils/Toast";

export default function UserDetailModal (props) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [modal, setModal] = React.useState(defaultModel);
  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			...modal,
			userId: (props.userId) ? true : false,
			password: "",
			confirm_password: "",
		},
		validationSchema: Yup.object().shape({
			userId: Yup.boolean(),
			username: Yup.string().required(t("required")),
			first_name: Yup.string().required(t("required")),
			last_name: Yup.string().required(t("required")),
			email: Yup.string().required(t("required")),
			password: Yup.string()
				.when("userId", {
					is: true,
					when: Yup.string(),
					otherwise: Yup.string().required(t("required")),
				}),
			confirm_password: Yup.string()
				.when("userId", {
					is: true,
					when: Yup.string(),
					otherwise: Yup.string()
						.required(t("required"))
						.oneOf([Yup.ref("password"), null], t("profiles.passwords_do_not_match")),
				}),
		}),
		onSubmit: (values) => handleSubmitUser(values),
	});
	
	const fetchUser = async (id) => {
		setLoading(true);
		try {
			const response = await GET(`${URL_AUTH.USERS}${id}/`);
			setModal(response.data)
		} catch (error) {
			setErrorMessage(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

  const handleSubmitUser = async (data) => {
		let isSuccess = true;
		setLoading(true);
		delete data["userId"]
		const userId = props.userId;
		try {
			if (userId) { // Update
				delete data["password"];
				delete data["confirm_password"];
				const response = await PATCH(`${URL_AUTH.USERS}${userId}/`, data);
				Toast.success(t("profiles.profile_updated_successfully"));
			} else { // Create
				const response = await POST(URL_AUTH.USERS, data);
				Toast.success(t("admin.profile_created"));
			}
		} catch (error) {
			setErrorMessage(error.errorMessages);
			isSuccess = false;
		} finally {
			setLoading(false);
		}

		if (isSuccess) 
			props.onClose();
	};

	const handleReactivateUser = async () => {
		const userId = props.userId;
		if (!userId)
			return;
		
		setLoading(true);
		try {
			const response = await POST(`${URL_AUTH.USERS}${userId}/reactivate/`);
			Toast.success(t("messages.success"));
			props.onClose();
		} catch (error) {
			setErrorMessage(error.errorMessages);
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		if (props.userId) {
			fetchUser(props.userId);
		}
	}, [props.userId])
	
	React.useEffect(() => {
		if (!props.open) {
			setModal(defaultModel);
			formik.resetForm();
			setErrorMessage(null);
		}
	}, [props.open]);

  return (
		<Modal
			open={props.open}
			onClose={props.onClose}
			size={props.size}
			centered={true}>
			<Modal.Header>{props.userId ? t("admin.edit_user") : t("admin.add_user")}</Modal.Header>
			<Modal.Content>
				<Dimmer active={loading} inverted>
					<Loader inverted content={t("loading")}/>
				</Dimmer>
				{ errorMessage && 
					<Message negative style={{textAlign: "left", marginLeft: 24, marginRight: 24}}>
						<Message.Header>{t("error")}</Message.Header>
						<p>{errorMessage}</p>
					</Message> 
				}

				<Form onSubmit={formik.handleSubmit}>
					<Form.Group widths='equal'>
						<Form.Input required
							fluid
							name={"first_name"}
							label={t(`profiles.first_name`)}
							value={formik.values.first_name}
							error={formik.errors.first_name}
							onChange={formik.handleChange}
						/>

						<Form.Field required>
							<label>{t(`profiles.last_name`)}</label>
							<Form.Input
								fluid
								name={"last_name"}
								value={formik.values.last_name}
								error={formik.errors.last_name}
								onChange={formik.handleChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Field required>
						<label>{t(`profiles.username`)}</label>
						<Form.Input
							fluid
							disabled={props.userId ? true : false}
							name={"username"}
							value={formik.values.username}
							error={formik.errors.username}
							onChange={formik.handleChange}
						/>
					</Form.Field>

					<Form.Field required>
						<label>{t(`profiles.email`)}</label>
						<Form.Input
							fluid
							name={"email"}
							value={formik.values.email}
							error={formik.errors.email}
							onChange={formik.handleChange}
						/>
					</Form.Field>

					{!props.userId && (
						<Form.Group widths='equal'>
							<Form.Input required
								fluid
								name={"password"}
								label={t(`profiles.password`)}
								type={"password"}
								value={formik.values.password}
								error={formik.errors.password}
								onChange={formik.handleChange}/>

							<Form.Input required
								fluid
								name={"confirm_password"}
								label={t(`profiles.confirm_password`)}
								type={"password"}
								value={formik.values.confirm_password}
								error={formik.errors.confirm_password}
								onChange={formik.handleChange}/>
						</Form.Group>
					)}
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={props.onClose} icon='cancel' content={t('cancel')} />
				{!props.userId && (
					<Button 
						loading={loading} 
						type='button' 
						icon='add' 
						content={t('add')} 
						color="green" 
						onClick={formik.handleSubmit}/>
				)}
				{props.userId && (modal && modal.is_active) && (
					<Button 
						loading={loading} 
						type='button' 
						icon='edit'
						content={t('edit')}
						color="blue"
						onClick={formik.handleSubmit}/>
				)}
				{props.userId && (modal && !modal.is_active) && (
					<Button 
						loading={loading} 
						type='button' 
						icon='sync alternate'
						content={t('reactivate')}
						color="blue"
						onClick={handleReactivateUser}/>
				)}
			</Modal.Actions>
		</Modal>
  );
}

const defaultModel = {
  username: "",
	first_name: "",
	last_name: "",
	email: "",
};

UserDetailModal.defaultProps = {
	open: false,
	size: "small",
	userId: null,
	onClose: () => {},
}

UserDetailModal.propTypes = {
	open: propTypes.bool,
	size: propTypes.string,
	userId: propTypes.string,
	onClose: propTypes.func,
}