import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import LoginScreen from '../screens/LoginScreen';
import MenuScreen from '../screens/MenuScreen';
import ScalesScreen from "../screens/ScalesScreen";
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import { Toast } from '../utils/Toast';
import { useTranslation } from 'react-i18next';
import CustomerStatisticScreen from '../screens/CustomerStatisticScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import LiffLoginScreen from '../screens/LiffLoginScreen';
import LiffDashboardScreen from '../screens/LiffDashboardScreen';
import LiffScalesScreen from '../screens/LiffScalesScreen';
import LiffTicketScreen from '../screens/LiffTicketScreen';


export const PATH = {
  LOGIN: '/login',
  HOME: '/',
  RESET_PASSWORD: '/reset-password/',
  CUSTOMER_STATISTIC: '/c/',

  LIFF_LANDING: "/liff/",
  LIFF_DASHBOARD: "/dashboard/",
  LIFF_SCALES: "/scales/",
  LIFF_TICKET: "/ticket/",
  LIFF_TMP: "/tmp/",

  DASHBOARD: '/app-dashboard/',
  SCALE: '/app-scale/',
  SETTINGS: '/app-settings/',
  ADMIN: '/app-admin/',
  REPORTS: '/app-reports/',
  PROFILE: '/app-profile/',
}

export default function Routes(props){

  const {
    listen
  } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    listen(status => {
      Toast.info(t(status));
    })    
  })

  return (
    <Switch>
      {/** Public PATH */}
      <Route exact path={PATH.LOGIN} component={LoginScreen} />
      <Route exact path={`${PATH.RESET_PASSWORD}:id`} component={ResetPasswordScreen} />
      <Route exact path={`${PATH.CUSTOMER_STATISTIC}:id`} component={CustomerStatisticScreen} />

      {/** LIFF PATH */}
      <Route exact path={`${PATH.LIFF_LANDING}:app`} component={LiffLoginScreen} />
      <Route exact path={PATH.LIFF_DASHBOARD} component={LiffDashboardScreen} />
      <Route exact path={PATH.LIFF_SCALES} component={LiffScalesScreen} />
      <Route exact path={`${PATH.LIFF_TICKET}:id`} component={LiffTicketScreen} />

      {/** APP PATH */}
      <PrivateRoute exact path={PATH.HOME} component={MenuScreen} />
      <PrivateRoute path={`${PATH.HOME}:menu`} component={MenuScreen} />
      <Route component={NotFoundScreen}/>
    </Switch>
  )
}