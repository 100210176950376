import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Dropdown, Grid, Header, Segment } from 'semantic-ui-react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import WidgetWithChart from '../components/dashboard/WidgetWithChart';
import { MOBILE_WIDTH, SMALL_MOBILE_WIDTH } from '../Config';
import { URL_DASHBOARD } from '../constances/urls';
import LineWidget from '../components/dashboard/LineWidget';
import LineGraphSegment from '../components/dashboard/LineGraphSegment';
import { GET } from '../utils/HttpClient';
import Cookies from 'js-cookie';


export default function LiffDashboardScreen () {
  const customer = Cookies.get('customer')
  const { width, height } = useWindowDimensions();
  const isMobile = width <= MOBILE_WIDTH;
  const { t, i18n } = useTranslation();
  const [timeScale, setTimeScale] = useState('day');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [hidden, setHidden] = useState('customer_income');

  const menus = [
    {
      title: 'customer_expense',
      color: 'blue',
      icon: 'money bill alternate outline',
      url: URL_DASHBOARD.CUSTOMER_EXPENSE,
      format: 'currency'
    },
    {
      title: 'customer_income',
      color: 'olive',
      icon: 'money bill alternate outline',
      url: URL_DASHBOARD.CUSTOMER_INCOME,
      format: 'currency'
    },
    {
      title: 'debt',
      color: 'yellow',
      icon: 'address book outline',
      url: URL_DASHBOARD.DEBT,
      format: 'currency'
    },
    {
      title: 'net_weight',
      color: 'teal',
      icon: 'dolly flatbed',
      url: URL_DASHBOARD.NET_WEIGHT,
      format: 'weight'
    },
    {
      title: 'passed_vehicle',
      color: 'green',
      icon: 'truck',
      url: URL_DASHBOARD.PASSED_VEHICLE,
      unit: 'vehicle'
    },
  ]

  const compareIncomeAndExpense = async () => {
    setIsLoading(true);

    try {
      const incomeResponse = await GET(`${URL_DASHBOARD.CUSTOMER_INCOME}today/`, {customer: customer});
      const income = incomeResponse.data.amount;
      const expenseResponse = await GET(`${URL_DASHBOARD.CUSTOMER_EXPENSE}today/`, {customer: customer});
      const expense = expenseResponse.data.amount;

      setHidden(income <= expense ? 'customer_income' : 'customer_expense');
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // if (!params.id) {
    //   history.replace(PATH.LOGIN);
    // }
    compareIncomeAndExpense();
  }, []);

  const handleChangeTimeScale = (scale) => {
    setTimeScale(scale);
  }

  return (
		<Container>
			<Header dividing textAlign='left' style={{marginTop: 24}}>
					{t('dashboard.customer_statistic')}
			</Header>

      <Grid>
        <Grid.Row columns={width > MOBILE_WIDTH ? 4 : 1}>
          {menus.map(menu => !(menu.title === hidden) ? (
            <Grid.Column key={`widget-${menu.title}`}>
              <WidgetWithChart
                title={menu.title}
                url={menu.url}
                icon={menu.icon}
                color={menu.color}
                customer={customer}
                currencyFormat={menu.format === 'currency'}
                weightFormat={menu.format === 'weight'}
                unit={menu.unit}
              />
            </Grid.Column>
          ) : null)}
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment basic>
              { width > SMALL_MOBILE_WIDTH && 
                <Button.Group basic>
                  <Button 
                    onClick={() => handleChangeTimeScale('day')} 
                    active={timeScale === 'day'}
                  >{t('dashboard.scale.day')}</Button>
                  <Button
                    onClick={() => handleChangeTimeScale('week')} 
                    active={timeScale === 'week'}
                  >{t('dashboard.scale.week')}</Button>
                  <Button 
                    onClick={() => handleChangeTimeScale('month')} 
                    active={timeScale === 'month'}
                  >{t('dashboard.scale.month')}</Button>
                  <Button
                    onClick={() => handleChangeTimeScale('year')} 
                    active={timeScale === 'year'}
                  >{t('dashboard.scale.year')}</Button>
                </Button.Group>              
              }
              { width <= SMALL_MOBILE_WIDTH && 
                <Dropdown
                  button
                  basic
                  options={[
                    {value: 'day', text: t('dashboard.scale.day')},
                    {value: 'week', text: t('dashboard.scale.week')},
                    {value: 'month', text: t('dashboard.scale.month')},
                    {value: 'year', text: t('dashboard.scale.year')},
                  ]}
                  defaultValue='day'
                  onChange={(e, data) => {
                    handleChangeTimeScale(data.value)
                  }}
                />
              }
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {!isMobile && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <LineWidget
                customer={customer}
                menus={menus}
                timeScale={timeScale}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      
        {isMobile &&
          <Grid.Row columns={1}>
            {
              menus.map((menu, index) => (
                <Grid.Column style={index > 0 ? {marginTop: '1rem'} : {}}>
                  <LineGraphSegment 
                    title={t(`dashboard.${menu.title}`)}
                    titleIcon={menu.icon}
                    timeScale={timeScale}
                    customer={customer}
                    color={menu.color}
                    url={menu.url}
                    currencyFormat={menu.format === 'currenncy'}
                    weightFormat={menu.format === 'weight'}
                    unit={menu.unit}
                  />
                </Grid.Column>
              ))                
            }            
          </Grid.Row>
        } 
      </Grid>
		</Container>
  )
}
