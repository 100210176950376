import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Header, Icon, Message, Segment } from 'semantic-ui-react';
import { GET } from '../../utils/HttpClient';
import { Line } from 'react-chartjs-2';
import { checkWeightAmountUseTonne, shortCurrencyFormat, shortWeightFormat } from '../../utils/Util';
import { COLOR_SET } from '../../constances/string';


export default function LineGraphSegment(props) {
	const { t, i18n } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [label, setLabel] = React.useState([]);
	// Graph
	const [data, setData] = React.useState([]);
	const [nextUrl, setNextUrl] = React.useState(null);
  const [prevUrl, setPrevUrl] = React.useState(null);

	const fetchData = async (url) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      let params = {
        scale: props.timeScale
      }
      if (props.customer) {
        params = {
          ...params,
          customer: props.customer
        }
      }

			const response = await GET(url, params);
      const results = response.data.results.reverse();
      setData(results.map(element => element.amount));
      setTimeScaleLabel(results);
      setNextUrl(response.data.links.next);
      setPrevUrl(response.data.links.previous);

    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const setTimeScaleLabel = (results) => {
    if (props.timeScale === 'day') {
      setLabel(results.map(element => element.date));
    } else if (props.timeScale === 'week') {
      setLabel(results.map(element => {
        const [day, month, year] = element.date.split('/');
        const pointDate = new Date(year, month-1, day);
        const today = new Date();
        const beforeNextPoint = new Date(year, month-1, day);
        beforeNextPoint.setDate(beforeNextPoint.getDate() + (7 - beforeNextPoint.getDay()) % 7); //next sunday
        if (beforeNextPoint > today) {
          beforeNextPoint.setDate(today.getDate() - 1)
        }
        return `${pointDate.toLocaleDateString('en-GB')} - ${beforeNextPoint.toLocaleDateString('en-GB')}`
      }));
    } else if (props.timeScale === 'month') {
      setLabel(results.map(element => {
        const [day, month, year] = element.date.split('/');
        const pointDate = new Date(year, month-1, day)
        return `${t(`months.${pointDate.getMonth()}`)} ${pointDate.getFullYear()}`
      }));
    } else if (props.timeScale === 'year') {
      setLabel(results.map(element => {
        const [day, month, year] = element.date.split('/');
        const pointDate = new Date(year, month-1, day)
        return `${pointDate.getFullYear()}`
      }));
    }
	}
  
  const renderTooltips = (value) => {
    if (props.currencyFormat) {
      return `${shortCurrencyFormat(value, true)} ${t('units.baht')}`
    } else if (props.weightFormat) {
      return `${shortWeightFormat(value)} ${checkWeightAmountUseTonne(value) ? t('units.tonne') : t('units.kilo_gram')}`
    }
    return `${value} ${t(`units.${props.unit}`)}`
  }

	const getChartDataSet = () => {
    return {
      labels: label,
      datasets: [{
        data: data,
        borderColor: COLOR_SET[props.color].lineColor,
        backgroundColor: COLOR_SET[props.color].backgroundColor,
        pointBackgroundColor: COLOR_SET[props.color].lineColor,
        pointRadius: 4,
        pointHitRadius: 10,
        pointHoverRadius: 8,
        lineTension: 0,
      }]
    };
	}

  const getChartOptions = () => {
    return {
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 10,
          bottom: 10,
        }
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => `${props.title}: ${renderTooltips(tooltipItem.yLabel)}`, 
        }
      },
      scales: {
        xAxes: [{
          display: false,
        }],
        yAxes: [{
          ticks: {
            callback: (value) => renderTooltips(value),
            maxTicksLimit: 7,
          }
        }]
      }
    };
  }

	React.useEffect(() => {
		if (props.url && props.timeScale) {
			fetchData(props.url);
		}
	}, [props.url, props.timeScale]);

	const renderSubtitle = () => {
    const timeLabel = `${label[0]} - ${label[label.length - 1]}`.split('-');
    if (label.length > 0) {
      return (
        <div>
          <div>
            {t(`dashboard.sub_title.${props.timeScale}`)}
          </div>
          {`${timeLabel[0]} - ${timeLabel[timeLabel.length - 1]}`}
        </div>
      )
    }
  }

	return (
		<Segment>
			{ errorMessage && 
        <Message negative>
          {errorMessage}
        </Message>    
      }
			{/* Title */}
			<Header as='h3' style={{textAlign: "left", marginTop: 16}}>
				<Icon name={props.titleIcon} color={props.color}/>
				{props.title}
			</Header>
			{/* Subtitle */}
			<Header textAlign='left' as='h2'>
          <Header.Content>
            <Header.Subheader>
              {renderSubtitle()}
            </Header.Subheader>
          </Header.Content>
        </Header>

				<div>
          <Line
            data={getChartDataSet()}
            options={getChartOptions()}
            height={300}
          />
        </div>

				<Button 
          color='blue'
          disabled={nextUrl === null || loading}
          onClick={() => fetchData(nextUrl)}
        >
          <Icon name='arrow left'/>
          {t("prev")}
        </Button>
        <Button
          color='blue'
          disabled={prevUrl === null || loading}
          onClick={() => fetchData(prevUrl)}
        >
          {t("next")}
          <Icon name='arrow right'/>
        </Button>
		</Segment>
	)
}

LineGraphSegment.defaultProps = {
	title: "",
	titleIcon: null,
	color: "blue",
	timeScale: "day",
	url: null,
  unit: "baht",
  currencyFormat: false,
  weightFormat: false,
  customer: "",
}

LineGraphSegment.propTypes = {
	title: propTypes.string,
	titleIcon: propTypes.string,
	color: propTypes.string,
	timeScale: propTypes.string,
	url: propTypes.string,
	unit: propTypes.string,
  currencyFormat: propTypes.bool,
  weightFormat: propTypes.bool,
  customer: propTypes.string,
}