import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Form, Input, Dropdown } from 'semantic-ui-react';
import { GET } from "../../utils/HttpClient";
import { URL_CUSTOMER } from "../../constances/urls";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from '../../utils/Toast';


const unitOptions = (t) => [
  {key: 1, value: "gram", text: t("units.gram")},
  {key: 2, value: "kilo_gram", text: t("units.kilo_gram")},
  {key: 3, value: "tonne", text: t("units.tonne")},
  {key: 4, value: "litre", text: t("units.litre")}
]

const ProductCard = React.forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();
	const [model, setModel] = React.useState(defaultInitialModel)
	// For search
	const [loading, setLoading] = React.useState(false);
	const [dataList, setDataList] = React.useState([]);
	const priceInputRef = React.createRef(null);

	const validationSchema = Yup.object().shape({
		title: Yup.string().required(t("required")),
		price_per_unit: Yup.string().required(t("required")),
		unit: Yup.string().required(t("required")),
	});
	const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: model,
		validationSchema: validationSchema,
	});

	React.useImperativeHandle(ref, () => ({
		formik,
		initial: (data) => {
			if (data) {
				setModel({
					...data,
					title: `[${data.code}] ${data.name}`,
					description: "",
				});
			} else {
				setModel(defaultInitialModel);
			}
		}
	}));

	const getProductList = async () => {
		setLoading(true);

		try {
			const params = {
				is_active: true,
				page_size: 100,
			}
			const response = await GET(URL_CUSTOMER.PRODUCT, params);
			setDataList(
				response.data.results.map(product => {
					return {
						...product,
						key: product.id,
						text: `[${product.code}] ${product.name} - ${product.price_per_unit} ${t(`units.baht`)} / ${t(`units.${product.unit}`)}`,
						value: {
							...product,
							title: `[${product.code}] ${product.name}`,
						}
					}
				})
			);
		} catch (error) {
			Toast.error(error.errorMessages)
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		getProductList();
	}, []);
	
  return (
		<div style={{width: "100%", textAlign: "left"}}>
			<Form loading={loading}>
				<Form.Group>
					<Form.Field
						width={7}
						required
						search
						selection
						name="title"
						label={t("product")}
						placeholder={`${t("select")}...`}
						options={dataList}
						onChange={(_, data) => {
							setModel(data.value);
						}}
						selectOnBlur={false}
						value={formik.values.title}
						text={formik.values.title}
						error={formik.errors.title}
						control={Dropdown}/>

					<Form.Field
						width={4}
						required
						fluid
						error={formik.errors.price_per_unit}
					>
						<label>{t("products.price_per_unit")}</label>
						<input
							ref={priceInputRef}
							name="price_per_unit"
							onClick={() => priceInputRef.current.select()}
							value={formik.values.price_per_unit}
							onChange={formik.handleChange}
						/>
					</Form.Field>

					<Form.Field
						width={5}
						required
						fluid
						selection
						options={unitOptions(t)}
						label={`${t("products.unit")}`}
						text={formik.values.unit ? t(`units.${formik.values.unit}`) : ""}
						value={formik.values.unit}
						error={formik.errors.unit}
						onChange={(_, data) => formik.setFieldValue('unit', data.value)}
						control={Dropdown}/>

				</Form.Group>
			</Form>
		</div>
	)
})

export const defaultInitialModel = {
	id: null,
	name: "",
	code: "",
	title: "",
	price_per_unit: "",
	unit: "",
};

ProductCard.defaultProps = {
	style: null,
}

ProductCard.propTypes = {
	style: propTypes.object,
}

export default React.memo(ProductCard);