import useDropdownItem from "./useDropdownItem";
import { useMount, useUpdateEffect } from "react-use";
import { URL_CORE } from "../constances/urls";


export default function useFormikAddress (formik, fields) {
  const { provinceField, districtField, subDistrictField, zipCodeField } = fields;

  const [provinceFetchingState, reloadProvinces] = useDropdownItem({
    url: URL_CORE.PROVINCE,
  });
  const [districtFetchingState, reloadDistricts] = useDropdownItem({
    url: URL_CORE.DISTRICT,
  });
  const [subDistrictFetchingState, reloadSubDistricts] = useDropdownItem({
    url: URL_CORE.SUB_DISTRICT,
  });

  useMount(() => {
    reloadProvinces();
  });

  useUpdateEffect(() => {
    reloadSubDistricts({ clear: true });
    reloadDistricts({ clear: true });

    if (formik.values.province) {
      reloadDistricts({ id: formik.values.province });
    }
    if (formik.values.district) {
      reloadSubDistricts({ id: formik.values.district });
    }
  }, [formik.values.province, formik.values.district]);

  function changeProvince(value) {
    formik.setFieldValue(provinceField, value);
    formik.setFieldValue(districtField, 0);
    formik.setFieldValue(subDistrictField, 0);
    formik.setFieldValue(zipCodeField, '');
  }

  function changeDistrict(value) {
    formik.setFieldValue(districtField, value);
    formik.setFieldValue(subDistrictField, 0);
    formik.setFieldValue(zipCodeField, '');
  }

  function changeSubDistrict(value) {
    const subDistricts = subDistrictFetchingState.value || [];
    const subDistrict = subDistricts.find(subDistrict => subDistrict.value === value);
    const zipcode = subDistrict ? subDistrict.row.zip_code : '';

    formik.setFieldValue(subDistrictField, value);
    formik.setFieldValue(zipCodeField, zipcode);
  }

  return {
    provinceFetchingState,
    districtFetchingState,
    subDistrictFetchingState,
    changeProvince,
    changeDistrict,
    changeSubDistrict,
  };
}