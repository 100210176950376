import React from 'react';
import { useTranslation } from 'react-i18next';
import {Header, Menu, Segment} from 'semantic-ui-react';
import ClientView from '../components/admin/ClientView';
import CompanyView from "../components/admin/CompanyView";
import UsersView from "../components/admin/UsersView";

export default function AdminView(props) {
	const { t, i18n } = useTranslation();
	const [activeItem, setActiveItem] = React.useState("company");

  return (
		<div>
			<Header dividing style={{textAlign: "left", padding: "8px"}}>
        {t("menu.admin")}
      </Header>

			<Menu attached={"top"} tabular>
				<Menu.Item 
					name={t("admin.company")}
					active={activeItem === "company"}
					onClick={() => setActiveItem("company")}/>
				<Menu.Item 
					name={t("admin.users")}
					active={activeItem === "users"}
					onClick={() => setActiveItem("users")}/>
				<Menu.Item 
					name={t("admin.client")}
					active={activeItem === "client"}
					onClick={() => setActiveItem("client")}/>
			</Menu>

			<Segment attached={"bottom"}>
				{activeItem === "company" && <CompanyView />}
				{activeItem === "users" && <UsersView />}
				{activeItem === "client" && <ClientView />}	
			</Segment>
		</div>
	);
}

AdminView.defaultProps = {}

AdminView.propTypes = {}
