import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { Toast } from '../../utils/Toast';


export default function TransactionNoteModal (props) {

  const {
    open,
    onClose,
    note,
    saveNoteInTransaction,
  } = props;

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [value, setValue] = useState('');

  const handleSaveNote = async () => {
    setIsLoading(true);
    setErrorMessages(null);

    const [isSuccess, response, error] = await saveNoteInTransaction(value);

    if (isSuccess) {
      if (response) {
        Toast.success(t('messages.success'));
      }
      onClose();
    } else {
      setErrorMessages(error.errorMessages);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (open) {
      setValue(note);
    }
  }, [open]);

  return (
    <div>
      <Modal
        centered={false}
        open={open}
        onClose={onClose}
        closeIcon
        size='small'
      >
        <Modal.Header>{t('scales.add_note')}</Modal.Header>
        <Modal.Content>
          { errorMessages && 
            <Message negative>
              <Message.Header>{t('error')}</Message.Header>
              {errorMessages}
            </Message>
          }
          <Form>
            <Form.Field>
              <Form.TextArea
                name='value'
                placeholder={`${t('memo')}...`}
                value={value}
                onChange={(event, data) => setValue(data.value)}  
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={isLoading}
            content={t('cancel')}
            color='red'
            onClick={onClose}
          />
          <Button
            loading={isLoading}
            content={t('confirm')}
            color='green'
            onClick={handleSaveNote}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
}

TransactionNoteModal.defaultProps = {
  open: false,
  onClose: () => {},
  note: '',
  saveNoteInTransaction: () => {},
};

TransactionNoteModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  note: propTypes.string,
  saveNoteInTransaction: propTypes.func,
};