import React, { useState, useEffect, useMemo } from 'react';
import { DELETE, GET } from '../../utils/HttpClient';
import { URL_CUSTOMER } from "../../constances/urls";
import ListTable from '../common/ListTable';
import { Button, Icon } from 'semantic-ui-react';
import VehicleDetailModal from './VehicleDetailModal';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../common/ConfirmModal';
import { Toast } from "../../utils/Toast";


export default function VehicleSetting () {
  
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    count: 0,
    pages: 0,
    links: {
      next: null,
      previous: null,  
    },
    results: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const tableRef = React.useRef();

  const columns = useMemo(() => [
    {
      Header: t('vehicles.vehicle_type'),
      accessor: 'type',
      Cell: cell => t(`vehicle_type.${cell.row.original.type}`)
    },
    {
      Header: t('vehicles.customer'),
      accessor: 'customer_name',
    },
    {
      Header: t('vehicles.license_plate'),
      accessor: 'license_plate_number',
    },
    {
      Header: t('vehicles.province'),
      accessor: 'license_plate_province_name',
    },
    { Header: '',
      accessor: 'id',
      Cell: cell => {
        const isActive = cell.row.original.is_active;
        return (
          <div style={{textAlign: 'center'}} >
            {isActive && (
              <div>
                <Button color='blue' basic onClick={() => editVehicle(cell.row.original)}>
                  <Icon name='edit'/>
                  {t('edit')}
                </Button>
                <Button color='red' basic onClick={() => {deleteVehicle(cell.row.original)}}>
                  <Icon name='delete'/>
                  {t('delete')}
                </Button>
              </div>
            )}
            {!isActive && (
              <Button color='blue' basic onClick={() => editVehicle(cell.row.original)}>
                <Icon name='sync alternate'/>
                {t('reactivate')}
              </Button>
            )}
          </div>
      )}
    },
  ], []);

  const fetchData = async (params) => {
    if (!params) {
      params = tableRef.current.getParams();
    }
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await GET(URL_CUSTOMER.VEHICLE, params);
      setData(response.data);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteVehicle = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    setIsDeleteVisible(false);
    try {
      const response = await DELETE(`${URL_CUSTOMER.VEHICLE}${selectedVehicle.id}/`)
      Toast.success(`${t('delete')} ${t('messages.success')}`);
      fetchData();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const createVehicle = () => {
    setIsModalVisible(true);
    setSelectedVehicle(null);
  }

  const editVehicle= (vehicle) => {
    setIsModalVisible(true);
    setSelectedVehicle(vehicle);
  }

  const deleteVehicle = (vehicle) => {
    setIsDeleteVisible(true);
    setSelectedVehicle(vehicle);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ConfirmModal
        open={isDeleteVisible}
        title={t("vehicles.delete_vehicle")}
        detail={t("vehicles.msg_confirm_delete_vehicle", 
          {name: (selectedVehicle ? selectedVehicle.license_plate_number : "")})}
        onClose={() => {
          setIsDeleteVisible(false);
          setSelectedVehicle(null);
        }}
        onConfirm={handleDeleteVehicle}
        onCancel={() => {
          setIsDeleteVisible(false);
          setSelectedVehicle(null);
        }}      
      />
      
      <VehicleDetailModal
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        vehicle={selectedVehicle}
        refetch={fetchData}
      />
      <ListTable
        ref={tableRef}
        columns={columns}
        data={data}
        loading={isLoading}
        error={errorMessage}
        onCreate={createVehicle}
        refetch={fetchData}
        showActionHeader
        showPagination
      />
    </div>
  );
}