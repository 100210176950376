import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Menu, Message, Segment } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { GET } from '../../utils/HttpClient';
import { checkWeightAmountUseTonne, shortCurrencyFormat, shortWeightFormat } from '../../utils/Util';
import { URL_DASHBOARD } from '../../constances/urls';
import { COLOR_SET } from '../../constances/string';


export default function LineWidget(props) {

  const {
    customer,
    menus,
    timeScale
  } = props;

  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  const [lineWidgetActive, setLineWidgetActive] = useState('sales');
  const [color, setColor] = useState('blue');
  const [format, setFormat] = useState('currency')
  const [unit, setUnit] = useState('');

  const handleMenuChange = (menu) => {
    setLineWidgetActive(menu.title);
    fetchData(menu.url);
    setColor(menu.color);
    setFormat(menu.format);
    setUnit(menu.unit);
  }

  const fetchData = async (url) => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      let params = {
        scale: timeScale
      };
      if (customer) {
        params = {
          ...params,
          customer: customer,
        }
      }

      const response = await GET(url, params);
      const results = response.data.results.reverse();
      setData(results.map(element => element.amount));
      setTimeScaleLabel(results);
      setNextUrl(response.data.links.next);
      setPrevUrl(response.data.links.previous);

    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const setTimeScaleLabel = (results) => {
    if (timeScale === 'day') {
      setLabel(results.map(element => element.date));
    } else if (timeScale === 'week') {
      setLabel(results.map(element => {
        const [day, month, year] = element.date.split('/');
        const pointDate = new Date(year, month-1, day);
        const today = new Date();
        const beforeNextPoint = new Date(year, month-1, day);
        beforeNextPoint.setDate(beforeNextPoint.getDate() + (7 - beforeNextPoint.getDay()) % 7); //next sunday
        if (beforeNextPoint > today) {
          beforeNextPoint.setDate(today.getDate() - 1)
        }
        return `${pointDate.toLocaleDateString('en-GB')} - ${beforeNextPoint.toLocaleDateString('en-GB')}`
      }));
    } else if (timeScale === 'month') {
      setLabel(results.map(element => {
        const [day, month, year] = element.date.split('/');
        const pointDate = new Date(year, month-1, day)
        return `${t(`months.${pointDate.getMonth()}`)} ${pointDate.getFullYear()}`
      }));
    } else if (timeScale === 'year') {
      setLabel(results.map(element => {
        const [day, month, year] = element.date.split('/');
        const pointDate = new Date(year, month-1, day)
        return `${pointDate.getFullYear()}`
      }));
    }
  }

  const renderTooltips = (value) => {
    if (format === 'currency') {
      return `${shortCurrencyFormat(value, true)} ${t('units.baht')}`
    } else if (format === 'weight') {
      return `${shortWeightFormat(value)} ${checkWeightAmountUseTonne(value) ? t('units.tonne') : t('units.kilo_gram')}`
    }
    return `${value} ${t(`units.${unit}`)}`
  }

  const getChartDataSet = () => {
    return {
      labels: label,
      datasets: [{
        data: data,
        borderColor: COLOR_SET[color].lineColor,
        backgroundColor: COLOR_SET[color].backgroundColor,
        pointBackgroundColor: COLOR_SET[color].lineColor,
        pointRadius: 4,
        pointHitRadius: 10,
        pointHoverRadius: 8,
        lineTension: 0,
      }]
    };
  }

  const getChartOptions = () => {
    return {
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 10,
          bottom: 10,
        }
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => `${t(`dashboard.${lineWidgetActive}`)}: ${renderTooltips(tooltipItem.yLabel)}`, 
        }
      },
      scales: {
        xAxes: [{
          display: false,
        }],
        yAxes: [{
          ticks: {
            callback: (value) => renderTooltips(value),
            maxTicksLimit: 7,
          }
        }]
      }
    };
  }

  const renderSubtitle = () => {
    const timeLabel = `${label[0]} - ${label[label.length - 1]}`.split('-');
    if (label.length > 0) {
      return (
        <div>
          <div>
            {t(`dashboard.sub_title.${timeScale}`)}
          </div>
          {`${timeLabel[0]} - ${timeLabel[timeLabel.length - 1]}`}
        </div>
      )
    }
  }

  useEffect(() => {
    if (menus.length) {
      fetchData(menus[0].url)
    }
  }, [timeScale]);

  useEffect(() => {
    if (menus.length) {
      handleMenuChange(menus[0])
    }
  }, [menus])

  return (
    <Segment raised>
      { errorMessages && 
        <Message negative>
          {errorMessages}
        </Message>    
      }
      <Menu tabular attached='top'>
        {menus.map(menu => (
          <Menu.Item
            key={`line-${menu.title}`}
            name={menu.title}
            active={lineWidgetActive === menu.title}
            onClick={() => handleMenuChange(menu)}
          >
            <Header as='h3'>
              <Icon name={menu.icon} color={menu.color}/>
              {t(`dashboard.${menu.title}`)}
            </Header>
          </Menu.Item>
        ))}
      </Menu>
      <Segment attached='bottom' basic>
        <Header textAlign='left' as='h2'>
          <Header.Content>
            <Header.Subheader>
              {renderSubtitle()}
            </Header.Subheader>
          </Header.Content>        
        </Header>
        <div>
          <Line
            data={getChartDataSet()}
            options={getChartOptions()}
            height={300}
          />
        </div>
        <Button 
          color='blue'
          disabled={nextUrl === null || isLoading}
          onClick={() => fetchData(nextUrl)}
        >
          <Icon name='arrow left'/>
          {t("prev")}
        </Button>
        <Button
          color='blue'
          disabled={prevUrl === null || isLoading}
          onClick={() => fetchData(prevUrl)}
        >
          {t("next")}
          <Icon name='arrow right'/>
        </Button>
      </Segment>
    </Segment>
  );  
}

LineWidget.defaultProps = {
  timeScale: '',
  customer: '',
  menus: [
    {
      title: 'net_weight',
      color: 'teal',
      icon: 'dolly flatbed',
      url: URL_DASHBOARD.NET_WEIGHT,
      format: 'weight'
    },
  ],
}

LineWidget.propTypes = {
  timeScale: propTypes.string,
  customer: propTypes.string,
  menus: propTypes.arrayOf(
    propTypes.shape({
      title: propTypes.string,
      color: propTypes.string,
      icon: propTypes.string,
      url: propTypes.string,
    }),
  ),
}