import React, { useState, useEffect } from 'react';
import {Header, Grid, Menu, Icon, Container} from 'semantic-ui-react';
import propTypes from "prop-types";

export default function PageHeader(props) {
  return (
		<Container fluid 
			textAlign={"justified"} 
			style={{
				height: 56, 
				paddingLeft: "16px", 
				paddingRight: "16px", 
				borderBottom: "2px solid #E5E7E9"
				}}>

			<div 
				style={{
					height: "100%", 
					display: "flex", 
					alignItems: "center"
				}}>
				<div 
					style={{
						width: "60%", 
						display: "flex", 
						alignItems: "center"
					}}>
					<Header size={"medium"}>
						{props.onBack && (
							<Icon name={"arrow left"} onClick={props.onBack}/>
						)}
						{props.title}
					</Header>
				</div>
				<div style={{width: "40%", display: "flex", justifyContent: 'flex-end'}}>
					{props.elements}
				</div>
			</div>
			
		</Container>)
}

PageHeader.defaultProps = {
	title: "Title",
	elements: null,
	onBack: null,
}

PageHeader.propTypes = {
	title: propTypes.string,
	elements: propTypes.element,
	onBack: propTypes.func,
}