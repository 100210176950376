import React, { useState, useRef } from 'react';
import propTypes from "prop-types";
import { Modal, Button, Form, Message, Dimmer, Loader, Segment, Header, Label } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { GET, POST, PATCH } from '../../utils/HttpClient';
import { URL_CORE, URL_CUSTOMER } from "../../constances/urls";
import Address from '../common/Address';
import { useTranslation } from 'react-i18next';
import { Toast } from "../../utils/Toast";

export default function CustomerDetailModal ({ open, onClose, customer, refetch, onUpdateSuccess}) {
  
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [errorMessages, setErrorMessage] = useState(null);
  const [detail, setDetail] = useState(defaultModel);
  const [addressDetail, setAddressDetail] = useState(defaultAddrressModel);
  const [liffOTP, setLiffOTP] = React.useState(null)
  const addressRef = useRef(null);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
  });
  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: detail,
		validationSchema: validationSchema,
		onSubmit: (values) => handleSubmitCustomer(values),
  })

  const fetchAddressDetail = async () => {
    setIsLoading(true);
    try {
      const response = await GET(`${URL_CORE.THAI_ADDRESS}${customer.address}/`)
      setAddressDetail(response.data);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmitCustomer = async (data) => {
    const addressForm = addressRef.current.formik;
    const addressValidation = await addressForm.validateForm();
    const isAddressValid = Object.keys(addressValidation).length === 0;

    if (isAddressValid) {
      setIsLoading(true)
      
      try {
        const addressParams = addressForm.values;
        const customerParams = data;
        let response = null;
        if (customer) {
          await PATCH(`${URL_CORE.THAI_ADDRESS}${customer.address}/`, addressParams)
          response = await PATCH(`${URL_CUSTOMER.CUSTOMER}${customer.id}/`, customerParams)
        } else {
          const addressResponse = await POST(URL_CORE.THAI_ADDRESS, addressParams)
          response = await POST(URL_CUSTOMER.CUSTOMER, {...customerParams, address: addressResponse.data.id})
        }
        onUpdateSuccess(response.data);
        formik.resetForm();
        handleCloseSuccessModal();
      } catch (error) {
        setErrorMessage(error.errorMessages);
      } finally {
        setIsLoading(false);
      }
    }

    return false;
  }

  const handleReactivateCustomer = async () => {
    if (!customer) {
      return;
    }

    setIsLoading(true);
    try {
      const customerId = customer.id;
      const response = await POST(`${URL_CUSTOMER.CUSTOMER}${customerId}/reactivate/`);
      handleCloseSuccessModal();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleCloseSuccessModal = () => {
    Toast.success(t("messages.success"));
    refetch();
    onClose();
  }

  const handleOTP = async () => {
    if (!customer) {
      return;
    }
    setIsOTPLoading(true);
    try {
      const customerId = customer.id;
      const response = await GET(`${URL_CUSTOMER.VIEW_CUSTOMER_OTP}${customerId}/`);
      setLiffOTP(response.data.liff_otp)
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsOTPLoading(false);
    }
  }

  React.useEffect(() => {
    if (customer) {
      setDetail(customer);
      fetchAddressDetail();
    } else {
      setDetail(defaultModel);
      setAddressDetail(defaultAddrressModel);
    }
  }, [customer])

  React.useEffect(() => {
    if (open) 
      setLiffOTP(null);

  }, [open])

  return (
    <div>
      <Dimmer inverted active={isLoading}>
        <Loader inverted>{t("loading")}</Loader>
      </Dimmer>
      
      <Modal
        open={open}
      >
        <Modal.Header>{customer? t('edit') : t('add')}{t('customer')}</Modal.Header>
        <Modal.Content>
          { errorMessages && 
            <Message negative>
              <Message.Header>{t('messages.error')}</Message.Header>
              <p>{errorMessages}</p>
            </Message>
          }
          <Form onSubmit={formik.handleSubmit}>
            <Form.Field required>
              <label>{t(`customers.name`)}</label>
              <Form.Input
                fluid
                name='name'
                placeholder='ชื่อ-นามสกุลลูกค้า'
                readOnly={!detail.is_active}
                value={formik.values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
              />
            </Form.Field>
            <Segment>
              <Address readOnly={!detail.is_active} ref={addressRef} initialValues={addressDetail}/>
            </Segment>
            {/* <Segment>
              <Header>{t('other')}</Header>
              <Form.Checkbox
                toggle
                name='ticket_qr_enable'
                label={t('customers.ticket_qr_enable')}
                readOnly={!detail.is_active}
                error={formik.errors.ticket_qr_enable}
                checked={formik.values.ticket_qr_enable}
                onChange={(e, data) => formik.setFieldValue('ticket_qr_enable', data.checked)}
              />
            </Segment> */}

            {/* {customer && (
              <div style={{textAlign: "center"}}>
                <QRCode value={customer.id} includeMargin={true} size={256} />
              </div>
            )} */}
            <Button type={"button"} color={"green"} loading={isOTPLoading} onClick={handleOTP}>OTP Request</Button>
            {liffOTP && (
              <Label color={"blue"}>{`รหัส OTP : ${liffOTP}`}</Label>
            )}
            
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button 
            onClick={() => {
              formik.resetForm();
              onClose();
            }} 
            icon='cancel' 
            content={t('cancel')} 
          />
          { customer ? 
            customer.is_active ?
            <Button loading={isLoading} type='button' onClick={formik.handleSubmit} icon='edit' content={t('edit')} color="blue" /> : 
            <Button loading={isLoading} type='button' onClick={handleReactivateCustomer} icon='sync alternate' content={t('reactivate')} color="blue" /> : 
            <Button loading={isLoading} type='button' onClick={formik.handleSubmit} icon='add' content={t('add')} color="green" />
          }
        </Modal.Actions>
      </Modal>
    </div>
  );
}

const defaultModel = {
  name: '',
  address: '',
  ticket_qr_enable: false,
  is_active: true,
};

const defaultAddrressModel = {
  zip_code: '',
  no: '',
  moo: '',
  building_th: '',
  building_en: '',
  floor: '',
  soi_th: '',
  soi_en: '',
  road_th: '',
  road_en: '',
  sub_district: 0,
  district: 0,
  province: 0,  
};

CustomerDetailModal.defaultProps = {
  open: false,
  refetch: () => {},
  onClose: () => {},
	onUpdateSuccess: (data) => {},
}

CustomerDetailModal.propTypes = {
  open: propTypes.bool,
  refetch: propTypes.func,
  onClose: propTypes.func,
  onUpdateSuccess: propTypes.func,
}