import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';
import { Table, Dimmer, Loader, Message, Pagination, Form, Button, Icon, Grid, Input, Checkbox } from 'semantic-ui-react';
import propTypes from "prop-types";


const PAGE_SIZE = 10;

const ListTable = React.forwardRef((props, ref) => {

  const {
    columns,
    data,
    loading,
    error,
    onCreate,
    refetch,
    showActionHeader,
    showPagination,
  } = props;

  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const [searched, setSearched] = useState(null);
  const [showInActive, setShowInActive] = useState(false);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: data.results,
    },
  );

  const handlePaginationChange = (e, { activePage }) => {
    const params = { page: activePage, page_size: PAGE_SIZE, is_active: !showInActive };
    refetch(params);
    setPage(activePage);
  }

  const handleReloadData = () => {
    const params = { page: 1, page_size: PAGE_SIZE, is_active: !showInActive };
    refetch(params);
    setPage(1);
    setSearchValue(null);
    setSearched(null);
  }

  const handleSearch = () => {
    if (searched === searchValue) {
      return;
    }
    const params = { search: searchValue, is_active: !showInActive };
    refetch(params)
    setSearched(searchValue);
  }

  React.useImperativeHandle(ref, () => ({
		getParams: () => {
			return {
        page: 1, 
        page_size: PAGE_SIZE, 
        is_active: !showInActive
      }
		}
	}));

  React.useEffect(() => {
    handleReloadData();
  }, [showInActive]);

  const renderActionHeader = () => {
    if (showActionHeader) {
      return (
        <Grid columns='2'>
          <Grid.Column floated='left' width={"12"}>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <label>{t('search')}</label>
                  <Input
                    value={searchValue || ''}
                    onChange={e => {
                      setSearchValue(e.target.value);
                    }}
                    placeholder={`${t('search')}...`}
                    action
                  >
                    <input />
                    <Button onClick={handleSearch}>
                      <Icon name='search' />
                      {t('search')}
                    </Button>
                  </Input>
                </Form.Field>

                <Button color='blue' icon='redo' onClick={handleReloadData}>
                </Button>

                <Form.Field style={{marginLeft: 16}}>
                  <Checkbox 
                    label={t('deleted_data')} 
                    checked={showInActive}
                    onChange={(e, {checked}) => setShowInActive(checked)}/>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column floated='right' width='4'>
            <Button color='green' floated='right' onClick={onCreate}>
              <Icon name='add' />
              {`${t('add')}`}
            </Button>
          </Grid.Column>
        </Grid>
      )
    }
    return null;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Dimmer inverted active={loading}>
        <Loader inverted>{t('loading')}</Loader>
      </Dimmer>
      { error &&
        <Message negative>
          <Message.Header>{t('error')}</Message.Header>
          <p>{error}</p>
        </Message>
      }
      { renderActionHeader()}
      <Table {...getTableProps()} unstackable>
        <Table.Header>
          {headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
        {data.count === 0 && !loading &&
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='100'>
                {/* <Message icon='file outline' header='ว่างเปล่า' content="ไม่พบข้อมูล"/> */}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        }
      </Table>
      { showPagination && 
        <Pagination
          activePage={page}
          onPageChange={handlePaginationChange}
          totalPages={data.pages}
        />
      }
    </div>
  );
})

ListTable.defaultProps = {
  columns: [],
  data: {
    count: 0,
    pages: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  loading: false,
  error: '',
  onCreate: () => null,
  refetch: () => null,
  showActionHeader: false,
  showPagination: false,
}

ListTable.propTypes = {
  columns: propTypes.array,
  data: propTypes.shape({
    count: propTypes.number,
    pages: propTypes.number,
    links: propTypes.shape({
      next: propTypes.string,
      previous: propTypes.string,
    }),
    results: propTypes.array,
  }),
  loading: propTypes.bool,
  error: propTypes.string,
  onCreate: propTypes.func,
  refetch: propTypes.func,
  showActionHeader: propTypes.bool,
  showPagination: propTypes.bool,
}

export default React.memo(ListTable)