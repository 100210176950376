import React, { createRef, forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Dimmer, Header, Icon, Loader, Message, Popup, Segment } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { checkWeightAmountUseTonne, shortCurrencyFormat, shortWeightFormat } from '../../utils/Util';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { GET } from '../../utils/HttpClient';
import WidgetDetailModal from './WidgetDetailModal';
import { COLOR_SET } from '../../constances/string';


const WidgetWithChart = (props) => {

  const {
    title,
    url,
    customer,
    icon,
    color,
    currencyFormat,
    weightFormat,
    unit,
  } = props;

  const { t, i18n } = useTranslation();

  const [todayAmount, setTodayAmount] = useState(0);
  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [gradient, setGradient] = useState({});
  const chartRef = createRef();

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await GET(url, customer ? {customer} : {});
      const results = response.data.results.reverse();
      setData(results.slice(-7).map(element => element.amount));
      setLabel(results.slice(-7).map(element => element.date));

      const todayResponse = await GET(`${url}today/`, customer ? {customer} : {});
      setTodayAmount(todayResponse.data.amount);

    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const renderRecentInformation = () => {
    return  (
      <div>
        <span style={{fontSize: 36, fontWeight: 'bold'}}>
          { currencyFormat && `${shortCurrencyFormat(todayAmount, true)}` }
          { weightFormat && `${shortWeightFormat(todayAmount)}` }
          { (!currencyFormat && !weightFormat) && todayAmount}
        </span>
        { currencyFormat && `${t('units.baht')}` }
        { weightFormat && `${checkWeightAmountUseTonne(todayAmount) ? t('units.tonne') : t('units.kilo_gram')}` }
        { (!currencyFormat && !weightFormat) && t(`units.${unit}`)}
      </div>
    );
  }

  const renderTooltips = (value) => {
    if (currencyFormat) {
      return `${shortCurrencyFormat(value, true)} ${t('units.baht')}`
    } else if (weightFormat) {
      return `${shortWeightFormat(value)} ${checkWeightAmountUseTonne(value) ? t('units.tonne') : t('units.kilo_gram')}`
    }
    return `${value} ${t(`units.${unit}`)}`
  }

  const createGradient = () => {
    const grd = chartRef.current.chartInstance.ctx.createLinearGradient(0, 0, 0, 70);
    grd.addColorStop(0, COLOR_SET['white'].backgroundColor)
    grd.addColorStop(1, COLOR_SET['white'].noneColor)
    setGradient(grd)
  }

  const getChartDataSet = () => {
    return {
      labels: label,
      datasets: [{
        data: data,
        borderColor: COLOR_SET['white'].code,
        backgroundColor: gradient,
        pointBackgroundColor: COLOR_SET['white'].code,
        pointRadius: 4,
        pointHitRadius: 10,
        pointHoverRadius: 8,
        lineTension: 0,
      }]
    };
  }

  const getChartOptions = () => {
    return {
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 10,
          bottom: 10,
        }
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => `${t(`dashboard.${title}`)}: ${renderTooltips(tooltipItem.yLabel)}`, 
        }
      },
      scales: {
        xAxes: [{
          display: false,
        }],
        yAxes: [{
          display: false,
        }], 
      }
    };
  }
  
  useEffect(() => {
    createGradient();
    fetchData();
  }, []);

  return (
    <Segment style={{padding: 0}} color={color} inverted clearing >
      <Dimmer active={isLoading}>
        <Loader>{t("loading")}...</Loader>
      </Dimmer>

      { errorMessages &&
        <Message negative>
          {errorMessages}
        </Message>
      }
      <WidgetDetailModal
        open={isDetailVisible}
        onClose={() => {setIsDetailVisible(false)}}
        title={title}
        icon={icon}
        customer={customer}
      />
      <Popup
        content={t('dashboard.detail')}
        position='bottom center'
        inverted
        trigger={
          <Button 
            floated='right' 
            icon='eye'
            size='huge' 
            circular 
            color={color} 
            onClick={() => {setIsDetailVisible(true)}}
            disabled={isLoading}
          />
        }
      />
      <Header textAlign='left' style={{paddingTop: '1em', paddingLeft: '1em'}}>
        <Icon name={icon}/>
        {t(`dashboard.${title}`)}
      </Header>
      {renderRecentInformation()}
      <div style={{height: 80}}>
        <Line
          ref={chartRef}
          data={getChartDataSet()}
          options={getChartOptions()}
          height={80}
        />
      </div>
    </Segment>
  )
}

export default memo(WidgetWithChart);

WidgetWithChart.defaultProps = {
  title: '',
  url: '',
  customer: '',
  icon: '',
  color: '',
  currencyFormat: false,
  weightFormat: false,
  unit: '',
}

WidgetWithChart.propTypes = {
  title: propTypes.string,
  url: propTypes.string,
  customer: propTypes.string,
  icon: propTypes.string,
  color: propTypes.string,
  currencyFormat: propTypes.bool,
  weightFormat: propTypes.bool,
  unit: propTypes.string,
}
