import React, { useState, useEffect } from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Header, Button, Form, Input, Modal, Label, Loader, Message, Icon } from 'semantic-ui-react';
import { GET } from "../../utils/HttpClient";
import { URL_WEIGHT } from "../../constances/urls";
import { formatComma } from "../../utils/Util";
import Cookies from "js-cookie";
import { SERVER } from "../../Config";


const ScaleCard = React.forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();
	const [dateTime, setDateTime] = React.useState(props.initialDateTime);
	const [weight, setWeight] = React.useState(props.initialWeight);
	const [loading, setLoading] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(null);

	React.useImperativeHandle(ref, () => ({
		dateTime, 
		weight,
		initial: (dateTime, weight) => {
			setDateTime(dateTime);
			setWeight(weight);
		},
		setError: (msg) => {
			setErrorMessage(msg)
		}
	}));

	const handleScale = async () => {
		setLoading(true);
		try {
			const serverIP = Cookies.get("server");
			const serverPort = Cookies.get("server_port");
			const response = await GET(
				`http://${serverIP ? serverIP : SERVER.DEFAULT_IP}:${serverPort ? serverPort : SERVER.DEFAULT_PORT}${URL_WEIGHT.MEASURE}`
			);
			if (!dateTime && !weight) {
				// auto save weight and time when no weight data in the exist ticket in database
				props.onAutoSaveTransaction(response.data.date, response.data.weight);
			}
			setDateTime(new Date(response.data.date));
			setWeight(formatComma(response.data.weight));

			if (errorMessage)
				setErrorMessage(null);
		} catch(error) {
			console.log(error);
			setErrorMessage(t("scales.error_cannot_get_data_from_the_scale"));
		} finally {
			setLoading(false);
		}
	}

	const getDateToString = (date) => {
		if (!date)
			return "";
			
		return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
	}

	const getTimeToString = (date) => {
		if (!date)
			return "";
		
		return `${("0" +date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`
	}

  useEffect(() =>{ 
		if (weight) {
			props.onChange(dateTime, weight);
		}
  }, [weight]);

  return (
		<div style={{margin: 8, ...props.style}}>
			{ errorMessage && 
        <Message negative style={{textAlign: "left"}}>
          <Message.Header>{t("error")}</Message.Header>
          <p>{errorMessage}</p>
        </Message> 
      }

			<Form>
				<Form.Group inline>
					<Form.Field disabled={props.disabled} style={{width: "10%"}}>
						<Header as={"h4"}>{props.title}</Header>
					</Form.Field>
					<Form.Field inline disabled={props.disabled}>
						<label>{`${t("scales.date")}`}</label>
						<Label size="big" style={{ width: "200px" }}>{dateTime ? getDateToString(dateTime) : "-"}</Label>
					</Form.Field>
					<Form.Field inline disabled={props.disabled}>
						<label>{t("scales.time")}</label>
						<Label size="big" style={{ width: "200px" }}>{dateTime ? getTimeToString(dateTime) : "-"}</Label>
					</Form.Field>
					<Form.Field inline disabled={props.disabled}>
						<label>{t("scales.weigh")}</label>
						<Label size="big" style={{ width: "200px" }}>{weight ? `${weight} ${t("units.kilo_gram")}` : "-"}</Label>
					</Form.Field>
					<Button 
						disabled={props.disabled}
						icon={weight ? "repeat" : "weight"}
						color={weight ? "blue" : "violet"}
						onClick={handleScale}/>
				</Form.Group>
				{props.enableHistoryTransacion && 
					<a onClick={() => props.openTransactionHistory()}>
						<Icon name="file alternate"/>
						{t("scales.select_from_previous_transaction")}
					</a>
				}
			</Form>

			{/* Loading modal */}
			<Modal centered basic open={loading} closeOnDimmerClick={false}>
				<Modal.Content>
					<Loader active={loading} content={`${t("scales.weighing")}...`}/>
				</Modal.Content>
			</Modal>
		</div>
	)
});

ScaleCard.defaultProps = {
	style: null,
	title: "title",
	disabled: false,
	initialDateTime: null,
	initialWeight: null,
	onChange: (date, weight) => {},
	enableHistoryTransacion: false,
	openTransactionHistory: () => {},
	onAutoSaveTransaction: () => {},
}

ScaleCard.propTypes = {
	style: propTypes.object,
	title: propTypes.string,
	disabled: propTypes.bool,
	initialDateTime: propTypes.object,
	initialWeight: propTypes.string,
	onChange: propTypes.func,
	enableHistoryTransacion: propTypes.bool,
	openHistoryTransactionModal: propTypes.func,
	onAutoSaveTransaction: propTypes.func,
}

export default ScaleCard;