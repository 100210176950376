import { useAsyncFn } from "react-use";
import { GET } from "../utils/HttpClient";
import { useTranslation } from 'react-i18next';

async function doFetch (lang, id ,url) {
  const response = await GET(id ? url + id : url);
  const items = response.data.map(data => {
    const {id, name_th, name_en} = data;
    return { 
      key: id, 
      text: (lang === "th") ? name_th : name_en, 
      value: id, 
      row: data};
  });

  return items
}

export default function useDropdownItem ({url}) {
  const { t, i18n } = useTranslation();
  const [state, reload] = useAsyncFn(async ({ clear=false, id=null, params={} } = {}) => {
    if (clear) {
      return [];
    }

    return doFetch(i18n.language, id, url, params);
  }, [url]);

  return [state, reload];
}