import React, { useImperativeHandle, useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useFormikAddress from '../../hooks/useFormikAddress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Address = React.memo(React.forwardRef( (props, ref) => {
  const { t, i18n } = useTranslation();
  const [detail, setDetail] = useState(defaultInitialModal);
  const validationSchema = Yup.object().shape({
    no: Yup.string().required(t("required")),
    sub_district: Yup.string().required(t("required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: detail,
		validationSchema: validationSchema,
  })

  const {
    provinceFetchingState,
    districtFetchingState,
    subDistrictFetchingState,
    changeProvince,
    changeDistrict,
    changeSubDistrict,
  } = useFormikAddress(formik, {
    provinceField: 'province',
    districtField: 'district',
    subDistrictField: 'sub_district',
    zipCodeField: 'zip_code',
  });
  const provinceOptions = provinceFetchingState.value || [];
  const districtOptions = districtFetchingState.value || [];
  const subDistrictOptions = subDistrictFetchingState.value || [];

  useImperativeHandle(ref, () => ({formik}));

  useEffect(() => {
    if (props.initialValues) {
      setDetail(props.initialValues);
    } else {
      setDetail(defaultInitialModal);
    }
  }, [props.initialValues]);

  return (
    <div>
      {!props.noHeader && (
        <h3>{t('customers.address')}</h3>
      )}
      
      <Form.Group widths='equal'>
        <Form.Field required>
          <label>{t('address.no')}</label>
          <Form.Input
            fluid
            name='no'
            placeholder={t('address.no')}
            readOnly={props.readOnly}
            value={formik.values.no}
            error={formik.errors.no}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.building')} (TH)</label>
          <Form.Input
            fluid
            name='building_th'
            placeholder={t('address.building')}
            readOnly={props.readOnly}
            value={formik.values.building_th}
            error={formik.errors.building_th}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.building')} (EN)</label>
          <Form.Input
            fluid
            name='building_en'
            placeholder={t('address.building')}
            readOnly={props.readOnly}
            value={formik.values.building_en}
            error={formik.errors.building_en}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.floor')}</label>
          <Form.Input
            fluid
            name='floor'
            placeholder={t('address.floor')}
            readOnly={props.readOnly}
            value={formik.values.floor}
            error={formik.errors.floor}
            onChange={formik.handleChange}
          />
        </Form.Field>
        </Form.Group>
        <Form.Group widths='equal'>
        <Form.Field>
          <label>{t('address.moo')}</label>
          <Form.Input
            fluid
            name='moo'
            placeholder={t('address.moo')}
            readOnly={props.readOnly}
            value={formik.values.moo}
            error={formik.errors.moo}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.soi')} (TH)</label>
          <Form.Input
            fluid
            name='soi_th'
            placeholder={t('address.soi')}
            readOnly={props.readOnly}
            value={formik.values.soi_th}
            error={formik.errors.soi_th}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.soi')} (EN)</label>
          <Form.Input
            fluid
            name='soi_en'
            placeholder={t('address.soi')}
            readOnly={props.readOnly}
            value={formik.values.soi_en}
            error={formik.errors.soi_en}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.road')} (TH)</label>
          <Form.Input
            fluid
            name='road_th'
            placeholder={t('address.road')}
            readOnly={props.readOnly}
            value={formik.values.road_th}
            error={formik.errors.road_th}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.road')} (EN)</label>
          <Form.Input
            fluid
            name='road_en'
            placeholder={t('address.road')}
            readOnly={props.readOnly}
            value={formik.values.road_en}
            error={formik.errors.road_en}
            onChange={formik.handleChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>{t('address.province')}</label>
          <Form.Dropdown
            fluid
            search
            selection
            name='province'
            disabled={props.readOnly}
            value={formik.values.province}
            error={formik.errors.province}
            options={provinceOptions}
            onChange={(event, data) => {
              changeProvince(data.value);
              formik.setFieldValue('province', data.value);
            }}          
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.district')}</label>
          <Form.Dropdown
            fluid
            search
            selection
            name='district'
            disabled={props.readOnly}
            value={formik.values.district}
            error={formik.errors.district}
            options={districtOptions}
            onChange={(event, data) => {
              changeDistrict(data.value);
              formik.setFieldValue('district', data.value);
            }}          
          />
        </Form.Field>
        <Form.Field required>
          <label>{t('address.sub_district')}</label>
          <Form.Dropdown
            fluid
            search
            selection
            name='sub_district'
            disabled={props.readOnly}
            value={formik.values.sub_district}
            error={formik.errors.sub_district}
            options={subDistrictOptions}
            onChange={(event, data) => {
              changeSubDistrict(data.value);
              formik.setFieldValue('sub_district', data.value);
            }}          
          />
        </Form.Field>
        <Form.Field>
          <label>{t('address.zip_code')}</label>
          <Form.Input
            // readOnly
            fluid
            name='zip_code'
            placeholder={t('address.zip_code')}
            readOnly={props.readOnly}
            value={formik.values.zip_code}
            error={formik.errors.zip_code}
          />
        </Form.Field>
      </Form.Group>
    </div>
  );

}));

const defaultInitialModal = {
  zip_code: '',
  no: '',
  moo: '',
  building_th: '',
  building_en: '',
  floor: '',
  soi_th: '',
  soi_en: '',
  road_th: '',
  road_en: '',
  sub_district: 0,
  district: 0,
  province: 0,
};

Address.defaultProps = {
  noHeader: false,
}

Address.propTypes = {
  initialValues: PropTypes.shape({
    zip_code: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    no: PropTypes.string,
    moo: PropTypes.string,
    building_th: PropTypes.string,
    building_en: PropTypes.string,
    floor: PropTypes.string,
    soi_th: PropTypes.string,
    soi_en: PropTypes.string,
    road_th: PropTypes.string,
    road_en: PropTypes.string,
    sub_district: PropTypes.number,
    district: PropTypes.number,
    province: PropTypes.number,
    tel: PropTypes.string,
  }),
  noHeader: PropTypes.bool,
}

export default Address;
