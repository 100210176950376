import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Dropdown, Grid, Header, Icon, Image, Menu, Segment, Sidebar } from 'semantic-ui-react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { PATH } from '../routes/Routes';
import logo from "../assets/logo.png";
import logoNoText from "../assets/logo_no_text.png";
import WidgetWithChart from '../components/dashboard/WidgetWithChart';
import { MOBILE_WIDTH, SMALL_MOBILE_WIDTH } from '../Config';
import { URL_DASHBOARD } from '../constances/urls';
import LanguageMenu from '../components/common/LanguageMenu';
import LineWidget from '../components/dashboard/LineWidget';
import LineGraphSegment from '../components/dashboard/LineGraphSegment';
import { GET } from '../utils/HttpClient';


export default function CustomerStatisticScreen () {

  const params = useParams();
  const history = useHistory();
  const { width, height } = useWindowDimensions();
  const isMobile = width <= MOBILE_WIDTH;
  const { t, i18n } = useTranslation();
  const [menu, setMenu] = useState("dashboard");
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [timeScale, setTimeScale] = useState('day');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [hidden, setHidden] = useState('customer_income');

  const menus = [
    {
      title: 'customer_expense',
      color: 'blue',
      icon: 'money bill alternate outline',
      url: URL_DASHBOARD.CUSTOMER_EXPENSE,
      format: 'currency'
    },
    {
      title: 'customer_income',
      color: 'olive',
      icon: 'money bill alternate outline',
      url: URL_DASHBOARD.CUSTOMER_INCOME,
      format: 'currency'
    },
    {
      title: 'debt',
      color: 'yellow',
      icon: 'address book outline',
      url: URL_DASHBOARD.DEBT,
      format: 'currency'
    },
    {
      title: 'net_weight',
      color: 'teal',
      icon: 'dolly flatbed',
      url: URL_DASHBOARD.NET_WEIGHT,
      format: 'weight'
    },
    {
      title: 'passed_vehicle',
      color: 'green',
      icon: 'truck',
      url: URL_DASHBOARD.PASSED_VEHICLE,
      unit: 'vehicle'
    },
  ]

  const compareIncomeAndExpense = async () => {
    setIsLoading(true);

    try {
      const incomeResponse = await GET(`${URL_DASHBOARD.CUSTOMER_INCOME}today/`, {customer: params.id});
      const income = incomeResponse.data.amount;
      const expenseResponse = await GET(`${URL_DASHBOARD.CUSTOMER_EXPENSE}today/`, {customer: params.id});
      const expense = expenseResponse.data.amount;

      setHidden(income <= expense ? 'customer_income' : 'customer_expense');
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const handleMenuSelected = (e, {name}) => setMenu(name);

  const renderCustomerDashboard = () => (
    <div>
      <Header dividing textAlign='left'>{t('dashboard.customer_statistic')}</Header>
      <Grid>
        <Grid.Row columns={width > MOBILE_WIDTH ? 4 : 1}>
          {menus.map(menu => !(menu.title === hidden) ? (
            <Grid.Column key={`widget-${menu.title}`}>
              <WidgetWithChart
                title={menu.title}
                url={menu.url}
                icon={menu.icon}
                color={menu.color}
                customer={params.id}
                currencyFormat={menu.format === 'currency'}
                weightFormat={menu.format === 'weight'}
                unit={menu.unit}
              />
            </Grid.Column>
          ) : null)}
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment basic>
              { width > SMALL_MOBILE_WIDTH && 
                <Button.Group basic>
                  <Button 
                    onClick={() => handleChangeTimeScale('day')} 
                    active={timeScale === 'day'}
                  >{t('dashboard.scale.day')}</Button>
                  <Button
                    onClick={() => handleChangeTimeScale('week')} 
                    active={timeScale === 'week'}
                  >{t('dashboard.scale.week')}</Button>
                  <Button 
                    onClick={() => handleChangeTimeScale('month')} 
                    active={timeScale === 'month'}
                  >{t('dashboard.scale.month')}</Button>
                  <Button
                    onClick={() => handleChangeTimeScale('year')} 
                    active={timeScale === 'year'}
                  >{t('dashboard.scale.year')}</Button>
                </Button.Group>              
              }
              { width <= SMALL_MOBILE_WIDTH && 
                <Dropdown
                  button
                  basic
                  options={[
                    {value: 'day', text: t('dashboard.scale.day')},
                    {value: 'week', text: t('dashboard.scale.week')},
                    {value: 'month', text: t('dashboard.scale.month')},
                    {value: 'year', text: t('dashboard.scale.year')},
                  ]}
                  defaultValue='day'
                  onChange={(e, data) => {
                    handleChangeTimeScale(data.value)
                  }}
                />
              }
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {!isMobile && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <LineWidget
                customer={params.id}
                menus={menus}
                timeScale={timeScale}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      
        {isMobile &&
          <Grid.Row columns={1}>
            {
              menus.map((menu, index) => (
                <Grid.Column style={index > 0 ? {marginTop: '1rem'} : {}}>
                  <LineGraphSegment 
                    title={t(`dashboard.${menu.title}`)}
                    titleIcon={menu.icon}
                    timeScale={timeScale}
                    customer={params.id}
                    color={menu.color}
                    url={menu.url}
                    currencyFormat={menu.format === 'currenncy'}
                    weightFormat={menu.format === 'weight'}
                    unit={menu.unit}
                  />
                </Grid.Column>
              ))                
            }            
          </Grid.Row>
        } 
      </Grid>
    </div>
  )

  useEffect(() => {
    if (!params.id) {
      history.replace(PATH.LOGIN);
    }
    compareIncomeAndExpense();
  }, []);

  const handleChangeTimeScale = (scale) => {
    setTimeScale(scale);
  }

  return (
    <Grid style={{height: "100vh", margin: 0}}>
      {width > MOBILE_WIDTH && 
        <Grid.Row style={{padding: 0}} columns={2}>
          <Grid.Column width={3} style={{padding: 0}}>
            <Menu fluid inverted vertical pointing style={{height: "100%", borderRadius: 0}}>
              <Menu.Item style={{paddingTop: 32}}>
                <Image centered src={logo}/>
              </Menu.Item>
              <Menu.Item 
                style={{fontSize: "16px", paddingTop: "16px", paddingBottom: "16px"}}
                name={"dashboard"}
                active={menu && (menu === "dashboard")}
                onClick={handleMenuSelected}>
                <Icon name={"line graph"}/>
                {t("menu.dashboard")}
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width={13}>
            <LanguageMenu position={"right"}/>
            {menu && (menu === "dashboard") && (
              renderCustomerDashboard()
            )}
          </Grid.Column>
        </Grid.Row>
      }
      {width <= MOBILE_WIDTH && width >= SMALL_MOBILE_WIDTH && 
        <Grid.Row style={{padding: 0}} columns={1}>
          <Grid.Column style={{padding: 0}}>
            <Menu inverted pointing style={{borderRadius: 0}}>
              <Menu.Item>
                <Image style={{ width: 50, height: 50 }} centered src={logoNoText}/>
              </Menu.Item>
              <Menu.Item 
                style={{fontSize: "16px", paddingTop: "16px", paddingBottom: "16px"}}
                name={"dashboard"}
                active={menu && (menu === "dashboard")}
                onClick={handleMenuSelected}>
                <Icon name={"line graph"}/>
                {t("menu.dashboard")}
              </Menu.Item>
            </Menu>

            <div style={{padding: 24, paddingTop: 0}}>
              <LanguageMenu position={"right"}/>
              {menu && (menu === "dashboard") && (
                renderCustomerDashboard()
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      }
      { width < SMALL_MOBILE_WIDTH && 
        <Grid.Row style={{padding: 0}} columns={1}>
          <Sidebar.Pushable style={{padding: 0}} as={Grid.Column}>
            <Sidebar
              as={Menu}
              animation='overlay'
              direction='left'
              icon='labeled'
              inverted
              onHide={() => setIsMenuVisible(false)}
              vertical
              visible={isMenuVisible}
              width='thin'
            >
              <Menu.Item 
                name={"dashboard"}
                active={menu && (menu === "dashboard")}
                onClick={(e, data) => {
                  handleMenuSelected(e, data);
                  setIsMenuVisible(false);
                }}>
                <Icon name={"line graph"}/>
                {t("menu.dashboard")}
              </Menu.Item>
            </Sidebar>
            <Sidebar.Pusher dimmed={isMenuVisible}>
              <Menu inverted pointing style={{borderRadius: 0}}>
                <Menu.Item>
                  <Icon size='big' name="bars" onClick={() => {setIsMenuVisible(true)}}/>
                </Menu.Item>
                <Menu.Item>
                  <Image style={{ width: 50, height: 50 }} centered src={logoNoText}/>
                </Menu.Item>
              </Menu>
              <div style={{padding: 24, paddingTop: 0}}>
                <LanguageMenu position={"right"}/>
                {menu && (menu === "dashboard") && (
                  renderCustomerDashboard()
                )}
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Grid.Row>
      }
    </Grid> 
  )
}
