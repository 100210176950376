import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Icon, List, Modal, Table, Pagination, Message } from 'semantic-ui-react';
import { GET } from '../../utils/HttpClient';
import { URL_LOG } from '../../constances/urls';
import { useExpanded, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { formatComma } from '../../utils/Util';
import moment from 'moment';
import propTypes from 'prop-types';


const PAGE_SIZE = 10;

export default function LogModal (props) {
  
  const {
    open,
    onClose,
    objectId,
    objectType
  } = props;

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [page, setPage] = useState(1);
  const [data, setData] = useState({
    links: {
      next: null,
      prev: null
    },
    count: 0,
    pages: 0,
    results: [],
  });

  const columns = useMemo(()=> [
    {
      Header: t('logs.date'),
      accessor: 'created'
    },
    {
      Header: t('logs.user'),
      accessor: 'user',
    },
    {
      Header: () => null,
      id: 'expander',
      Cell: ({row}) => (
        <Button
          color={row.isExpanded? null : 'blue'}
          {...row.getToggleRowExpandedProps()}
        >
          <Icon name={row.isExpanded? 'eye slash' : 'eye'}/>
          {row.isExpanded? t('logs.hidden') : t('logs.expand')}
        </Button>
      )
    }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable(
    {
    columns,
    data: data.results,
    },
    useExpanded
  );

  const fetchLogData = async (params) => {
    setIsLoading(true);
    try {
      const response = await GET(`${URL_LOG[objectType]}${objectId}/`, params);
      setData(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handlePaginationChange = (e, {activePage}) => {
    const params = {page: activePage, page_size: PAGE_SIZE};
    fetchLogData(params);
    setPage(activePage);
  }

  const renderLogMemo = (memo) => {
    if (memo === 'None') {
      return t('none');
    } else if (!isNaN(memo)) {
      return formatComma(memo)
    } else if (moment(memo, 'YYYY-MM-DD hh:mm:ssZ', true).isValid()) {
      const date = new Date(memo)
      return date.toLocaleString()
    } else if (memo === 'purchase' || memo === 'sale') {
      return t(`scales.${memo}`)
    } else if (memo === 'kilo_gram') {
      return (t(`units.${memo}`))
    }

    return memo;
  }

  const renderLogDetail = useCallback(({row}) => {
    let type = '';

    if (objectType === 'WEIGHT_FORM')
      type = 'weight_form';
    else if (objectType === 'WEIGHT_TRANSACTION')
      type = 'weight_transaction';  
    return (
      <div>
        <List>
          { row.original.memo.map((memo, index) => (
            <List.Item key={index}>
              <Icon name='right triangle' />
              <List.Content>
                <List.Header as='a'>{t(memo.action)} {t(memo.field_name ? `logs.${type}.${memo.field_name}` : `scales.${type}`)}</List.Header>
                {memo.memo && 
                  <List.Description>
                    {t('memo')}
                    <Icon color='blue' name='arrow right'/>
                    {t(`logs.memo.${memo.memo}`)}
                  </List.Description>
                }
                {memo.field_name && 
                  <List.Description>
                    {renderLogMemo(memo.old)}
                    <Icon color='blue' name='arrow right'/>
                    {renderLogMemo(memo.new)}
                  </List.Description>                
                }
              </List.Content>
            </List.Item>
          )) }
        </List>
      </div>
    )
  }, []);

  useEffect(() => {
    if (objectId && !objectId.startsWith('temp') && open) {
      fetchLogData()      
    }
  }, [objectId, open])

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        closeIcon
      >
        <Modal.Header>{t('log')}</Modal.Header>
        <Modal.Content>
          { errorMessages && 
            <Message negative>{errorMessages}</Message>
          }
          <Table {...getTableProps()}>
            <Table.Header>
              {headerGroups.map(headerGroup => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Table.HeaderCell {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <React.Fragment key={i}>
                    <Table.Row {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                      })}
                    </Table.Row>
                    {row.isExpanded ? ( 
                      <Table.Row>
                        <Table.Cell colSpan={visibleColumns.length}>
                          {renderLogDetail({row})}
                        </Table.Cell>
                      </Table.Row>
                    ) : null }

                  </React.Fragment>
                )
              })}
            </Table.Body>
            { data.count === 0 && !isLoading && 
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='100'> 
                    {/* <Message icon='file outline' header='ว่างเปล่า' content="ไม่พบข้อมูล"/> */}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            }
          </Table>
          <div style={{textAlign: 'center'}}>
            <Pagination
              activePage={page}
              onPageChange={handlePaginationChange}
              totalPages={data.pages}
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}

LogModal.defaultProps = {
  open: false,
  onClose: () => {},
  objectId: '',
  objectType: '',
}

LogModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  objectId: propTypes.string,
  objectType: propTypes.string,
}