import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { Button, Form, Grid } from 'semantic-ui-react';
import moment from 'moment';
import { URL_REPORT } from '../../constances/urls';
import ReportExportButton from '../common/ReportExportButton';


export default function CustomerReport() {

  const { t, i18n } = useTranslation();
  const [dateRange, setDateRange] = React.useState('');
  const [dateRangeError, setDateRangeError] = React.useState(null);
  const [reportParams, setReportParams] = React.useState({});
  const reportRef = React.useRef();

  React.useEffect(() => {
    if (Object.keys(reportParams).length > 0) {
      reportRef.current.generateReport();
    }
  }, [reportParams])

  const validateDateRange = () => {
    const [date_after, date_before] = dateRange.split(' - ');
    if (
      (moment(date_after, 'YYYY-MM-DD').isValid()) &&
      (moment(date_before, 'YYYY-MM-DD').isValid())
    ) {
      return { date_after, date_before }
    }
    return false
  }

  const handlePrintReport = () => {
    setDateRangeError(null);
    const dateValidator = validateDateRange();
    console.log(dateValidator)
    if (!dateValidator) {
      setDateRangeError('invalid date');
    }
    else {
      setReportParams({
        "date_after": dateValidator.date_after,
        "date_before": dateValidator.date_before
      })
    }
  }

  return (
    <div>
      <Form>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('scales.date')}</label>
            <Form.Field
              control={DatesRangeInput}
              autoComplete="off"
              name='dateRange'
              placeholder={t('date_range')}
              dateFormat='YYYY-MM-DD'
              value={dateRange}
              error={dateRangeError}
              onChange={(_, { name, value }) => {
                setDateRange(value)
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Grid>
        <Grid.Row textAlign='center'>
          <Grid.Column>
            <ReportExportButton
              ref={reportRef}
              url={`${URL_REPORT.CUSTOMER_REPORT}`}
              onClick={handlePrintReport}
              params={reportParams}
              size="medium"
              title={t('report.print_report')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
