import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Form, Grid, Message, Search } from 'semantic-ui-react';
import moment from 'moment';
import { URL_CUSTOMER, URL_REPORT } from '../../constances/urls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GET } from '../../utils/HttpClient';
import ReportExportButton from '../common/ReportExportButton';


const resultRender = ({ title }) => (
  <label>{title}</label>
)

export default function ProductSummary() {

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const [customer, setCustomer] = useState('');
  const [exportParams, setExportParams] = useState({});
  const reportExportRef = React.useRef()

  React.useEffect(() => {
    if (Object.keys(exportParams).length > 0) {
      reportExportRef.current.generateReport();
    }
  }, [exportParams])

  const validationSchema = Yup.object().shape({
    customer: Yup.string().required(t('required')),
    product: Yup.string().required(t('required')),
    date: Yup.date().required(t('required')).nullable(),
  })

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: defaultProductSummaryForm,
    validationSchema: validationSchema,
    onSubmit: (values) => printReport(values),
  });

  const printReport = (values) => {
    setExportParams({
      customer: customer,
      product: values.product,
      date: values.date
    })
    //window.open(`${URL_REPORT.PRODUCT_SUMMARY}?customer=${customer}&product=${values.product}&date=${values.date}`, '_blank')
  }

  const handleSearchChange = async (value) => {
    setIsLoading(true);

    try {
      const params = {
        search: value,
        page: 1,
        page_size: 100,
        is_active: true
      };

      const response = await GET(URL_CUSTOMER.CUSTOMER, params)

      setCustomerOptions(response.data.results.map(item => {
        return {
          ...item,
          title: item.name,
        }
      }))

    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const fetchProductOptionData = async () => {
    setIsLoading(true);

    try {
      const params = {
        page: 1,
        page_size: 100,
        is_active: true
      };

      const response = await GET(URL_CUSTOMER.PRODUCT, params)
      const newOptions = response.data.results.map(product => {
        return {
          key: product.id,
          value: product.id,
          text: `${product.name}`
        }
      });
      setProductOptions([
        {
          key: 'all',
          value: 'all',
          text: `${t('all')} (${response.data.count} ${t('product')})`,
        }, ...newOptions,
      ]);

    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchProductOptionData();
  }, []);

  return (
    <div>
      {errorMessages &&
        <Message negative>
          {errorMessages}
        </Message>
      }
      <Form>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>{t('scales.date')}</label>
            <Form.Field
              maxDate={moment()}
              dateFormat='YYYY-MM-DD'
              value={formik.values.date}
              error={formik.errors.date}
              onChange={(_, data) => {
                formik.setFieldValue('date', data.value)
              }}
              animation='false'
              control={DateInput}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('customer')}</label>
            <Form.Field
              name='customer'
              minCharacters={2}
              loading={isLoading}
              placeholder={`${t('search')}...`}
              value={formik.values.customer}
              error={formik.errors.customer}
              results={customerOptions}
              onResultSelect={(e, data) => {
                formik.setFieldValue('customer', data.result.name)
                setCustomer(data.result.id)
              }}
              onSearchChange={(e, data) => {
                handleSearchChange(data.value)
                if (customer) {
                  setCustomer(null);
                }
                formik.setFieldValue('customer', data.value)
              }}
              onBlur={(e, data) => {
                if (!customer) {
                  setCustomer(null);
                  formik.setFieldValue('customer', '');
                }
              }}
              resultRenderer={resultRender}
              control={Search}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('product')}</label>
            <Form.Dropdown
              fluid
              search
              selection
              options={productOptions}
              name='product'
              value={formik.values.product}
              error={formik.errors.product}
              onChange={(event, data) =>
                formik.setFieldValue('product', data.value)
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Grid>
        <Grid.Row textAlign='center'>
          <Grid.Column>
            <ReportExportButton
              ref={reportExportRef}
              url={`${URL_REPORT.PRODUCT_SUMMARY}`}
              onClick={formik.handleSubmit}
              params={exportParams}
              size="medium"
              title={t('report.print_report')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

const defaultProductSummaryForm = {
  customer: '',
  product: '',
  date: '',
}
