import React, { useState } from 'react'
import logo from '../assets/logo.png'
import {
  Button, Form, Grid, Header, Message, Segment, Image, Divider
} from 'semantic-ui-react'
import { POST } from '../utils/HttpClient';
import { URL_AUTH } from "../constances/urls";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { MOBILE_WIDTH } from "../Config";
import { Toast } from '../utils/Toast';
import { AUTH_TOKEN } from '../constances/string';


export default function LoginScreen() {
  const isMobile = (window.innerWidth <= MOBILE_WIDTH);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  // forgot password menu
  const [activeMenu, setActiveMenu] = useState('login');
  const [isRequestClicked, setIsRequestClicked] = useState(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("required")),
    password: Yup.string().required(t("required")),
  });
  const formik = useFormik({
		validateOnChange: false,
    validationSchema: validationSchema,
    initialValues: {
      username: '',
      password: '',
    },
		onSubmit: (values) => onSubmitLogin(values),
  });
  
  const requestResetPasswordForm = useFormik({
		validateOnChange: false,
    validationSchema: Yup.object().shape({
      user: Yup.string().required(t("required"))
    }),
    initialValues: {
      user: '',
    },
		onSubmit: (values) => handleSendRequestResetPassword(values),
  });

  const onSubmitLogin = async (values) => {
    setIsLoading(true);
    setErrorMessage(null);
    try{
      const response = await POST(URL_AUTH.API_TOKEN_AUTH, {...values})
      Cookies.set(AUTH_TOKEN.token_key, response.data.token, { expires: 30 })
      history.replace("/");
    } catch(error) { 
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendRequestResetPassword = async (data) => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      await POST(URL_AUTH.REQUEST_RESET_PASSWORD, data);
      Toast.success(`${t('forgot_passwords.request_done')}: ${data.user}`)
      requestResetPasswordForm.resetForm();
      setIsRequestClicked(true);
      setTimeout(() => setIsRequestClicked(false), 5000);
      handleChangeMenu('login')
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeMenu = (menu) => {
    formik.resetForm();
    requestResetPasswordForm.resetForm();
    setErrorMessage(null);
    setActiveMenu(menu);
  }

  return (
    <div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column
          style={{ 
            maxWidth: 450, 
            display: 'flex', 
            alignItems: "center" 
          }}>
          <Image src={logo} size='small' centered/>
          { activeMenu === 'login' && 
            <Segment 
              style={{width: (isMobile ? "95%" : "100%")}} >
              <Form onSubmit={formik.handleSubmit} >
                <Message
                  error
                  header={t("profiles.authentication_failed")}
                  content={errorMessage}
                  visible={errorMessage != null}
                />
                <Form.Input
                  fluid
                  name='username'
                  icon='user'
                  iconPosition='left'
                  placeholder={t("profiles.username")}
                  value={formik.values.username}
                  error={formik.errors.username}
                  onChange={formik.handleChange}
                />
                <Form.Input
                  fluid
                  name='password'
                  icon='lock'
                  iconPosition='left'
                  placeholder={t("profiles.password")}
                  type='password'
                  value={formik.values.password}
                  error={formik.errors.password}
                  onChange={formik.handleChange}
                />
                <Button
                  color='blue'
                  fluid size='large'
                  type='submit'
                  loading={isLoading}
                >
                  {t("profiles.sign_in")}
                </Button>
                <Divider hidden/>
                <a disabled={isLoading} onClick={() => handleChangeMenu('forgot-password')}>{t('forgot_password')}</a>
              </Form>
            </Segment>
          }
          { activeMenu === 'forgot-password' && 
            <Segment
              style={{width: (isMobile ? "95%" : "100%")}} >
              <Header color='grey'>{t('forgot_password')}</Header>
              <Form>
                <Message 
                  error
                  header={t('forgot_passwords.request_failed')}
                  content={errorMessage}
                  visible={errorMessage != null}
                />
                <Form.Field>
                  <Form.Input
                    name='user'
                    icon='mail'
                    iconPosition='left'
                    placeholder={t('forgot_passwords.request_email')}
                    value={requestResetPasswordForm.values.user}
                    error={requestResetPasswordForm.errors.user}
                    onChange={requestResetPasswordForm.handleChange}
                  />
                </Form.Field>
                <Button
                  color='blue'
                  fluid size='large'
                  loading={isLoading}
                  disabled={isRequestClicked}
                  onClick={requestResetPasswordForm.handleSubmit}
                >
                  {t('forgot_passwords.send_request')}
                </Button>
                <Divider hidden/>
                <a disabled={isLoading} onClick={() => handleChangeMenu('login')}>{t('back')}</a>
              </Form>
            </Segment>
          }
        </Grid.Column>
      </Grid>
    </div>
  )
}

