import React, { useEffect } from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import {Header, Modal, Button, Form} from 'semantic-ui-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';


export default function ConfirmWithNoteModal(props) {

  const {
    open,
    onClose,
    onConfirm,
    title,
    detail,
    size,
  } = props;

  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    memo: Yup.string().required(t('required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: {memo: ''},
		validationSchema: validationSchema,
		onSubmit: (values) => onConfirm(values),
  })

  useEffect(() => {
    formik.resetForm();
  }, [open]);

  return (
    <Modal
      centered={false}
      open={open}
      onClose={onClose}
      size={size}
    >
      <Modal.Header content={title}/>
      <Modal.Content>
        {detail}
        <Form>
          <Form.Field required>
            <label>{t('memo')}</label>
            <Form.TextArea
              name='memo'
              placeholder={`${t('memo')}...`}
              value={formik.values.memo}
              error={formik.errors.memo}
              onChange={formik.handleChange}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>{t("cancel")}</Button>
		 		<Button positive onClick={formik.handleSubmit}>{t("confirm")}</Button>
      </Modal.Actions>
    </Modal>
	)
}

ConfirmWithNoteModal.defaultProps = {
	title: "",
	iconTitle: null,
	detail: "",
	size: "small",
	onClose: () => {},
	onConfirm: () => {},
	onCancel: () => {},
}

ConfirmWithNoteModal.propTypes = {
	title: propTypes.string,
	detail: propTypes.oneOfType([
		propTypes.string,
		propTypes.element,
	]),
	size: propTypes.string,
	onClose: propTypes.func,
	onConfirm: propTypes.func,
	onCancel: propTypes.func,
}