import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import {Header, Modal, Button} from 'semantic-ui-react';

export default function ConfirmModal(props) {
	const { t, i18n } = useTranslation();

  return (
		<Modal 
			centered={false}
			open={props.open} 
			onClose={props.onClose}
			size={props.size}>
			<Header icon={props.iconTitle} content={props.title}/>
			<Modal.Content>{props.detail}</Modal.Content>
			<Modal.Actions>
				<Button negative onClick={props.onCancel}>{t("cancel")}</Button>
				<Button positive onClick={props.onConfirm}>{t("confirm")}</Button>
			</Modal.Actions>
		</Modal>
	)
}

ConfirmModal.defaultProps = {
	title: "",
	iconTitle: null,
	detail: "",
	size: "small",
	onClose: () => {},
	onConfirm: () => {},
	onCancel: () => {},
}

ConfirmModal.propTypes = {
	title: propTypes.string,
	detail: propTypes.oneOfType([
		propTypes.string,
		propTypes.element,
	]),
	size: propTypes.string,
	onClose: propTypes.func,
	onConfirm: propTypes.func,
	onCancel: propTypes.func,
}