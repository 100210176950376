import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Form, List, Loader, Message } from 'semantic-ui-react';
import InputModal from "../common/InputModal";
import DetailItem from "../common/DetailItem";
import Address from "../common/Address";
import ConfirmModal from "../common/ConfirmModal";
import { Toast } from "../../utils/Toast";
import { GET, PATCH } from "../../utils/HttpClient";
import { URL_COMPANY, URL_CORE } from "../../constances/urls";

export default function CompanyView(props) {
	const { t, i18n } = useTranslation();
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [detail, setDetail] = React.useState(null);
	const [address, setAddress] = React.useState(null);
	// Modal
	const [openModal, setOpenModal] = React.useState(false);
	const [titleModal, setTitleModal] = React.useState("");
	const [initialValueModal, setInitialValueModal] = React.useState("");
	// Address modal
	const [openAddressModal, setOpenAddressModal] = React.useState(false);
	const addressRef = React.useRef(null);

	const handleEditData = (name) => {
		switch (name) {
			case "name":
				setTitleModal(t("admin.company_is_name"));
				setInitialValueModal(detail ? detail.name : "");
				break;
			case "email":
				setTitleModal(t("profiles.email"));
				setInitialValueModal(detail ? detail.email : "");
				break;
			case "phone":
				setTitleModal(t("admin.phone"));
				setInitialValueModal(detail ? detail.phone : "");
				break;
			case "tax_invoice_address":
				setTitleModal(t("admin.tax_invoice_address"));
				setInitialValueModal(detail ? detail.tax_invoice_address : "");
				break;
			default: break;
		}
		setOpenModal(true);
	};

	const fetchCompanyDetail = async () => {
    setLoading(true);
    try {
			const response = await GET(URL_COMPANY.COMPANY);
			if (response.data.results.length > 0) {
				setDetail(response.data.results[0]);
			}
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setLoading(false);
    }
	};
	
	const fetchAddress = async (id) => {
		if (!id) 
			return;
		
		try {
			const response = await GET(`${URL_CORE.THAI_ADDRESS}${id}/`);
			setAddress(response.data);
		} catch (error) {
			console.log("Error: ", error);
		}
	}

	/** Update name, email, phone */
	const updateValue = async (value) => {
		if (!detail || !value) {
			return;
		}
		let params = {};
		switch (titleModal) {
			case t("admin.company_is_name"):
				params.name = value;
				break;
			case t("profiles.email"):
				params.email = value;
				break;
			case t("admin.phone"):
				params.phone = value;
				break;
			case t("admin.tax_invoice_address"):
				params.tax_invoice_address = value;
				break;
			default: break;
		}

		try {
			const response = await PATCH(`${URL_COMPANY.COMPANY}${detail.id}/`, params);
			Toast.success(t("admin.company_profile_updated_successfully"));
			setErrorMessage(null);
		} catch (error) {
			setErrorMessage(error.errorMessages);
		} finally {
			setOpenModal(false);
			fetchCompanyDetail();
		}
	};

	/** Update address */
	const handleSubmitAddress = async () => {
		const addressForm = addressRef.current.formik;
    const addressValidation = await addressForm.validateForm();
		const isAddressValid = Object.keys(addressValidation).length === 0;
		if (!isAddressValid) 
			return;
		if (!detail || !detail.address)
			return;
		
		try {
			const addressParams = addressForm.values;
			const response = await PATCH(`${URL_CORE.THAI_ADDRESS}${detail.address}/`, addressParams);
			Toast.success(t("admin.company_profile_updated_successfully"));
			setErrorMessage(null);
		} catch (error) {
			setErrorMessage(error.errorMessages);
		} finally {
			setOpenAddressModal(false);
			fetchCompanyDetail();
		}
	}

	React.useEffect(() => {
		if (detail) {
			fetchAddress(detail.address);
		}
	}, [detail]);

	React.useEffect(() => {
		fetchCompanyDetail();
	}, []);
	
  return (
		<div style={{marginTop: 24}}>
			<Dimmer active={loading}>
				<Loader />
			</Dimmer>

			{ errorMessage && (
        <Message negative>
          <Message.Header>{t("error")}</Message.Header>
          <p>{errorMessage}</p>
        </Message>
      )}

			<List 
				divided 
        verticalAlign={"middle"}
				style={{marginLeft: 65, marginRight: 65}}>

				<DetailItem 
					style={{padding: 18}}
					title={t("admin.company_is_name")}
					titleIcon={"building"}
					detail={detail ? detail.name : ""}
					onEdit={() => handleEditData("name")}/>

				<DetailItem 
					style={{padding: 18}}
					title={t("profiles.email")}
					titleIcon={"mail"}
					detail={detail ? detail.email : ""}
					onEdit={() => handleEditData("email")}/>

				<DetailItem 
					style={{padding: 18}}
					title={t("admin.phone")}
					titleIcon={"phone"}
					detail={detail ? detail.phone : ""}
					onEdit={() => handleEditData("phone")}/>
				
				<DetailItem 
					style={{padding: 18}}
					title={t("admin.address")}
					titleIcon={"map marker alternate"}
					detail={detail ? detail.address_display : ""}
					onEdit={() => setOpenAddressModal(true)}/>

				<DetailItem
					style={{padding: 18}}
					title={t("admin.tax_invoice_address")}
					titleIcon={"file alternate"}
					detail={detail ? detail.tax_invoice_address : ""}
					onEdit={() => handleEditData("tax_invoice_address")}/>				
			</List>

			<InputModal 
				open={openModal}
				title={titleModal}
				initialValue={initialValueModal}
				onClose={() => setOpenModal(false)}
				onCancel={() => setOpenModal(false)}
				onConfirm={(value) => updateValue(value)}/>

			{/* Address Modal */}
			<ConfirmModal 
				title={t("admin.address")}
				detail={
					<Form>
						<Address 
							noHeader={true}
							ref={addressRef} 
							initialValues={address}/>
					</Form>
				}
				open={openAddressModal}
				onClose={() => setOpenAddressModal(false)}
				onCancel={() => setOpenAddressModal(false)}
				onConfirm={handleSubmitAddress}/>
		</div>
	)
}

CompanyView.defaultProps = {}

CompanyView.propTypes = {}