import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Menu, Icon, Image, Sidebar } from 'semantic-ui-react';
import { MOBILE_WIDTH, SMALL_MOBILE_WIDTH } from "../Config";
import { PATH } from '../routes/Routes';
import logo from "../assets/logo.png";
import logoNoText from "../assets/logo_no_text.png";
import LanguageMenu from "../components/common/LanguageMenu";

import FormScalesView from "../views/FormScalesView";
import DashboardView from '../views/DashboardView';
import ProfileView from "../views/ProfileView";
import SettingsView from '../views/SettingsView';
import AdminView from "../views/AdminView";
import ReportView from '../views/ReportView';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useShiftStatus from '../hooks/useShiftStatus';
import ShiftModal from '../components/cashier/ShiftModal';


export default function MenuScreen() {
  const DEFAULT_PATH = PATH.DASHBOARD
  const history = useHistory();
  const params = useParams();
  const {width, height} = useWindowDimensions();
  const { t, i18n } = useTranslation();
  const [menu, setMenu] = React.useState("dashboard");
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [tabVisible, setTabVisible] = useState(false);
	// shift status
	const shiftStatus = useShiftStatus();
  const [isShiftWindowVisible, setIsShiftWindowVisible] = React.useState(null);

  const handleMenuSelected = (e, { name }) => {
    if (menu === name) { return } // Ignore same path
    const targetMenu = menus.find(m => m.name === name)
    targetMenu ? history.push(targetMenu.path) : history.push(DEFAULT_PATH)
  };

  const menus = [
    {
      name: "dashboard",
      icon: "line graph",
      description: t("menu.dashboard"),
      path: PATH.DASHBOARD,
      mobile: true,
    },
    {
      name: "scales",
      icon: "balance scale",
      description: t("menu.scales"),
      path: PATH.SCALE
    },
    {
      name: "settings",
      icon: "settings",
      description: t("menu.settings"),
      path: PATH.SETTINGS
    },
    {
      name: "admin",
      icon: "users",
      description: t("menu.admin"),
      path: PATH.ADMIN
    },
    {
      name: "report",
      icon: "file alternate",
      description: t("menu.report"),
      path: PATH.REPORTS
    },
    {
      name: "profile",
      icon: "address card outline",
      description: t("menu.profile"),
      path: PATH.PROFILE
    },
  ]

  
  useEffect(() => {
    window.addEventListener('focus', function () {
      setTabVisible(true)
    }, false);
    window.addEventListener('blur', function () {
      setTabVisible(false)
    }, false);

    return () => {
      window.removeEventListener('focus', function () {
        setTabVisible(true)
      }, false);
      window.removeEventListener('blur', function () {
        setTabVisible(false)
      }, false);  
    }
  }, [])

  React.useEffect(() => {
    if (params.menu) {
      const targetActive = menus.find(menu => menu.path && menu.path.replaceAll('/', '') === params.menu)
      if (targetActive) {
        setMenu(targetActive.name)
      }
    } else {
      history.replace(DEFAULT_PATH)  // Set Default to DASHBOARD
    }
  }, [params])


  return (
    <Grid style={{ height: "100vh", margin: 0 }}>
      <ShiftModal
        open={isShiftWindowVisible != null}
        shiftData={shiftStatus.data}
        shiftType={isShiftWindowVisible}
        onClose={() => {
          setIsShiftWindowVisible(null);
          shiftStatus.syncStatus();
        }} />
      {width > MOBILE_WIDTH &&
        <Grid.Row style={{ padding: 0 }} columns={2}>
          <Grid.Column width={3} style={{ padding: 0 }}>
            <Menu fluid inverted vertical pointing style={{ height: "100%", borderRadius: 0 }}>
              <Menu.Item style={{ paddingTop: 32 }}>
                <Image centered src={logo} size="small" />
              </Menu.Item>
              {menus.map(m => (
                <Menu.Item
                  style={{ fontSize: "16px", paddingTop: "16px", paddingBottom: "16px" }}
                  name={m.name}
                  active={menu && (menu === m.name)}
                  onClick={handleMenuSelected}>
                  <Icon name={m.icon} />
                  {m.description}
                </Menu.Item>
              ))}
              {shiftStatus.data.is_enabled &&
                <Menu.Item
                  style={{ fontSize: "16px", paddingTop: "16px", paddingBottom: "16px" }}
                  onClick={() => setIsShiftWindowVisible(shiftStatus.data.is_ready ? "shift_out" : "shift_in")}>
                  <Icon name={shiftStatus.data.is_ready ? "sign-out alternate" : "sign-in alternate"} />
                  {shiftStatus.data.is_ready ? t("shifts.shift_out") : t("shifts.shift_in")}
                </Menu.Item>
              }
            </Menu>
          </Grid.Column>
          <Grid.Column width={13}>
            <LanguageMenu position={"right"} />
            {menu && (menu === "dashboard") && (
              <DashboardView />
            )}
            {menu && (menu === "scales") && (
              <FormScalesView shiftReady={shiftStatus.data.is_ready} />
            )}
            {menu && (menu === "settings") && (
              <SettingsView />
            )}
            {menu && (menu === "admin") && (
              <AdminView />
            )}
            {menu && (menu === "report") && (
              <ReportView />
            )}
            {menu && (menu === "profile") && (
              <ProfileView shiftReady={shiftStatus.data.is_enabled ? shiftStatus.data.is_ready : null} />
            )}
          </Grid.Column>
        </Grid.Row>
      }
      {width <= MOBILE_WIDTH && width >= SMALL_MOBILE_WIDTH &&
        <Grid.Row style={{ padding: 0 }} columns={1}>
          <Grid.Column style={{ padding: 0 }}>
            <Menu inverted pointing style={{ borderRadius: 0 }}>
              <Menu.Item>
                <Image style={{ width: 50, height: 50 }} centered src={logoNoText} />
              </Menu.Item>
              <Menu.Item
                name={"dashboard"}
                active={menu && (menu === "dashboard")}
                onClick={handleMenuSelected}>
                <Icon name={"line graph"} />
                {t("menu.dashboard")}
              </Menu.Item>
              <Menu.Item
                name={"report"}
                active={menu && (menu === "report")}
                onClick={handleMenuSelected}>
                <Icon name={"file alternate"} />
                {t("menu.report")}
              </Menu.Item>
              <Menu.Item
                name={"profile"}
                active={menu && (menu === "profile")}
                onClick={handleMenuSelected}>
                <Icon name={"address card outline"} />
                {t("menu.profile")}
              </Menu.Item>
            </Menu>

            <div style={{ padding: 24, paddingTop: 0 }}>
              <LanguageMenu position={"right"} />
              {menu && (menu === "dashboard") && (
                <DashboardView />
              )}
              {menu && (menu === "profile") && (
                <ProfileView />
              )}
              {menu && (menu === "report") && (
                <ReportView />
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      }
      {width < SMALL_MOBILE_WIDTH &&
        <Grid.Row style={{ padding: 0 }} columns={1}>
          <Sidebar.Pushable style={{ padding: 0 }} as={Grid.Column}>
            <Sidebar
              as={Menu}
              animation='overlay'
              direction='left'
              icon='labeled'
              inverted
              onHide={() => setIsMenuVisible(false)}
              vertical
              visible={isMenuVisible}
              width='thin'
            >
              <Menu.Item
                name={"dashboard"}
                active={menu && (menu === "dashboard")}
                onClick={(e, data) => {
                  handleMenuSelected(e, data);
                  setIsMenuVisible(false);
                }}>
                <Icon name={"line graph"} />
                {t("menu.dashboard")}
              </Menu.Item>
              <Menu.Item
                name={"report"}
                active={menu && (menu === "report")}
                onClick={(e, data) => {
                  handleMenuSelected(e, data);
                  setIsMenuVisible(false);
                }}>
                <Icon name={"file alternate"} />
                {t("menu.report")}
              </Menu.Item>
              <Menu.Item
                name={"profile"}
                active={menu && (menu === "profile")}
                onClick={(e, data) => {
                  handleMenuSelected(e, data);
                  setIsMenuVisible(false);
                }}>
                <Icon name={"address card outline"} />
                {t("menu.profile")}
              </Menu.Item>
            </Sidebar>
            <Sidebar.Pusher dimmed={isMenuVisible}>
              <Menu inverted pointing style={{ borderRadius: 0 }}>
                <Menu.Item>
                  <Icon size='big' name="bars" onClick={() => { setIsMenuVisible(true) }} />
                </Menu.Item>
                <Menu.Item>
                  <Image style={{ width: 50, height: 50 }} centered src={logoNoText} />
                </Menu.Item>
              </Menu>
              <div style={{ padding: 24, paddingTop: 0 }}>
                <LanguageMenu position={"right"} />
                {menu && (menu === "dashboard") && (
                  <DashboardView />
                )}
                {menu && (menu === "profile") && (
                  <ProfileView />
                )}
                {menu && (menu === "report") && (
                  <ReportView />
                )}
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Grid.Row>
      }
    </Grid>
  )
}
