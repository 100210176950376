export function setupServiceWorkerStatusTransfer () {
  let callBack;
  let status;

  const send = (s) => {
    status = s;
    if (callBack && status) {
      callBack(status);
    }
  }

  const listen = (cb) => {
    callBack = cb;
    send(status);
  }

  return {
    listen,
    onUpdate: sendStatus(send, 'messages.update_available'),
  }
}

function sendStatus (send, status) {
  return () => send(status);
}
