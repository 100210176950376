import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import CompanyShiftSummary from '../components/report/CompanyShiftSummary';
import DeletedTransaction from '../components/report/DeletedTransaction';
import ProductSummary from '../components/report/ProductSummary';
import CustomerReport from '../components/report/CustomerReport';
import DateSummaryReport from '../components/report/DateSummaryReport';
import ProductReport from '../components/report/ProductReport';


export default function ReportView() {

  const { t, i18n } = useTranslation();

  const [activeItem, setActiveItem] = useState('product_summary');
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const generateGrid = (menu) => (
    <Grid.Column key={menu}>
      <Button
        fluid
        size='big'
        color='blue'
        basic
        icon='file'
        content={t(`report.${menu}`)}
        onClick={() => {
          setIsMenuVisible(true);
          setActiveItem(menu)
        }}
      />
    </Grid.Column>
  )
  

  return (
    <div>
			<Header dividing style={{textAlign: 'left', padding: '8px'}}>
        {t('menu.report')}
      </Header>
      <Grid stackable columns={3}>
        <Grid.Row>
          {generateGrid('product_summary')}
          {generateGrid('company_shift')}
        </Grid.Row>
        <Grid.Row>
          {generateGrid('customer_report')}
          {generateGrid('date_report')}
          {generateGrid('product_report')}
        </Grid.Row>
      </Grid>
      <Modal
        open={isMenuVisible}
        onClose={() => {
          setIsMenuVisible(false);
        }}
        closeIcon
        centered={false}
      >
        <Modal.Header>{t(`report.${activeItem}`)}</Modal.Header>
        <Modal.Content>
          { activeItem === 'product_summary' && 
            <ProductSummary />
          }
          { activeItem === 'company_shift' && 
            <CompanyShiftSummary />
          }
          {activeItem === 'customer_report' &&
            <CustomerReport />
          }
          {activeItem === 'date_report' &&
            <DateSummaryReport />
          }
          {activeItem === 'product_report' &&
            <ProductReport />
          }
        </Modal.Content>
      </Modal>
      <DeletedTransaction />
   </div>
  );
}