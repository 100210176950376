import React, { createRef, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import propTypes from 'prop-types';
import ListTable from '../common/ListTable';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { GET } from '../../utils/HttpClient';
import { URL_WEIGHT } from '../../constances/urls';
import { formatComma } from '../../utils/Util';


export default function HistoryTransactionModal (props) {

  const {
    open,
    onClose,
    vehicleRef,
    setWeightFromSelectTransaction,
  } = props;

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [vehicle, setVehicle] = useState({
    id: '',
    license_plate_number: null,
    license_plate_province_name: null,
  });
  const columns = useMemo(() => [
    {
      Header: t('scales.weigh_in'),
      accessor: 'in_date',
    },
    {
      Header: t('product'),
      accessor: 'product_name',
    },
    {
      Header: t('scales.weigh'),
      accessor: 'in_weight',
      Cell: ({row}) => `${formatComma(row.original.in_weight)} ${t('units.kilo_gram')}`
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}} >
          <Button
            onClick={() => props.setWeightFromSelectTransaction(formatComma(row.original.in_weight))}
            content={t('select')}
            color='blue'
            icon='weight'
            basic
          />
        </div>
      )
    }
  ],[]);
  const [data, setData] = useState({
    count: 0,
    pages: 0,
    links: {
      next: null,
      previous: null,  
    },
    results: [],
  })
  const tableRef = createRef();
  
  const getTransactionList = async (params) => {
    if (!params) {
      params = tableRef.current.getParams();
    }

    setIsLoading(true);

    params.vehicle = vehicleRef.current.formik.values.id;
    params.page_size = 5;

    try {
      const response = await GET(`${URL_WEIGHT.TRANSACTION}`, params);
      setData(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      if (vehicleRef.current){
        setVehicle(vehicleRef.current.formik.values);
        getTransactionList();
      }
    }
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Modal.Header>
          {`[${t('scales.transaction_history')}] ${t('vehicles.license_plate')}:
            ${vehicle.license_plate_number}
            ${vehicle.license_plate_province_name
            ? ` - ${vehicle.license_plate_province_name}`
            : ''}`
          }
        </Modal.Header>
        <Modal.Content>
          <ListTable
            ref={tableRef}
            columns={columns}
            data={data}
            loading={isLoading}
            error={errorMessages}
            refetch={getTransactionList}
            showPagination
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

// vehicleRef.formik.values.license_plate_number

HistoryTransactionModal.defaultProps = {
  open: false,
  onClose: () => {},
  vehicleRef: {
    current: {
      formik: {
        values: {},
      }
    }
  },
  setWeightFromSelectTransaction: () => {},
};

HistoryTransactionModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  vehicleRef: propTypes.shape({
    current: propTypes.shape({
      formik: propTypes.shape({
        values: propTypes.object,
      })
    })
  }),
  setWeightFromSelectTransaction: propTypes.func,
};