import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import {Header, Modal, Button, Grid, Input, Icon, Checkbox, Message, Dimmer, Loader, Label, Image} from 'semantic-ui-react';
import { GET, POST } from "../../utils/HttpClient";
import { URL_WEIGHT, URL_WALLET, URL_COMPANY } from "../../constances/urls";
import { formatComma } from "../../utils/Util";
import { SERVER } from "../../Config";
import Cookies from "js-cookie";
import PromptpayIcon from '../../assets/promptpay_icon.png';
import PromptPayModal from './PromptPayModal';

const DECIMAL_PATTERN = /^\d+(\.\d)?\d*$/;

export default function CashierModal(props) {
	const { t, i18n } = useTranslation();
	const [promptPayOpen, setPromptPayOpen] = React.useState(false);
	const [promptPayAccount, setPromptPayAccount] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [total, setTotal] = React.useState(0);
	const [walletAmount, setWalletAmount] = React.useState(0);
	const [useWallet, setUseWallet] = React.useState(false);
	const [payWithWallet, setPayWithWallet] = React.useState(0);
	const [notEnough, setNotEnough] = React.useState(0);
	const [inputValue, setInputValue] = React.useState("");

	const [useCredit, setUseCredit] = React.useState(false);
	const [payWithCredit, setPayWithCredit] = React.useState(0);
	// Change Modal
	const [openChange, setOpenChange] = React.useState(false);
	const [change, setChange] = React.useState(0);

	const handleInputChange = (e, data) => {
		let myValue = data.value.replaceAll(",", "");
		const values = myValue.split('.');

		if (myValue === "") {
			setInputValue("");
		}
		else if(values.length <= 2) {
			if (myValue.endsWith(".")) {
				setInputValue(myValue);
			}
			else {
				setInputValue(
					values.length === 2 ? `${formatComma(values[0])}.${values[1]}` : formatComma(values[0])
				);
			}
		}
	};

	const readyForPayment = () => {
		const value = inputValue.replaceAll(",", "");

		let ready = true;
		if (useCredit)
			return true;
		
		if (useWallet) {
			if (notEnough !== 0) {
				if ((value === "") || (parseFloat(value) < notEnough))
					ready = false;
			}
		} else {
			if (!DECIMAL_PATTERN.test(value)) {
				// Wrong cash input format
				return false;
			}
			else if ((value === "") || (parseFloat(value) < total)) {
				ready = false;
			}
		}
		
		return ready;
	};

	const handlePayment = async () => {
		if (!props.form) {
			return;
		}

		const value = inputValue.replaceAll(",", "");
		let cashPayAmount = 0;
		let myChange = 0;
		
		// Credit
		if (useCredit) {
			let walletPayAmount = payWithWallet;
			let creditPayAmount = payWithCredit;
			const walletPaid = await paymentWithWallet(walletPayAmount + creditPayAmount);
			if (!walletPaid) {
				return;
			}
		} else {
			if (useWallet) {
				let walletPayAmount = total;
				if (notEnough !== 0) {
					if ((value !== "") && (parseFloat(value) >= notEnough)) {
						walletPayAmount = walletAmount;
						cashPayAmount = notEnough;
						myChange = parseFloat(value) - notEnough;
					} else { // Error: Cannot paid
						return;
					}
				}
				// Payment (Wallet)
				const walletPaid = await paymentWithWallet(walletPayAmount);
				if (!walletPaid) { // Error : fail to payment
					return;
				}
			} else {
				handleOpenCashier();
				cashPayAmount = total;
				if ((value !== "") && (parseFloat(value) >= total)) {
					myChange = parseFloat(value) - total;
				} else { // Error: Cannot paid
					return;
				}
			}
		}

		// Payment (Cash)
		if (cashPayAmount > 0) {
			const cashPaid = await paymentWithCash(cashPayAmount);
			if (!cashPaid) {
				// Error : fail to payment
				return;
			}
		}
		setChange(myChange);
		setOpenChange(true);
	};

	const paymentWithCash = async (amount) => {
		return await payment("cash", amount);
	};

	const paymentWithWallet = async (amount) => {
		return await payment("wallet", amount);
	};

	const paymentWithTransfer = async (amount) => {
		return await payment("transfer", amount);
	};

	const payment = async (type, amount) => {
		if (!props.form) {
			return false;
		}

		let isSuccess = true;
		setLoading(true);
		const form = props.form
		let data = {
			form: form.id,
			amount: amount,
			payment_type: type
		}
		try {
			const response = await POST(`${URL_WEIGHT.FORM_PAYMENT}${form.id}/`, data);
			if (errorMessage)
				setErrorMessage(null);
		} catch(error) {
			setErrorMessage(error.errorMessages);
			isSuccess = false;
		} finally {
			setLoading(false);
		}
		
		return isSuccess;
	};

	const getWallet = async () => {
		if (!props.form 
				|| !props.form.customer 
				|| !props.form.customer.id) {
			return false;
		}

		const customerId = props.form.customer.id;
		try {
			const response = await GET(`${URL_WALLET.DETAIL}${customerId}/`);
			let amount = response.data.current_balance;
			setWalletAmount(parseFloat(amount.replaceAll(",", "")));
		} catch(error) {
			setErrorMessage(error.errorMessages);
		}
	};

	const handleOpenCashier = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const serverIP = Cookies.get("server");
			const serverPort = Cookies.get("server_port");
      const response = await GET(
				`http://${serverIP ? serverIP : SERVER.DEFAULT_IP}:${serverPort ? serverPort : SERVER.DEFAULT_PORT}${URL_COMPANY.OPEN_DRAWER}`
			);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {      
      setLoading(false);
    }
  }

	React.useEffect(() => {
		setUseWallet(false);
		if (useCredit) {
			setInputValue("");
			if (walletAmount >= total) {
				setPayWithWallet(total);
			} else {
				if (walletAmount >= 0) {
					setPayWithWallet(walletAmount);
					setPayWithCredit(total - walletAmount);
				} else {
					setPayWithWallet(0);
					setPayWithCredit(total);
				}
			}
		} else {
			setPayWithCredit(0);
			setPayWithWallet(0);
		}
	}, [useCredit]);

	React.useEffect(() => {
		if (useWallet) {
			if (walletAmount >= total) 
				setPayWithWallet(total);
			else {
				setPayWithWallet(walletAmount);
				setNotEnough(total - walletAmount);
			}
		} else {
			if (!useCredit)
				setPayWithWallet(0);
			setNotEnough(0);
		}
	}, [useWallet]);

	React.useEffect(() => {
		if (props.form) {
			const form = props.form
			getWallet();
			setTotal(form.net_price);
			if (form.prompt_pay_account)
				setPromptPayAccount(form.prompt_pay_account);
		}
	}, [props.form]);

	React.useEffect(() => {
		if (!props.open) {
			// Clear data
			setErrorMessage(null);
			setUseWallet(false);
			setPayWithWallet(0);
			setNotEnough(0);
			setOpenChange(false);
			setChange(0);
			setInputValue("");
			setUseCredit(false);
			setPayWithCredit(0);
			setPromptPayAccount(null);
		} 
		else {console.log(props.form)}
	}, [props.open]);

  return (
		<>
		{ promptPayAccount && 
			<PromptPayModal 
				open={promptPayOpen}
				promptPayAccount={promptPayAccount}
				amount={total}
				onClose={() => setPromptPayOpen(false)}
				onPaid={async (amount) => {
					setPromptPayOpen(false)
					await paymentWithTransfer(amount);
					props.onPaid()
				}} />
		}
		<Modal
			open={props.open} 
			onClose={props.onClose}
			size={props.size}
			centered={true}>
			{ props.form && 
				<Modal.Header>
					{`${t('cashier')} `}
					<Label
						size='big'
						basic
						color={props.form.type === 'sale' ? 'green' : 'red'}
					>
						{props.form.type && t(`scales.${props.form.type}`)}
					</Label>
				{ props.form.prompt_pay_account && (props.form.type === 'sale') && 
					<Image 
						floated='right' 
						src={PromptpayIcon} 
						size='tiny' 
						as='a' 
						style={{cursor: 'pointer'}}
						onClick={() => setPromptPayOpen(true)}
					/> 
				}
				</Modal.Header>		
			}
			<Modal.Content>
				<Dimmer active={loading} inverted>
					<Loader inverted content={t("loading")}/>
				</Dimmer>
				{ errorMessage && 
					<Message negative style={{textAlign: "left", marginLeft: 24, marginRight: 24}}>
						<Message.Header>{t("error")}</Message.Header>
						<p>{errorMessage}</p>
					</Message> 
				}
				<Grid>
					<Grid.Column width={4} verticalAlign={"middle"}>
						<label style={{fontSize: 16}}>{t("cashiers.amount")}</label>
					</Grid.Column>
					<Grid.Column width={12}>
						<Header block style={{fontSize: 48, textAlign: "right"}}>
							{formatComma(total)}
						</Header>
					</Grid.Column>
				</Grid>

				{/* <Divider horizontal>{t("cashiers.payment")}</Divider> */}
				{ props.form && props.form.type === "sale" && 
					<Grid style={{marginTop: 16}}>
						<Grid.Row>
							<Grid.Column width={4} verticalAlign={"middle"}>
								<Checkbox 
									label={t("cashiers.credit")}
									style={{fontSize: 16}}
									onChange={(_, {checked}) => setUseCredit(checked)}/>
							</Grid.Column>
							<Grid.Column width={12}>
								<Header block style={{fontSize: 32, textAlign: "right"}}>
									{formatComma(payWithCredit)}
								</Header>
							</Grid.Column>
						</Grid.Row>

						<Grid.Column width={16} verticalAlign={"middle"} textAlign={"right"}>
							<label 
								style={{
									fontSize: 22, 
									color: (walletAmount >= 0) ? "black" : "red",
									fontWeight: "bold"}}>
							{walletAmount >= 0 ? t("cashiers.deposit_balance_is") : t("cashiers.debt_balance_is")}
							</label>
							<label 
								style={{
									marginLeft: 24, 
									fontSize: 22, 
									color: (walletAmount >= 0) ? "black" : "red",
									fontWeight: "bold"}}>
								{formatComma(walletAmount)} {t("units.baht")}
							</label>
						</Grid.Column>

						<Grid.Row>
							<Grid.Column width={4} verticalAlign={"middle"}>
								<Checkbox 
									label={t("cashiers.auto_payment_from_account")}
									style={{fontSize: 16}}
									disabled={useCredit || (walletAmount <= 0)}
									checked={useWallet || useCredit}
									onChange={(_, {checked}) => setUseWallet(checked)}/>
							</Grid.Column>
							<Grid.Column width={12}>
								<Header block style={{fontSize: 32, textAlign: "right"}} disabled={useCredit}>
									{formatComma(payWithWallet)}
								</Header>
							</Grid.Column>
						</Grid.Row>

						{(notEnough > 0) && 
							<Grid.Column width={16}>
								<Message 
									fluid
									style={{fontSize: 16}}
									color={"yellow"} >
									*{t("cashiers.have_to_pay_the_difference_in_cash")} {formatComma(notEnough)} {t("units.baht")}
								</Message>
							</Grid.Column>
						}
					</Grid>
				}

				<Grid>
					<Grid.Row>
						<Grid.Column width={4} verticalAlign={"middle"}>
						<label 
							style={{
								fontSize: 16, 
								color: (useCredit 
									|| (useWallet && (notEnough === 0)) 
									|| openChange) ? "#999" : "#000"}}>
								{ props.form && props.form.type === 'sale' ?
									t("cashiers.received") :
									t("cashiers.paid")
								}
						</label>
						</Grid.Column>
						<Grid.Column width={12}>
							<Input 
								fluid
								className={"right"}
								style={{fontSize: 32}}
								type={"text"}
								//pattern={MONEY_PATTERN}
								disabled={useCredit || (useWallet && (notEnough === 0)) || openChange} 
								value={inputValue} 
								onChange={handleInputChange}
								onKeyPress={(e) => {
									if (e.key === "Enter") { handlePayment(); }
									}}/>
						</Grid.Column>
					</Grid.Row>

					{openChange && (
						<Grid.Row>
							<Grid.Column width={4} verticalAlign={"middle"}>
								<label style={{fontSize: 16}}>{t("cashiers.change")}</label>
							</Grid.Column>
							<Grid.Column width={12}>
								<Header 
									block 
									color={"green"}
									style={{
										fontSize: 48, 
										textAlign: "right", 
										background: "#EAFAF1", 
										borderColor: "#ABEBC6"
									}}>
									{formatComma(change)}
								</Header>
							</Grid.Column>
						</Grid.Row>
					)}
				</Grid>

			</Modal.Content>
			<Modal.Actions>
				{!openChange && (
					<Button 
						primary 
						disabled={!(readyForPayment())}
						onClick={() => handlePayment()}>
						<Icon name={"money"}/>
						{t("paid")}
					</Button>
				)}
				{!openChange && (
					<Button 
						color={"grey"} 
						onClick={props.onClose}>
						<Icon name={"cancel"}/>
						{t("cancel")}
					</Button>
				)}

				{openChange && (
					<Button 
						color={"green"} 
						onClick={props.onPaid}>
						<Icon name={"cancel"}/>
						{t("close")}
					</Button>
				)}
			</Modal.Actions>
				
		</Modal>
	</>
	)
}

CashierModal.defaultProps = {
	form: null,
	size: "small",
	open: false,
	onClose: () => {},
	onPaid: () => {},
}

CashierModal.propTypes = {
	form: propTypes.object,
	size: propTypes.string,
	open: propTypes.bool,
	onClose: propTypes.func,
	onPaid: propTypes.func,
}