import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Form, Search, Dropdown } from 'semantic-ui-react';
import { GET } from "../../utils/HttpClient";
import { URL_CUSTOMER, URL_CORE } from "../../constances/urls";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from '../../utils/Toast';

const resultRenderer = ({license_plate_number, license_plate_province_name}) => {
	return (<label>{`${license_plate_number} ${license_plate_province_name ? license_plate_province_name : ''}`}</label>);
};

const vehicleType = (t) => {
	return [
		{key: 1, text: `${t("vehicle_type.truck")}`, value: "truck" },
		{key: 2, text: `${t("vehicle_type.pickup")}`, value: "pickup"},
		{key: 4, text: `${t("vehicle_type.other")}`, value: "other"},
	]
};

const validationSchema = Yup.object().shape({
	license_plate_number: Yup.string().required('กรุณาใส่ทะเบียนรถ'),
	type: Yup.string().required("กรุณาใส่ประเภทรถ"),
});

const VehicleCard = React.forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();
	const [model, setModel] = React.useState(defaultInitialModel)
	const [selected, setSelected] = React.useState(null);
	// For selected
	const [provinceOptions, setProvinceOptions] = React.useState([]);
	// For search
	const [loading, setLoading] = React.useState(false);
	const [dataList, setDataList] = React.useState([]);

	const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: model,
		validationSchema: validationSchema,
	})
	
	React.useImperativeHandle(ref, () => ({
		formik,
		initial: (data) => {
			if (data) {
				setModel({
					...data,
					title: `${data.license_plate_number}`,
					description: "",
				});
				setSelected(data);
			} else {
				setModel(defaultInitialModel);
				setSelected(null);
			}
			
		}
	}));

	const handleSearchChange = async (data) => {
		if (data.value.length < 2)
			return;
		
		setLoading(true);
		let params = {
			license_plate: data.value,
			page: 1,
			page_size: 100,
			is_active: true,
		}
		try {
			const response = await GET(URL_CUSTOMER.VEHICLE, params);
			let data = [];
			for (let item of response.data.results) {
				data.push({
					...item,
					title: `${item.license_plate_number}`,
					description: "",
				});
			}
			setDataList(data);

		} catch(error) {
			Toast.error(error.errorMessages)
		} finally {
			setLoading(false);
		}
	};

	const handleSelectVehicle = async (vehicle) => {
		setLoading(true);

		try {
			const response = await GET(`${URL_CUSTOMER.VEHICLE}${vehicle.id}/available`)
			if (response.data.result === true) {
				setSelected(vehicle)
				setModel(vehicle)
			} else {
				Toast.error(t('scales.error_vehicle_is_scaling'));
				formik.setFieldValue('license_plate_number', '');
				setSelected(null);
				setModel(defaultInitialModel)
			}

		} catch (error) {
			Toast.error(`${t('scales.error_cannot_select_vehicle')}: ${error.errorMessages}`)
			formik.setFieldValue('license_plate_number', '');
			setSelected(null);
			setModel(defaultInitialModel)
		} finally {
			setLoading(false);
		}
	}

  React.useEffect(() =>{
		const getProvince = async () => {
			const lang = i18n.language;
      try {
        const response = await GET(URL_CORE.PROVINCE);
				let data = [{key: null, text: '-', value: null}];
				for (let item of response.data) {
					data.push({
						...item,
						key: item.id,
						text: (lang === "th") ? item.name_th : item.name_en,
						value: item.id,
					})
				}
				setProvinceOptions(data);
      } catch(error) {
				Toast.error(error.errorMessages)
      }
    }
    getProvince()
	}, []);
	
	React.useEffect(() => {
		props.onChange(selected);
	}, [selected])

  return (
		<div style={{width: "100%", textAlign: "left"}}>
			<Form loading={loading}>
				<Form.Group>
					<Form.Field
						width={7}
						required
						name={"license_plate_number"}
						label={t("scales.license_plate")}
						placeholder={`${t("search")}...`}
						loading={loading}
						minCharacters={2}
						results={dataList}
						resultRenderer={resultRenderer}
						onSearchChange={(_, data) => {
							handleSearchChange(data);
							if(selected) {
								setSelected(null);
								setModel(defaultInitialModel)
							}
							formik.setFieldValue('license_plate_number', data.value);
						}}
						onResultSelect={(_, data) => handleSelectVehicle(data.result)}
						value={formik.values.license_plate_number}
						error={formik.errors.license_plate_number}
						control={Search}/>

					<Form.Field 
						search 
						selection
						width={4}
						name={"license_plate_province"}
						label={t("province")}
						placeholder={`${t("select")}...`}
						options={provinceOptions}
						onChange={(_, data) => {
							if(selected) {
								setSelected(null);
							}
							formik.setFieldValue('license_plate_province', data.value ? data.value : '');
						}}
						value={formik.values.license_plate_province}
						error={formik.errors.license_plate_province}
						control={Dropdown}/>

					<Form.Field 
						required 
						search 
						selection
						width={5}
						name={"type"}
						label={t("scales.vehicle_type")}
						placeholder={`${t("select")}...`}
						options={vehicleType(t)}
						onChange={(_, data) => {
							if(selected) {
								setSelected(null);
							}
							formik.setFieldValue("type", data.value);
						}}
						value={formik.values.type}
						error={formik.errors.type}
						control={Dropdown}/>
				</Form.Group>
			</Form>
		</div>
	)
});

export const defaultInitialModel = {
	id: null,
	type: "",
	license_plate_number: "",
	license_plate_province: null,
};

VehicleCard.defaultProps = {
	style: null,
	onChange: (data) => {},
}

VehicleCard.propTypes = {
	style: propTypes.object,
	onChange: propTypes.func,
}

export default React.memo(VehicleCard);