import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { setupServiceWorkerStatusTransfer } from './utils/setupServiceWorkerStatusTransfer';
import { LiffProvider } from 'react-liff-v2';
import { PATH } from './routes/Routes';


const { listen, onUpdate } = setupServiceWorkerStatusTransfer();

function getLiffId() {
  const paths = window.location.href.split('/');
  const path = paths[paths.indexOf('liff') + 1];

  if (path === PATH.LIFF_DASHBOARD) {
    return '1657070072-ZKdlxmo7';
  }
  else if(path === PATH.LIFF_SCALES) {
    return '1657070072-yoqbBO1N';
  }
  else if(path === PATH.LIFF_TICKET) {
    return '1657070072-71jLw428';
  }
  else {
    return '1657070072-ZKdlxmo7';  // Default path
  }
}

ReactDOM.render(
  <React.StrictMode>
    <LiffProvider liffId={getLiffId()} 
    // stubEnabled={process.env.NODE_ENV !== 'production'}
    >
      <App listen={listen}/>
    </LiffProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate,
});