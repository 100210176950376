import React from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { Container, Form, Header, Input, Segment } from "semantic-ui-react";
import { URL_WEIGHT } from '../constances/urls';
import { GET } from '../utils/HttpClient';
import { dateTimeToString, formatComma } from '../utils/Util';

export default function LiffTicketScreen () {
	const params = useParams();
	const { t, i18n } = useTranslation();
	const [data, setData] = React.useState(null);
	const [errorMessages, setErrorMessages] = React.useState(null);

	const fetchData = async () => {
		if (errorMessages)
				setErrorMessages(null);

		try {
			const response = await GET(`${URL_WEIGHT.CUSTOMER_TICKET_DETAIL}${params.id}/`);
			setData(response.data);
		} catch(error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (params.id) {
			fetchData()
		}
	}, [])

	return (
		<Container>
			<Header dividing textAlign='left' style={{marginTop: 24}}>
					{t('scales.ticket')}
			</Header>

			<Segment textAlign='left'>
				<Header size={"small"}>{data ? t(`scales.${data.type}`) : "ไม่ระบุ"}</Header>
				<Form textAlign={"left"}>
					<Form.Field 
						label={t('scales.net_weight')}
						control={Input}
						value={data ? formatComma(data.net_weight) : ""}/>
					<Form.Field 
						label={t('scales.net_price')}
						control={Input}
						value={data ? formatComma(data.net_price) : ""}/>
				</Form>
			</Segment>

			{data && data.transactions && data.transactions.map((transaction, id) => {
				return (
					<Segment textAlign='left' key={`transaction-${id}`}>
						<Header size={"small"}>{`${t('scales.summary')} - ${id + 1}`}</Header>
						<Form textAlign={"left"}>
							<label style={{fontWeight: "bold"}}>{t('scales.weigh_in')}</label>
							<Form.Field 
								label={"วันที่ - เวลา"}
								control={Input}
								value={transaction ? dateTimeToString(transaction.in_date) : ""}/>
							<Form.Field 
								label={t('scales.weigh')}
								control={Input}
								value={transaction ? transaction.in_weight : ""}/>

							<label style={{fontWeight: "bold"}}>{t('scales.weigh_out')}</label>
							<Form.Field 
								label={"วันที่ - เวลา"}
								control={Input}
								value={data ? dateTimeToString(transaction.out_date) : ""}/>
							<Form.Field 
								label={t('scales.weigh')}
								control={Input}
								value={transaction ? transaction.out_weight : ""}/>
							
							<label style={{fontWeight: "bold"}}>{t('product')}</label>
							<Form.Field 
								label={t('products.name')}
								control={Input}
								value={transaction.product ? transaction.product.name : ""}/>
							<Form.Field 
								label={t('products.price_per_unit')}
								control={Input}
								value={transaction.product ? transaction.product.price_per_unit : ""}/>
							<Form.Field 
								label={t('products.unit')}
								control={Input}
								value={transaction.product ? t(`units.${transaction.product.unit}`) : ""}/>
							
							<label style={{fontWeight: "bold"}}>{t('vehicle')}</label>
							<Form.Field 
								label={t('vehicles.vehicle_type')}
								control={Input}
								value={transaction.vehicle ? t(`vehicle_type.${transaction.vehicle.type}`) : ""}/>
							<Form.Field 
								label={t('vehicles.license_plate')}
								control={Input}
								value={transaction.vehicle ? transaction.vehicle.license_plate_number : ""}/>
							<Form.Field 
								label={t('vehicles.province')}
								control={Input}
								value={transaction.vehicle ? transaction.vehicle.license_plate_province_name : ""}/>
						</Form>
					</Segment>
				)}
			)}
		</Container>
	)
}