import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon, Image, Message, Segment } from 'semantic-ui-react';
import logo from '../assets/logo.png'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { MOBILE_WIDTH } from "../Config";
import { GET, POST } from '../utils/HttpClient';
import { URL_AUTH } from '../constances/urls';
import { PATH } from '../routes/Routes';


export default function ResetPasswordScreen () {

  const params = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const isMobile = (window.innerWidth <= MOBILE_WIDTH);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("required")),
    confirm_password: Yup.string().required(t("required"))
      .oneOf([Yup.ref("password"), null], t("profiles.passwords_do_not_match")),
  });
  const formik = useFormik({
		validateOnChange: false,
    validationSchema: validationSchema,
    initialValues: {
      password: '',
      confirm_password: '',
    },
		onSubmit: (values) => handleResetPassword(values),
  });

  const handleResetPassword = async (values) => {
    setIsLoading(true);
    setErrorMessages(null);

    try {
      const data = {
        password: values.password
      }
      await POST(`${URL_AUTH.REQUEST_RESET_PASSWORD}${params.id}/reset_password/`, data)
      setIsSuccess(true);
    } catch(error) {
      if (error.status === 404) {
        setErrorMessages(t('forgot_passwords.request_no_longer_valid'))
        setIsValid(false);
      } else {
        setErrorMessages(error.errorMessages)
      }
    } finally {
      setIsLoading(false);
    }
  }

  const checkIfRequestValid = async () => {
    if (!params.id) {
      return
    }
    setIsLoading(true);

    try {
      const response = await GET(`${URL_AUTH.REQUEST_RESET_PASSWORD}${params.id}`)
      if (response.status === 200) {
        setIsLoading(false);
      }
    } catch(error) {
      if (error.status === 404) {
        setErrorMessages(t('forgot_passwords.request_no_longer_valid'))
        setIsValid(false);
      } else {
        setErrorMessages(error.errorMessages)
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    checkIfRequestValid();
  }, []);

  return (
    <div className='reset-password-form'>
      <style>
        {`
          body > div,
          body > div > div,
          body > div > div > div.reset-password-form {
            height: 100%;
          }
        `}
      </style>

      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column
          style={{ 
            maxWidth: 450, 
            display: 'flex', 
            alignItems: "center" 
          }}>
          <Image src={logo} size='small' centered/>
          <Segment style={{width: (isMobile ? "95%" : "100%")}} >
            { !isSuccess && 
              <Form onSubmit={formik.handleSubmit} >
                <Header color='grey'>{t('forgot_passwords.reset_password')}</Header>
                <Message
                  error
                  header={t("error")}
                  content={errorMessages}
                  visible={errorMessages != null}
                />
                { isValid && 
                  <div>
                    <Form.Input
                      fluid
                      name='password'
                      icon='lock'
                      iconPosition='left'
                      placeholder={t("profiles.password")}
                      type='password'
                      value={formik.values.password}
                      error={formik.errors.password}
                      onChange={formik.handleChange}
                    />
                    <Form.Input
                      fluid
                      name='confirm_password'
                      icon='lock'
                      iconPosition='left'
                      placeholder={t("profiles.confirm_password")}
                      type='password'
                      value={formik.values.confirm_password}
                      error={formik.errors.confirm_password}
                      onChange={formik.handleChange}
                    />
                    <Button
                      color='blue'
                      fluid size='large'
                      type='submit'
                      loading={isLoading}
                    >
                      {t("forgot_passwords.reset_password")}
                    </Button>
                  </div>
                }
                { !isValid && 
                  <Button
                    color='blue'
                    size='large'
                    onClick={() => history.replace(PATH.LOGIN)}
                  >
                    {t('forgot_passwords.back_to_login')}
                  </Button>
                }
              </Form>            
            }
            { isSuccess && 
              <div>
                <Header as='h2' icon textAlign='center' color='grey'>
                  <Icon inverted name='check' circular color='green'/>
                  <Header.Content>{t('forgot_passwords.reset_password_successfully')}</Header.Content>
                </Header>
                <Button
                  color='blue'
                  size='large'
                  onClick={() => history.replace(PATH.LOGIN)}
                >
                  {t('forgot_passwords.back_to_login')}
                </Button>
              </div>
            }
          </Segment>

        </Grid.Column>
      </Grid>
    </div>
  );
}