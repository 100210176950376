import React from 'react'
import { Dimmer, Loader, Message } from 'semantic-ui-react'
import { useHistory, useParams } from "react-router-dom";
import Cookies from 'js-cookie'
import { useLiff } from 'react-liff-v2';
import LoginView from '../components/common/LoginView';
import { URL_CUSTOMER } from '../constances/urls';
import { GET } from '../utils/HttpClient';

export default function LiffLoginScreen() {
	const history = useHistory();
  const { app } = useParams();
  const [customerCheck, setCustomerCheck] = React.useState(false)
  const { error, liff, isLoggedIn, ready } = useLiff();

	const handleOnLoggedIn = () => {
    history.replace(`/${app}/`);
  };

  const handleCustomerCheck = async () => {
    if (!liff) {
      return;
    }
    const customer = Cookies.get('customer')
    if (customer) {
      setCustomerCheck(true)
      return
    }


    try {
      const response = await GET(`${URL_CUSTOMER.GET_CUSTOMER_BY_LIFF}${liff.getAccessToken()}/`);
      Cookies.set('customer', response.data.id, { expires: 30 })
    } catch (error) {
    } finally {
      setCustomerCheck(true)
    }
  }

	React.useEffect(() => {
    if (ready) {
      const customer = Cookies.get('customer')
      if (customer) {
        // Already logged in, auto redirect
        setCustomerCheck(true);
        history.replace(`/${app}/`);
      }
    }
		
  }, [ready]);

  React.useState(() => {
    setCustomerCheck(false);
  }, [])

	React.useEffect(() => {
		if (isLoggedIn) {
      if (Cookies.get('customer') == null)
        handleCustomerCheck()
    }
  }, [isLoggedIn]);

	return (
		<>
			{!ready && 
        <Dimmer active>
          <Loader>Initializing Line LIFF...</Loader>
        </Dimmer>
      }

      {ready && !isLoggedIn &&
        < Dimmer active>
          <Loader>Logging In to Line Server...</Loader>
          {liff.login()}
        </Dimmer>
      }

      {ready && isLoggedIn && !customerCheck &&
        < Dimmer active>
          <Loader>Customer Checking...</Loader>
        </Dimmer>
      }

			{error &&
        <Message
          negative
          icon="exclamation circle"
          header="Error on Line LIFF"
          content={error}
        />
      }

      {ready && isLoggedIn && customerCheck && Cookies.get('customer') == null &&
        <LoginView onLoggedIn={handleOnLoggedIn} liff={liff}/>
      }
		</>
	)
}