import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import {Header, Modal, Button, Dimmer, Loader, Message, Input, Form} from 'semantic-ui-react';

export default function InputModal(props) {
	const { t, i18n } = useTranslation();
	const [value, setValue] = React.useState("");

	const handleInputChange = (e, data) => {
		setValue(data.value);
	}

	React.useEffect(() => {
		if (props.open) {
			setValue(props.initialValue);
		} else {
			setValue("");
		}
	}, [props.open])

  return (
		<Modal 
			centered={false}
			open={props.open} 
			onClose={props.onClose}
			size={props.size}>
			<Header icon={props.iconTitle} content={props.title}/>
			<Modal.Content>
				<Dimmer active={props.loading} inverted>
					<Loader inverted content={t("loading")}/>
				</Dimmer>
				{ props.error && 
					<Message negative style={{textAlign: "left", marginLeft: 24, marginRight: 24}}>
						<Message.Header>{t("error")}</Message.Header>
						<p>{props.error}</p>
					</Message> 
				}
				
				<Input 
					fluid
					className={"right"}
					size={"large"}
					type={props.inputType}
					value={value}
					onChange={handleInputChange}
					onKeyPress={(event) => {
						if (event.key === "Enter") {
							props.onConfirm(value);
						}
					}}/>
			</Modal.Content>
			<Modal.Actions>
				<Button 
					negative 
					onClick={props.onCancel}>
					{(props.negativeText) ? props.negativeText : t("cancel")}
				</Button>
				<Button 
					positive 
					onClick={() => {
						props.onConfirm(value);
					}}>
					{(props.positiveText) ? props.positiveText : t("confirm")}
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

InputModal.defaultProps = {
	loading: false,
	error: null,
	title: "",
	iconTitle: null,
	initialValue: "",
	size: "tiny",
	inputType: "text",
	negativeText: null,
	positiveText: null,

	onClose: () => {},
	onConfirm: (value) => {},
	onCancel: () => {},
}

InputModal.propTypes = {
	loading: propTypes.bool,
	error: propTypes.string,
	title: propTypes.string,
	iconTitle: propTypes.string,
	initialValue: propTypes.string,
	size: propTypes.string,
	inputType: propTypes.string,
	negativeText: propTypes.string,
	positiveText: propTypes.string,

	onClose: propTypes.func,
	onConfirm: propTypes.func,
	onCancel: propTypes.func,
}