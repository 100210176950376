import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import {Header, Icon, Modal, Button, Form, Input} from 'semantic-ui-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

export default function ChangePasswordModal(props) {
	const { t, i18n } = useTranslation();
	const validationSchema = Yup.object().shape({
		current_password: Yup.string().required(t("required")),
		new_password: Yup.string().required(t("required")),
		confirm_password: Yup.string()
			.required(t("required"))
			.oneOf([Yup.ref("new_password"), null], t("profiles.passwords_do_not_match"))
	});
	const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: {password: "", confirm_password: ""},
		validationSchema: validationSchema,
		onSubmit: (values) => handleSubmitPassword(values),
	});
	
	const handleSubmitPassword = async (values) => {
		props.onConfirm(values);
	};

	React.useEffect(() => {
		if (!props.open) {
			formik.resetForm();
		}
	}, [props.open])

  return (
		<Modal
			open={props.open} 
			onClose={props.onClose}
			size={props.size}
			centered={true}>
			<Header content={t("profiles.change_password")}/>
			<Modal.Content>
				<Form style={{marginTop: 16}}>
					<Form.Field 
						required
						name={"current_password"}
						control={Input}
						label={t("profiles.current_password")}
						type={"password"}
						value={formik.values.current_password}
            error={formik.errors.current_password}
						onChange={formik.handleChange}/>

					<Form.Field 
						required
						name={"new_password"}
						control={Input}
						label={t("profiles.new_password")}
						type={"password"}
						value={formik.values.new_password}
            error={formik.errors.new_password}
						onChange={formik.handleChange}/>

					<Form.Field 
						required
						name={"confirm_password"}
						control={Input}
						label={t("profiles.confirm_new_password")}
						type={"password"}
						value={formik.values.confirm_password}
            error={formik.errors.confirm_password}
						onChange={formik.handleChange}/>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					negative
					onClick={props.onClose}>
					{t("cancel")}
				</Button>

				<Button 
						positive
						onClick={formik.handleSubmit}>
						{t("confirm")}
					</Button>
			</Modal.Actions>
		</Modal>
	)
}

ChangePasswordModal.defaultProps = {
	size: "tiny",
	open: null,
	onClose: null,
	onConfirm: (data) => {},
}

ChangePasswordModal.propTypes = {
	size: propTypes.string,
	open: propTypes.bool,
	onClose: propTypes.func,
	onConfirm: propTypes.func,
}