import React, { useState, useEffect } from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Icon, Modal, Button, Form, Input, Search, Divider, Message, Dimmer, Loader, Header } from 'semantic-ui-react';
import TransactionCard from "./TransactionCard";
import { DELETE, GET, PATCH, POST } from "../../utils/HttpClient";
import { URL_COMPANY, URL_CUSTOMER, URL_WEIGHT } from "../../constances/urls";
import CustomerDetailModal from "../customer/CustomerDetailModal";
import CashierModal from "../cashier/CashierModal";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { formatComma } from "../../utils/Util";
import LogModal from '../common/LogModal';
import { Toast } from "../../utils/Toast";
import Cookies from "js-cookie";
import UndoPaymentModal from '../cashier/UndoPaymentModal';


let tempIndex = 0;
const searchResultRenderer = ({title}) => {
	return (<label>{title}</label>);
}

export default function FormScalesModal(props) {
	const { t, i18n } = useTranslation();
	const transactionRef = React.useRef([]);
	const [form, setForm] = React.useState(null);
	const [transactions, setTransactions] = React.useState([]);
	const [trigger, setTrigger] = React.useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	// Customer
	const [openCustomerModal, setOpenCustomerModal] = React.useState(false);
	const [searchLoading, setSearchLoading] = React.useState(false);
	const [customerList, setCustomerList] = React.useState([]);
	const [customerSelected, setCustomerSelected] = React.useState(null);
	const [customer, setCustomer] = React.useState({...(props.initialData || defaultCustomerModel)});
	const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
	const [isSameWeight, setIsSameWeight] = useState(true);
	// Cashier
	const [openCashierModal, setOpenCashierModal] = React.useState(false);
	// Undo Payment
	const [openUndoPaymentModal, setOpenUndoPaymentModal] = React.useState(false);
	// Log
	const [isDeleteVisible, setIsDeleteVisible] = useState(false);
	const [isLogVisible, setIsLogVisible] = useState(false);
	// Company Feature
	const [enableTransactionHistory, setEnableTransactionHistory] = useState(false);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(t("required")),
		full_address_th: Yup.string().required(t("required")),
	});
	const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: customer,
		validationSchema: validationSchema,
	});

	const handleAddTransaction = () => {
		let temp = transactions
		tempIndex++;
		temp.push({id: `temp${tempIndex}`, name: "test"});
		setTransactions(temp);
		setTrigger(!trigger);
	}

	// const hasSameWeight = () => {  // Check if transactions has same weight
	// 	return transactionRef.current.some(tx => {
	// 		return tx != null && tx.isSameWeight()
	// 	})
	// }

	const handleDeleteTransaction = async (transactionId, memo) => {
		if (transactionId && (!transactionId.startsWith("temp"))) {
			for (let index in transactions) {
				let trans = transactions[index];
				if (trans.id === transactionId) {
					const [isSuccess, response] = await transactionRef.current[index].delete(memo);
					if (!isSuccess) {
						setErrorMessage(`[${transactionId}] ${t("scales.error_cannot_delete_this_ticket")}`);
						return;
					}
				}
			}
		}

		Toast.success(t("messages.success"));
		if (errorMessage)
			setErrorMessage(null);
		if (transactions.length) {
			let temp = [];
			for (let item of transactions) {
				if (item.id === transactionId)
					continue;
				temp.push(item);
			}
			setTransactions(temp);
			if (!temp.length) {
				props.onClose();
			}
		}
	}

	const handlePrintTransaction = async () => {
		for (let index in transactions) {
			let trans = transactions[index];
			if (!trans.id.startsWith("temp")) {
				const [isSuccess, response] = await transactionRef.current[index].print();
				if (!isSuccess) {
					setErrorMessage(`[${trans.id}] ${t("scales.error_cannot_print_this_ticket")}`);
					return;
				}
			}

			// Pause for processing printing
			await new Promise(r => setTimeout(r, 2000));
		}

		Toast.success(t("messages.success"));
	}

	const handleCustomerSearchChange = async (data) => {
		if (data.value.length < 2)
			return;
		
		setSearchLoading(true);
		let params = {
			search: data.value,
			page: 1,
			page_size: 100,
			is_active: true,
		}
		try {
			const response = await GET(URL_CUSTOMER.CUSTOMER, params);
			let data = [];
			for (let item of response.data.results) {
				data.push({
					...item,
					title: `${item.name}`,
					description: "",
				});
			}
			setCustomerList(data);

		} catch(error) {
			console.log(error)
		} finally {
			setSearchLoading(false);
		}
	};

	const handleVehicleChange = async (data) => {
		if (!data || !data.customer || customerSelected)
			return;
		
		setSearchLoading(true);
		try {
			const response = await GET(`${URL_CUSTOMER.CUSTOMER}${data.customer}/`);
			let item = response.data;
			if (item.is_active) {
				const target = {
					...item,
					title: item.name,
					description: "",
				}
				setCustomerSelected(target);
				setCustomer(target);
				formik.setValues(target);	
			}
		} catch(error) {
			console.log(error)
		} finally {
			setSearchLoading(false);
		}
	};

	/**
	 * Reactivate form
	 */
	const handleReactivate = async () => {
		if (!form) 
			return;
		
		setLoading(true);
		let isSuccess = true;
		try {
			const response = await POST(`${URL_WEIGHT.FORM}${form.id}/reactivate/`);
		} catch(error) {
			setErrorMessage(error.errorMessage);
			console.log("Error: ", error);
			isSuccess = false;
		} finally {
			setLoading(false);
		}

		if (isSuccess) {
			if (errorMessage) 
				setErrorMessage(null);
			Toast.success(t("messages.success"));
			props.onClose();
		}
	};

	/** Handle SAVE button */
	/**
	 * 1. Validate data
	 * 2. Handle Form (Create or Update)
	 * 3. Handle all transaction (Create or Update)
	 */
	const handleSave = async () => {
		setErrorMessage(null);
		let isValid = true;
		// Validation
		for (let index in transactions) {
			let isTransactionValid = await transactionRef.current[index].validation();
			if (!isTransactionValid) {
				isValid = false;
			}
		}
		const customerValidation = await formik.validateForm();
		let isCustomerValid = (Object.keys(customerValidation).length === 0);
		if (!(isValid && isCustomerValid))
			return;

		// Handle Form
		setLoading(true);
		let formId = null;
		if (form && form.id)
			formId = form.id;
		
		// Prepare form data
		const formData = { customer: formik.values.id }
		if (!formId) {
			// Create Form
			let [isSuccess, formResponse] = await createForm(formData);
			if (isSuccess) 
				formId = formResponse.data.id;
			else {
				console.log("Error: Create form failed!");
				setErrorMessage(t("scales.error_cannot_create_form"));
				setLoading(false);
				return;
			}
		} else {
			// Update Form
			let [isSuccess, formResponse] = await updateForm(formId, formData);
			if (!isSuccess) {
				console.log("Error: Update form failed!");
				setErrorMessage(t("scales.error_cannot_update_form"));
				setLoading(false);
				return;
			}
		}

		// Handle transactions
		for (let index in transactions) {
			const trans = transactions[index];
			let isSuccess = false;
			if (trans.id.startsWith("temp")) {
				const [success, response] = await transactionRef.current[index].create(formId);
				isSuccess = success;
			} else {
				const [success, response] = await transactionRef.current[index].update(formId);
				isSuccess = success;
			}
			
			if (!isSuccess) {
				console.log("Error: Create transactions failed!");
				setErrorMessage(t("scales.error_cannot_create_ticket"));
				setLoading(false);
				return;
			}
		}

		// Re-load form
		const [success, response] = await loadFormDetail(formId);
		if (success) {
			const data = response.data;
			if (!data.is_finished) {
				props.onClose();
			}
		}

		// Clear loading and close modal
		Toast.success(t("messages.success"));
		setLoading(false);
	}

	const createForm = async (data) => {
		let formResponse = null;
		let isSuccess = true;
		try {
			formResponse = await POST(URL_WEIGHT.FORM, data);
			setForm(formResponse.data);
		} catch(error) {
			isSuccess = false;
			console.log("Error: ", error);
		}
		return [isSuccess, formResponse];
	}

	const updateForm = async (formId, data) => {
		let formResponse = null;
		let isSuccess = true;
		try {
			formResponse = await PATCH(`${URL_WEIGHT.FORM}${formId}/`, data);
			setForm(formResponse.data);
		} catch(error) {
			isSuccess = false;
			console.log("Error: ", error);
		}
		return [isSuccess, formResponse];
	}

	const deleteForm = async () => {
		let isSuccess = true;
		if (form) {
			try {
				const formResponse = await DELETE(`${URL_WEIGHT.FORM}${form.id}/`);
				if (errorMessage) {
					setErrorMessage(null);
				}
			} catch(error) {
				setErrorMessage(error.errorMessages);
				console.log("Error: ", error);
				isSuccess = false;
			}
		}
		
		return isSuccess;
	}

	// Load Detail
	const loadFormDetail = async (uid) => {
		let isSuccess = true;
		let response = null;
		try {
			response = await GET(`${URL_WEIGHT.FORM}${uid}/`);
			const data = response.data;
			setForm(data);
			setCustomerSelected(data.customer);
			setCustomer(data.customer);
			setTransactions(data.transactions);
			if (errorMessage)
				setErrorMessage(null);
		} catch(error) {
			console.log("Error: ", error);
			setErrorMessage(error.errorMessages);
		}

		return [isSuccess, response];
	}

	const checkTransactionHistoryFeature = async () => {
		setLoading(true);
		try {
			const response = await GET(URL_COMPANY.COMPANY);
				if (response.data.results.length > 0) {
					setEnableTransactionHistory(response.data.results[0].enable_transaction_history_feature);
				}
		} catch (error) {
			setErrorMessage(error.errorMessages)
		} finally {
			setLoading(false);
		}
	}

  useEffect(() => { 
		if (!props.open) {
			tempIndex = 1;
			setTransactions([{id: "temp1", name: ""}]);
			setErrorMessage(null);
			setForm(null);
			setCustomerSelected(null);
			setCustomer({...(props.initialData || defaultCustomerModel)});
		}
	}, [props.open]);
	
	useEffect(() => {
		if (props.formUID) {
			loadFormDetail(props.formUID);
			checkTransactionHistoryFeature()
		}
	}, [props.formUID]);

	useEffect(() => {
		setIsCustomerDeleted(false);
		if (!customer.is_active) {
			setIsCustomerDeleted(true);
		}
	}, [customer]);

  return (
		<div>
			<LogModal
				open={isLogVisible}
				onClose={() => setIsLogVisible(false)}
				objectId={props.formUID}
				objectType='WEIGHT_FORM'
			/>

			<ConfirmModal
        open={isDeleteVisible}
        title={t("scales.delete_form")}
        detail={t("scales.msg_confirm_delete_form")}
        onClose={() => setIsDeleteVisible(false)}
        onConfirm={async () => {
					const isSuccess = await deleteForm();
					setIsDeleteVisible(false);
					if (isSuccess) {
						Toast.success(t("messages.success"));
						props.onClose();
					}
				}}
        onCancel={() => setIsDeleteVisible(false)}      
      />

			<Modal
				closeIcon
				closeOnDimmerClick={false}
				size={"large"}
				open={props.open}
				onClose={props.onClose}>
				<Modal.Header>
					{t("scales.weight_form")}
					{ form && !form.is_paid && 
						<Button primary 
							floated={"right"}
							content={t("scales.add_ticket")} 
							icon={"add circle"}
							onClick={handleAddTransaction}/>
					}
					<Button
						floated='right'
						basic 
						primary
						icon='file alternate outline'
						content={t('log')}
						onClick={() => setIsLogVisible(true)}
					/>
				</Modal.Header>
				<Modal.Content>
					<Dimmer active={loading} inverted>
						<Loader inverted content={t("loading")} />
					</Dimmer>
					{ isCustomerDeleted && form && !form.is_paid && form.is_active && 
						<div style={{textAlign: "left", marginLeft: 24, marginRight: 24}}>
							<Message warning>
								<Message.Header>{t('warnings.customer_deleted')}</Message.Header>
								<p>{t('messages.please_change_customer')}</p>
							</Message>
						</div>
					}
					{ errorMessage && 
						<Message negative style={{textAlign: "left", marginLeft: 24, marginRight: 24}}>
							<Message.Header>{t("error")}</Message.Header>
							<p>{errorMessage}</p>
						</Message> 
					}
					{transactions.map((item, index) => {
						return(
							<TransactionCard 
								ref={el => (transactionRef.current[index] = el)}
								style={{marginTop: 8}}
								key={index}
								index={index}
								transactionId={item.id}
								initialData={item}
								disablePrint={!(form && form.is_finished && form.is_active && (form.debt_amount === 0))}
								isPaid={(form && form.is_paid)}
								onPrint={(success, data) => {
									if (success) {
										Toast.success(t("messages.success"));
										setErrorMessage(null);
									} else {
										setErrorMessage(t("scales.error_cannot_print_this_ticket"));
									}
								}}
								shiftReady={props.shiftReady}
								onDelete={handleDeleteTransaction}
								onVehicleChange={handleVehicleChange}
								onWeightChange={(inWeight, outWeight) => {setIsSameWeight(inWeight === outWeight)}}
								enableTransactionHistory={(form && form.is_active && !form.is_paid && enableTransactionHistory)}
							/>
						)
					})}
					<Divider horizontal style={{marginTop: 24, marginBottom: 24}}>{t("scales.customer_detail")}</Divider>
					<div style={{marginLeft: 24, marginRight: 24}}>
						<Form>
							<Form.Group inline>
								<Form.Field 
									width={7}
									inline
									required
									style={{width: "85%"}}
									name={"name"}
									label={t("customers.name")}
									placeholder={`${t("search")}...`}
									loading={searchLoading}
									minCharacters={2}
									results={customerList}
									resultRenderer={searchResultRenderer}
									onSearchChange={(_, data) => {
										handleCustomerSearchChange(data);
										if(customerSelected) {
											setCustomerSelected(null);
											setCustomer(defaultCustomerModel)
										}
										formik.setFieldValue('name', data.value);
									}}
									onResultSelect={(_, data) => {
										setCustomerSelected(data.result);
										setCustomer(data.result);
									}}
									onBlur={(e, data) => {
										if (!customerSelected) {
											setCustomer(defaultCustomerModel);
											formik.setFieldValue('name', "");
										}
									}}
									value={formik.values.name}
									error={formik.errors.name}
									control={Search}/>

								{form && form.is_active && !form.is_paid && 
									<Form.Field 
										circular
										color={"green"} 
										icon={"add"} 
										size={"mini"}
										onClick={() => setOpenCustomerModal(true)}
										control={Button}/>
								}
								
								<Form.Field 
									width={9}
									inline
									name={"full_address_th"}
									label={t("customers.address")}
									style={{width: "92%"}}
									value={formik.values.full_address_th}
									// error={formik.errors.full_address_th}
									control={Input}/>
							</Form.Group>
						</Form>
					</div>

					{form && form.is_finished && (
					<div>
						<Divider horizontal style={{marginTop: 24, marginBottom: 24}}>{t("scales.summary")}</Divider>
						<div style={{marginLeft: 24, marginRight: 24}}>
							<Form>
								<Header textAlign="center">{(form && form.type) ? t(`scales.${form.type}`) : ''}</Header>
								<Form.Group inline>
									<Form.Field width={4}/>
									<Form.Field width={2}>
										<label style={{width: "100%", textAlign: "right"}}>{t("scales.net_weight")}</label>
									</Form.Field>
									<Form.Field width={5}>
										<Input className={"right"} fluid value={formatComma(form.net_weight)}/>
									</Form.Field>
									<Form.Field width={1}>
										<label>{t("units.kilo_gram")}</label>
									</Form.Field>
								</Form.Group>
								<Form.Group inline>
									<Form.Field width={4}/>
									<Form.Field width={2}>
										<label style={{width: "100%", textAlign: "right"}}>{t("scales.net_price")}</label>
									</Form.Field>
									<Form.Field width={5}>
										<Input className={"right"} fluid value={formatComma(form.net_price)}/>
									</Form.Field>
									<Form.Field width={1}>
										<label>{t("units.baht")}</label>
									</Form.Field>
								</Form.Group>
							</Form>
						</div>
					</div>
					)}
				</Modal.Content>
				<Modal.Actions>
					{form && form.is_finished && form.is_active && !form.is_paid && props.shiftReady && (
						<Button primary 
							floated={"left"}
							// disabled={(form.debt_amount <= 0)}
							onClick={() => setOpenCashierModal(true)}>
							<Icon name={"money"}/>
							{t("paid")}
						</Button>
					)}

					{form 
						&& form.is_finished 
						&& form.is_active 
						&& form.is_paid
						&& (
						<Button
							key="print-ticket-button"
							primary 
							floated={"left"}
							onClick={() => handlePrintTransaction()}
							icon="print"
							content={t("print")}
						/>
					)}

					<Button color={"grey"} onClick={props.onClose}>
						<Icon name={"cancel"}/>
						{t("cancel")}
					</Button>
					
					{form && form.is_finished && form.is_active && form.is_paid && props.shiftReady &&
						<Button 
							key="undo-payment-button"
							primary
							basic
							onClick={() => setOpenUndoPaymentModal(true)}
							icon="undo"
							content={t("undo")}
						/>
					}

					{form && form.is_active && !form.is_paid && props.shiftReady && (
						<Button 
							color={"red"} 
							// disabled={(form.debt_amount === 0 && form.is_finished)}
							onClick={() => setIsDeleteVisible(true)}>
							<Icon name={"trash"}/>
							{t("delete")}
						</Button>
					)}
					
					{(!form || (form && form.is_active && !form.is_paid)) && props.shiftReady && (
						<Button 
							color={"green"} 
							disabled={(transactions.length === 0) || isCustomerDeleted}
							onClick={() => handleSave()}>
							<Icon name={"save"}/>
							{t("save")}
						</Button>
					)}

					{/* {isSameWeight && <Button disabled color="green"> น้ำหนักเท่ากัน! </Button>} */}
					
					{form && !form.is_active && props.shiftReady && (
						<Button basic
							color={"blue"} 
							onClick={handleReactivate}>
							<Icon name={"sync alternate"}/>
							{t("reactivate")}
						</Button>
					)}
				</Modal.Actions>

				<CustomerDetailModal 
					open={openCustomerModal}
					onClose={() => setOpenCustomerModal(false)}
					onUpdateSuccess={(data) => {
						if (data) {
							setCustomerSelected(data);
							setCustomer(data);
							formik.setValues(data);
						}
					}}/>

				<UndoPaymentModal
					open={openUndoPaymentModal}
					form={form}
					onClose={() => setOpenUndoPaymentModal(false)}
					onConfirm={async () => {
						setOpenUndoPaymentModal(false);
						setLoading(true);
						try {
							await loadFormDetail(props.formUID);
						} catch (error) {
							setErrorMessage(error.errorMessages);
						} finally {
							setLoading(false);
						}
					}}
				/>
				<CashierModal 
					open={openCashierModal}
					form={form}
					onClose={() => setOpenCashierModal(false)}
					onPaid={async () => {
						setOpenCashierModal(false);
						const autoPrint = Cookies.get("auto_print");
						if (!autoPrint || (autoPrint === "true")) {
							await handlePrintTransaction();
						}
						props.onClose();
					}}/>
			</Modal>
		</div>
	)
}

export const defaultCustomerModel = {
	id: null,
	name: "",
	full_address_th: "",
	is_active: true,
};

FormScalesModal.defaultProps = {
	formUID: null,
	open: false,
	onClose: () => {},
	initialData: null,
	shiftReady: false,
}

FormScalesModal.propTypes = {
	formUID: propTypes.string,
	open: propTypes.bool,
	onClose: propTypes.func,
	initialData: propTypes.object,
	shiftReady: propTypes.bool,
}