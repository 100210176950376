import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Header, Icon, List, Message, Modal, Segment } from "semantic-ui-react";
import propTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from 'yup';
import NumberFormat from "react-number-format";
import { SERVER } from "../../Config";
import Cookies from "js-cookie";
import { GET, POST } from "../../utils/HttpClient";
import { URL_COMPANY } from "../../constances/urls";
import { Toast } from "../../utils/Toast";

export default function ShiftModal(props) {
  const {
    open,
    onClose,
    shiftType,
    shiftData,
  } = props;

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
      type: shiftType,
      amount: '',
      note: '',
    },
		validationSchema: validationSchema,
		onSubmit: (values) => handleCreateShift(values),
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessage] = React.useState(null);

  const handleCreateShift = async (values) => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const params = { ...values, amount: values.amount.replace(',', '') }
      const response = await POST(URL_COMPANY.COMPANY_SHIFT, params)
      Toast.success(t('messages.success'))
      onClose();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {      
      setIsLoading(false);
    }
  }

  const handleOpenCashier = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const serverIP = Cookies.get("server");
			const serverPort = Cookies.get("server_port");
      const response = await GET(
				`http://${serverIP ? serverIP : SERVER.DEFAULT_IP}:${serverPort ? serverPort : SERVER.DEFAULT_PORT}${URL_COMPANY.OPEN_DRAWER}`
			);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {      
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (open) {
      console.log(shiftData)
      formik.resetForm();
    }
  }, [open])

  return (
    <Modal 
      open={open}
      onClose={onClose}
      centered={false}
    >
      <Modal.Header>
        <Icon name={shiftType === 'shift_out' ? "sign-out alternate" : "sign-in alternate"}/>
        {shiftType ? t(`shifts.${shiftType}`) : ''}
      </Modal.Header>
      <Modal.Content>
        {errorMessages && 
          <Message error>{errorMessages}</Message>
        }
        {!shiftData.is_ready && !shiftData.is_available &&
          <Message warning>{t('shifts.warning_not_user_shift')}</Message>
        }
        {shiftData.current_in_shift && shiftData.current_in_shift.id && shiftData.current_in_shift.type == 'shift_in' && 
          <Segment>
            <Header>{t('shifts.current_shift')}</Header>
            <List>
              <List.Item><span style={{fontWeight: 'bold'}}>{t('date_time')}</span>: {shiftData.current_in_shift.created}</List.Item>
              <List.Item><span style={{fontWeight: 'bold'}}>{t('admin.users')}</span>: {shiftData.current_in_shift.user_name}</List.Item>
              <List.Item><span style={{fontWeight: 'bold'}}>{t('shifts.amounts.shift_in')}</span>: {shiftData.current_in_shift.amount}</List.Item>
              <List.Item><span style={{fontWeight: 'bold'}}>{t('note')}</span>: {shiftData.current_in_shift.note}</List.Item>
            </List>
          </Segment>
        }
        <Form>
          <Form.Group inline>
            <Form.Field
              control={NumberFormat}
              thousandSeparator={true}
              name='amount'
              label={shiftType ? t(`shifts.amounts.${shiftType}`) : ''}
              placeholder={shiftType ? t(`shifts.amounts.${shiftType}`) : ''}
              readOnly={!shiftData.is_ready && !shiftData.is_available}
              value={formik.values.amount}
              error={formik.errors.amount}
              onChange={formik.handleChange}/>
            <Button 
              primary
              content={t('shifts.open_cashier')}
              disabled={!shiftData.is_ready && !shiftData.is_available}
              icon='money bill alternate'
              onClick={() => handleOpenCashier()}/>
          </Form.Group>
          <Form.TextArea
            name='note'
            label={t(`note`)}
            placeholder={t(`note`)}
            readOnly={!shiftData.is_ready && !shiftData.is_available}
            value={formik.values.note}
            error={formik.errors.note}
            onChange={formik.handleChange}/>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button 
          loading={isLoading}
          content={t('cancel')}
          icon='cancel'
          onClick={onClose}/>
        <Button 
          loading={isLoading}
          positive content={t('confirm')}
          disabled={!shiftData.is_ready && !shiftData.is_available}
          icon='check'
          onClick={formik.handleSubmit}/>
      </Modal.Actions>
    </Modal>
  )
}


ShiftModal.defaultProps = {
  open: false,
  onClose: () => null,
  shiftData: {
    is_on_shift: false,
    is_user_shift: false,
    is_ready: false,
    is_available: false,
    last_shift: {
      id: null,
    },  
  },
  shiftType: null,
}

ShiftModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  shiftType: propTypes.string,
  shiftData: propTypes.object,
}
