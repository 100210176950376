import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Label, Modal } from 'semantic-ui-react';
import { URL_DASHBOARD } from '../../constances/urls';
import { GET } from '../../utils/HttpClient';
import { checkWeightAmountUseTonne, shortCurrencyFormat, shortWeightFormat } from '../../utils/Util';
import ListTable from '../common/ListTable';
import propTypes from 'prop-types';


export default function WidgetDetailModal(props) {

  const {
    open,
    onClose,
    title,
    icon,
    customer,
  } = props;

  const { t, i18n } = useTranslation();

  const columns = useMemo(() => {
    let default_columns = [
      {
        Header: t('scales.date'),
        accessor: 'created'
      },
      {
        Header: t('customers.name'),
        accessor: 'customer_name',
      },
    ];

    if (title === 'sales') {
      return [
        ...default_columns,
        {
          Header: t('scales.transaction_type'),
          accessor: 'type',
          Cell: ({row}) => <Label color={row.original.type === 'sale' ? 'green' : 'red'}>
            {t(`scales.${row.original.type}`)}
          </Label>
        },
        {
          Header: t('dashboard.sales'),
          accessor: 'paid_amount',
          Cell: ({row}) => `${shortCurrencyFormat(row.original.paid_amount)} ${t('units.baht')}`
        },
      ]
    } else if (title === 'debt') {
      return [
        ...default_columns, 
        {
          Header: t('dashboard.debt'),
          accessor: 'current_balance',
          Cell: ({row}) => `${shortCurrencyFormat(row.original.current_balance)} ${t('units.baht')}`
        },
      ]
    } else if (title === 'net_weight') {
      return [
        ...default_columns, 
        {
          Header: t('dashboard.net_weight'),
          accessor: 'net_weight',
          Cell: ({row}) => `${shortWeightFormat(row.original.net_weight)} ${checkWeightAmountUseTonne(row.original.net_weight) ? t('units.tonne') : t('units.kilo_gram')}`
        },
      ]
    } else if (title === 'passed_vehicle') {
      return [
        ...default_columns, 
        {
          Header: t('vehicles.license_plate'),
          accessor: 'license_plate',
        },
      ]
    } else if (title === 'deleted_transaction') {
      return [
        {
          Header: t('scales.date'),
          accessor: 'created'
        },
        {
          Header: t('vehicles.license_plate'),
          accessor: 'vehicle_license_plate',
        },
        {
          Header: t('logs.weight_form.amount'),
          accessor: 'amount',
          Cell: ({row}) => `${shortCurrencyFormat(row.original.amount)} ${t('units.baht')}`
        },
        {
          Header: t('memo'),
          accessor: 'memo',
        },
      ]
    } else if (title === 'customer_income' || title === 'customer_expense') {
      return [
        ...default_columns,
        {
          Header: t(`dashboard.${title}`),
          accessor: 'paid_amount',
          Cell: ({row}) => `${shortCurrencyFormat(row.original.paid_amount)} ${t('units.baht')}`
        },
      ]
    }
  }, []);

  const [data, setData] = useState({
    links: {
      next: null,
      previous: null,
    },
    count: 0,
    pages: 0,
    results: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const tableRef = useRef();

  const fetchData = async (params) => {
    if (!params) {
      params = tableRef.current.getParams();
    }
    setIsLoading(true);
    setErrorMessages(null);

    try {
      if (customer) {
        params = {
          ...params,
          customer
        }
      }
      const response = await GET(getFetchURL(), params)
      setData(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const getFetchURL = () => {
    switch (title) {
      case 'sales':
        return URL_DASHBOARD.SALES_DETAIL
      case 'debt':
        return URL_DASHBOARD.DEBT_DETAIL
      case 'net_weight':
        return URL_DASHBOARD.NET_WEIGHT_DETAIL
      case 'passed_vehicle':
        return URL_DASHBOARD.PASSED_VEHICLE_DETAIL
      case 'deleted_transaction':
        return URL_DASHBOARD.DELETED_TRANSACION_DETAIL
      case 'customer_income':
        return URL_DASHBOARD.CUSTOMER_INCOME_DETAIL
      case 'customer_expense':
        return URL_DASHBOARD.CUSTOMER_EXPENSE_DETAIL
    }
    return null;
  }

  useEffect(() => {
    if (open) {
      setErrorMessages(null);
      fetchData();
    }

  }, [open])

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        centered={false}
        closeIcon
      >
        <Modal.Header>
          <Header as='h3' icon={icon} content={t(`dashboard.${title}`)}/>          
        </Modal.Header>
        <Modal.Content>
          <ListTable
            ref={tableRef}
            columns={columns}
            data={data}
            loading={isLoading}
            error={errorMessages}
            refetch={fetchData}
            showPagination
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

WidgetDetailModal.defaultProps = {
  open: false,
  onClose: () => {},
  title: '',
  icon: '',
  customer: '',
}

WidgetDetailModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  title: propTypes.string,
  icon: propTypes.string,
  customer: propTypes.string,
}