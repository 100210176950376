import moment from "moment";

export const formatComma = (value, enable_negative=false) => {
  if (!value) {
    return 0;
  }
  const isNegative = value.toString().startsWith('-')
  const split_decimal = (+parseFloat(value.toString().replace(',', '').replace('-', ''))).toFixed(2).split('.');
  const number = split_decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (+split_decimal[1] ? "." + split_decimal[1] : "");
  return `${isNegative && enable_negative ? '-' : ''}${number}`
}

export const shortCurrencyFormat = (value, enable_negative=false) => {
  if (!value) {
    return 0;
  }

  const isNegative = value.toString().startsWith('-')
  const numberValue = (+parseFloat(value.toString().replace(',', '').replace('-', '')));
  if (numberValue >= 1000000000) {
    return `${isNegative && enable_negative ? '-' : ''}${(numberValue/1000000000).toFixed(2)}B`
  } else if (numberValue >= 1000000) {
    return `${isNegative && enable_negative ? '-' : ''}${(numberValue/1000000).toFixed(2)}M`
  } else if (numberValue >= 1000) {
    return `${isNegative && enable_negative ? '-' : ''}${(numberValue/1000).toFixed(2)}K`    
  }
  return `${isNegative && enable_negative ? '-' : ''}${formatComma(value)}`
}

export const shortWeightFormat = (value) => {
  if (!value) {
    return 0;
  }

  const numberValue = (+parseInt(value.toString().replace(',', '').replace('-', '')));
  if (numberValue >= 10000000) {
    return `${formatComma((numberValue/1000000).toFixed(2))}K`;
  } else if (numberValue >= 10000) {
    return formatComma((numberValue/1000).toFixed(2));
  }
  return formatComma(value)
}

export const checkWeightAmountUseTonne = (value) => {
  if (!value) {
    return 0;
  }

  const numberValue = (+parseInt(value.toString().replace(',', '').replace('-', '')));
  return numberValue >= 10000;
}

export const ellipsisString = (value, threshold_length=15) => {
  if (!value) {
    return '';
  }
  
  return `${value.toString().substring(0, threshold_length)}${value.toString().length > threshold_length ? '...' : ''}`
}

/** Convert Date-Time */
export const dateTimeToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY HH:mm");
}

export const dateToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY");
}