import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Button, Dimmer, Form, Header, Icon, Label, Loader, Message, Modal, Popup, Segment } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { GET, POST } from '../../utils/HttpClient';
import { URL_WALLET } from '../../constances/urls';
import ListTable from '../common/ListTable';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Toast } from "../../utils/Toast";
import { formatComma } from "../../utils/Util";


export default function CustomerWalletModal(props) {

  const {
    open,
    onClose,
    customer,
  } = props;

  const { t, i18n } = useTranslation();
  const columns = useMemo(() => [
    {
      Header: t('wallets.transactions.date'),
      accessor: 'created',
    },
    {
      Header: t('wallets.transactions.type'),
      accessor: 'type',
      Cell: ({row}) => {
        if (row.original.type === 'deposit') {
          return (<Label color='green'>{t('wallets.transactions.types.deposit')}</Label>);
        }
        return (<Label color='red'>{t('wallets.transactions.types.withdraw')}</Label>);
      }
    },
    {
      Header: t('wallets.transactions.amount'),
      accessor: 'amount',
      Cell: ( {value} ) => {
				return (<div>{formatComma(value)}</div>)
			}
    },
    {
      Header: t('wallets.balance'),
      accessor: 'balance',
      Cell: ( {value} ) => {
				return (<div>{value}</div>)
			}
    },
    {
      Header: t('wallets.transactions.attachment'),
      accessor: 'attachment',
      Cell: ({row}) => {
        if (row.original.attachment) {
          return <a target='_blank' rel='noopener noreferrer' href={row.original.attachment}>
            {row.original.attachment.split('/').reverse()[0]}
          </a>
        } else {
          return '-'
        }
      }
    },
    {
      Header: () => null,
      accessor: 'note',
      Cell: ({row}) => (
        <div style={{textAlign: 'center'}}>
          { row.original.note && 
            <Popup
              inverted
              trigger={<Icon name='file alternate outline' size='large' color='blue'/>}
              content={() => {
                if (row.original.note.startsWith('undo_payment')) {
                  return t(`logs.memo.${row.original.note}`)
                }
                else if (row.original.note.startsWith('transaction_deleted')) {
                  const deleted_transaction_text = row.original.note.split(' ')
                  return `${t(`logs.memo.${deleted_transaction_text[0]}`)} ${deleted_transaction_text[1] || ''}`
                } else {
                  return row.original.note;
                }
              }}
              position='bottom right'
            />
          }
        </div>
      )
    }
  ], []);

  const tableRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [loadingErrorMessages, setLoadingErrorMessages] = useState(null);
  const [depositErrorMessages, setDepositErrorMessages] = useState(null);
  const [isDepositActive, setIsDepositActive] = useState(false);
  const [wallet, setWallet] = useState(defaultWalletModel);
  const [transactions, setTransactions] = useState({
    count: 0,
    pages: 0,
    links: {
      next: null,
      previous: null,  
    },
    results: [],
  });
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required(t('required')),
  });
  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: defaultModel,
		validationSchema: validationSchema,
		onSubmit: (values) => deposit(values),
  });
  const fileInput = createRef();

  const fetchWalletData = async () => {
    setIsLoading(true);
    
    try {
      const response = await GET(`${URL_WALLET.DETAIL}${customer.id}`)
      setWallet(response.data)
    } catch (error) {
      setLoadingErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchTransactionData = async (params) => {
    if (!params) {
      params = tableRef.current.getParams();
    }
    setIsLoading(true);
    setLoadingErrorMessages(null)    
    
    try {
      const response = await GET(`${URL_WALLET.TRANSACTION}${customer.id}`, params)
      setTransactions(response.data)
    } catch (error) {
      setLoadingErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const deposit = async (data) => {
    if (parseFloat(data.amount) <= 0) {
      setDepositErrorMessages(t("wallets.amount_must_be_value_greater_then_zero"))
      return;
    }
    
    data.amount = data.amount.replaceAll(",", "");
    setIsDepositLoading(true);
    try {
      await POST(`${URL_WALLET.DEPOSIT}${customer.id}/`, data);
      Toast.success(t('messages.success'));
      formik.resetForm();
      fetchWalletData();
      fetchTransactionData();
    } catch (error) {
      setDepositErrorMessages(error.errorMessages)
    } finally {
      setIsDepositLoading(false);
    }
  }

  const handleFileChange = (event) => {
    if (event.currentTarget.files[0]) {
      const fileReader = new FileReader();
      fileReader.onload = (fileLoadEvent) => {
        formik.setFieldValue('attachment', fileLoadEvent.target.result);  
      }
      fileReader.readAsDataURL(event.currentTarget.files[0])

      formik.setFieldValue('attachment_name', event.currentTarget.files[0].name);  
    }
  }

  useEffect(() => {
    if (customer && open) {
      fetchWalletData();
      fetchTransactionData();
    }
  }, [customer]);

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setIsDepositActive(false);
      setDepositErrorMessages(null);
    }
  }, [open]);

  return (
    <div>
      <Dimmer active={isLoading}>
        <Loader>{t("loading")}</Loader>
      </Dimmer>
      <Modal
        closeIcon
        open={open}
        onClose={onClose}
      >
        <Modal.Header>{t('cashiers.wallet')}: {customer? customer.name : ''}</Modal.Header>
        <Modal.Content>
          { depositErrorMessages && 
            <Message negative>
              <Message.Header>{t("error")}</Message.Header>
              <p>{depositErrorMessages}</p>
            </Message>
          }
          <Segment>
            <Accordion>
              <Accordion.Title
                active={isDepositActive}
                onClick={() => setIsDepositActive(!isDepositActive)}
              >
                <Icon name='dropdown'/>
                {t('wallets.deposit')}
              </Accordion.Title>
              <Accordion.Content active={isDepositActive}>
                <Form>
                  <Form.Group widths='equal'>
                    <Form.Field 
                      required
                      label={t('wallets.transactions.amount')}
                      name={"amount"}
                      placeholder={t('wallets.transactions.amount')}
                      value={formik.values.amount}
                      error={formik.errors.amount}
                      onChange={formik.handleChange}
                      control={NumberFormat}
                      thousandSeparator={true}
                    />
                    <Form.Field>
                      <label>{t('wallets.transactions.attachment')}</label>
                      <Form.Input
                        name='attachment_name'
                        readOnly={true}
                        placeholder={`${t("upload_file")}...`}
                        action={
                          <Button 
                            color='blue' 
                            basic
                            onClick={() => fileInput.current.click()}
                          >
                            <Icon name='upload' />
                            {t("upload")}
                          </Button>
                        }
                        value={formik.values.attachment_name}
                        error={formik.errors.attachment_name}
                        onChange={formik.handleChange}
                      />
                      <input 
                        ref={fileInput}
                        type='file' 
                        hidden  
                        onChange={handleFileChange}/>
                    </Form.Field>
                  </Form.Group>
                  <Form.TextArea
                    name='note'
                    label={t('memo')}
                    placeholder={t('memo')}
                    value={formik.values.note}
                    error={formik.errors.note}
                    onChange={formik.handleChange}
                  />
                  <Button 
                    color='green'
                    onClick={formik.handleSubmit}
                    loading={isDepositLoading}
                  >
                    <Icon name='dollar sign'/>
                    {t('wallets.deposit')}
                  </Button>
                </Form>
              </Accordion.Content>
            </Accordion>
          </Segment>
          <Segment>
            <Header as='h1'>{t('wallets.balance')}: {wallet.current_balance} {t('units.baht')}</Header>
            <div>{t('wallets.recent_transaction')}: {wallet.updated}</div>
          </Segment>
          <ListTable
            ref={tableRef}
            columns={columns}
            data={transactions}
            loading={isLoading}
            error={loadingErrorMessages}
            refetch={fetchTransactionData}
            showPagination
          />
        </Modal.Content>
      </Modal>
    </div>
  );
}

const defaultWalletModel = {
  current_balance: 0,
  updated: '',
}

const defaultModel = {
  amount: "0",
  attachment: '',
  attachment_name: '',
  note: '',
}
