import React from 'react';
import propTypes from "prop-types";
import { Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function LanguageMenu(props) {
	const { t, i18n } = useTranslation();
	const [activeItem, setActiveItem] = React.useState(props.initialLang);

	const handleItemClick = async (e, data) => {
		i18n.changeLanguage(data.name.toLowerCase());
		setActiveItem(data.name);
	};

	React.useEffect(() => {
		setActiveItem(i18n.language.toUpperCase())
	}, [])

  return (
		<Menu secondary size={"mini"} style={{marginBottom: 0}}>
			<Menu.Menu position={props.position}>
				<Menu.Item 
					name={"EN"}
					active={activeItem === "EN"}
					onClick={handleItemClick}>
				</Menu.Item>
				<Menu.Item 
					name={"TH"}
					active={activeItem === "TH"}
					onClick={handleItemClick}>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	)
};

LanguageMenu.defaultProps = {
	position: "left",
	initialLang: "EN",
}

LanguageMenu.propTypes = {
	position: propTypes.string,
	initialLang: propTypes.string,
}