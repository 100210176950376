import React, { useEffect } from 'react';
import './App.css';
import { HashRouter as Router } from "react-router-dom";
import 'dotenv'
import Routes from './routes/Routes';
import { Dimmer, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
function App(props) {

  const {
    listen
  } = props;

  return (
    <React.Suspense fallback={
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
    }>
      <Router>
        <div className="App">
          <Routes listen={listen} />
        </div>
      </Router>
    </React.Suspense>
  );
}

export default App;
